import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import '@/index.css';
import '@/app/views/main.scss';

import { useCallback, useEffect } from 'react';
import { App as AppView } from './app/views';
import AlertPopup from '@/shared/components/alertPopup/AlertPopup';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { routePageNames as routes } from '@/core/routes';
import { pathToUrl } from '@/shared/utils/routes';
import { ScrollToTop } from '@/shared/components/ScrollToTop/ScrollToTop';
import { getProfile } from '@/shared/features/profile/services';
import { Authentication } from '@/shared/features/authentication/Authentication';
import { ErrorBoundaryApp } from '@/shared/components/error/ErrorBoundaryApp';
import { useAppSelector } from '@/core/hook/appHook';
import { selectAppSession } from '@/shared/components/global/globalSlice';

export const RootApp = props => {
  const account = useSelector(state => state?.auth?.account);
  const blockCountry = useSelector(state => state?.blockCountry);
  const appSession = useAppSelector(selectAppSession);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const refetchProfile = useCallback(() => {
    if (!account) {
      return;
    }

    getProfile({ refresh: true })(dispatch);
  }, [account]);

  useEffect(() => {
    if (blockCountry.isBlock && location.pathname !== routes.blockCountry) {
      history.push(pathToUrl(routes.blockCountry));
    }
  }, [history, blockCountry, location]);

  useEffect(() => {
    refetchProfile();
  }, [location]);

  return (
    <ErrorBoundaryApp hiddenChildren={false} isDialog>
      <AppView {...props} key={appSession}>
        <AlertPopup />
        <ScrollToTop />
        <Authentication />
      </AppView>
    </ErrorBoundaryApp>
  );
};
