import FETCH from '../utils/security/fetch';
import * as APIString from '../constants/apiString';
import { store } from '@/store/store';
import queryString from 'query-string';

const getSurveys = pageSlug => {
  const path = `/tenants/${APIString.TENANT_SLUG}/surveys/service/`;
  return FETCH(path, 'GET', {});
};

const postSurveys = (params = {}) => {
  let Authorization = '';
  const { ...paramsRest } = params;
  const body = JSON.stringify({
    survey_content: JSON.stringify(paramsRest),
  });
  const { account } = store.getState().auth;
  const accountId = (account && account.profile && account.profile.id) || '';
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }

  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/surveys/service/`;
  return FETCH(
    path,
    'POST',
    {
      Authorization,
      'Content-Type': 'application/json',
    },
    body,
  );
};

export { getSurveys, postSurveys };
