import { Button } from '../button/Button';
import { TUseTimerProps, useTimer } from './useTimer';
import Countdown, { zeroPad } from 'react-countdown';

type TTimerProps = {
  onTimeout?: () => void;
  onResendClick?: () => void;
  durationInSeconds: TUseTimerProps['durationInSeconds'];
};

export const Timer = ({ durationInSeconds, onTimeout, onResendClick }: TTimerProps) => {
  const { date } = useTimer({ durationInSeconds });

  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    return (
      <div className="font-open-sans text-[16px] leading-[21px] text-[#B1B1B1]">
        <div className="">{completed ? 'Chưa nhận được mã xác nhận' : 'Thời gian còn lại'}</div>
        {completed ? (
          <Button size={'standard'} onClick={onResendClick} color={'primary'} className="p-0">
            Gửi lại
          </Button>
        ) : (
          <div className="text-[#197FFC]">
            {minutes}:{zeroPad(seconds)} {minutes > 0 ? 'Phút' : 'Giây'}
          </div>
        )}
      </div>
    );
  };
  return (
    <div className="timer text-center">
      <Countdown date={date} renderer={renderer} onComplete={onTimeout} />
    </div>
  );
};
