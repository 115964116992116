import { RootState } from '@/store/store';
import {
  createDraftSafeSelector,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import window from 'global';

type TAPISliceState = {
  dataByPath: Record<string, any>;
};

const initialState: TAPISliceState = window?.__PRELOADED_STATE__?.apiCacheSSR ?? {
  dataByPath: {},
};

export const apiCacheSSRSlice = createSlice({
  name: 'apiCacheSSR',
  initialState,
  reducers: {
    addData: (state, { payload }: PayloadAction<any>) => {
      state.dataByPath[payload.path] = payload.data;
    },
    removeDataByPath: (state, { payload }: PayloadAction<any>) => {
      state.dataByPath[payload.path] = null;
    },
  },
});

export const { reducer: apiCacheSSRReducer } = apiCacheSSRSlice;
export const { addData, removeDataByPath } = apiCacheSSRSlice.actions;

export const selectAPICacheSSRDataByPath = (state: RootState) =>
  state[apiCacheSSRSlice.name]?.dataByPath;
export const selectAPIPath = (state: RootState, apiPath: string) => apiPath;
export const selectAPIByPath = createDraftSafeSelector(
  [selectAPICacheSSRDataByPath, selectAPIPath],
  (dataByPath, apiPath) => dataByPath?.[apiPath],
);
