import React, { Component, Suspense } from 'react';
import loadable from '@loadable/component';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/vi';
import { SmartBanner } from '@/components/SmartBanner/SmartBanner';

import { getConfigByKey, tenantConfig as tenantConfigs } from '@/features/tenantConfig/services';

import { insertScript } from '../functions/add-script';
import { detectTheme } from '@/features/theme/detectTheme';
import { setUserProperties } from '@/services/analytics/SetUserProperties';
import * as APIString from '../../constants/apiString';
import { setIsShowLogin, getPaidContent, resetPaymentMethod } from '@/features/auth/services';
import onsignalService from '@/services/onesignal/onesignalServices';
import initGTM from '@/services/gtm/gtmServices';
import firebaseService from '@/services/firebase/firebase';
import { PageLoading } from '@/components/loading';
import OverlayLoading from '@/components/loading/overlayLoading';
import pushAnalytics from '../../services/analytics/Analytics';

import { initialApp, checkGeoBlocking, getMuxEnvKey, getRegionSubscription } from '../services';

import { setPreviousLocation } from '../../features/history/services';

import DialogAppSubscription from '@/components/svodApp/DialogAppSubscription';
import { gtmVar_setHasSubscription, gtmVar_setIsLoggedIn } from '@/utils/gtm';
import { showMenu } from '@/components/global/globalServices';
import { getAccountInfo } from '@/features/profile/services';
import {
  showForgotPassModal,
  showLoginModal,
  showRegisterModal,
  hideLoginModalByKey,
  showLoginModalByKey,
} from '@/components/global/globalServices';
import { executeScriptBrowser } from '@/shared/utils/browserScript';
const LoginForm = loadable(() => import('@/features/auth/components/loginForm'), { ssr: false });
const RegisterForm = loadable(() => import('@/features/auth/components/registerForm'), {
  ssr: false,
});
const ResetPasswordForm = loadable(() => import('@/features/auth/components/resetPasswordForm'), {
  ssr: false,
});
const MessagePopup = loadable(() => import('@/features/auth/components/messagePopup'), {
  ssr: false,
});

const Toast = loadable(() => import('@/components/toast/index'));
const Backdrop = loadable(() => import('@/components/backdrop/index'));
const DefaultRouter = loadable(() => import('../components/render-router'));

class App extends Component {
  constructor(props) {
    super(props);
    this.myInterval = null;
    this.countCheckSlugDomain = 0;
    this.initConfig = false;
    const { dispatchInitialApp } = this.props;
    dispatchInitialApp();
    this.state = {
      BlockCountry: false,
      isRender: false,
      selectedTheme: detectTheme(getConfigByKey('tenant_slug')),
    };
  }

  _initConfigs() {
    onsignalService.initialize();
    initGTM();
    insertScript(tenantConfigs);
    firebaseService
      .initialize()
      .then(() => {
        setUserProperties();
      })
      .catch(() => {});
  }

  _navigate = ({ location }) => {
    const { dpGetAccountInfo } = this.props;
    dpGetAccountInfo().catch(() => {});
    pushAnalytics('optimize.activate');
    this.props.dpSetPreviousLocation(location);
  };

  _checkAuth = () => {
    const { auth } = this.props;
    const { account } = auth;
    const isHasSubscription =
      auth.isHasSubscription ||
      (account && account.profile && account.profile.has_subscription) ||
      false;

    gtmVar_setIsLoggedIn(auth.isLogin);
    gtmVar_setHasSubscription(isHasSubscription);
  };

  componentWillMount() {
    const { account, dpShowMenu } = this.props;
    if (account && !account.profile) this.props.dispatchResetPaymentMethod();
    this._checkAuth();
    this._initConfigs();
    dpShowMenu();
    this.unlisten = this.props.history.listen((location, action) => {
      this._navigate({ location, action });
    });
  }

  componentDidMount() {
    // initScriptShareFB()
    const { dispatchGetRegionSubscription, dispatchGetPaidContent, account } = this.props;
    if (account) {
      dispatchGetPaidContent().then().catch();
    }
    dispatchGetRegionSubscription()
      .then(() => {})
      .catch(() => {
        executeScriptBrowser(() => {
          window.document.body.classList.add('v-blockRegionSubscription');
        });
      });
  }

  componentWillReceiveProps(nextProps) {
    this._checkAuth();
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    const { selectedTheme, themeLoaded } = this.state;
    const {
      root,
      auth,
      blockCountry,
      dpShowForgotPassModal,
      dpShowLoginModal,
      dpShowRegisterModal,
      dpHideLoginModalByKey,
      dpShowLoginModalByKey,
      loginForm = {},
      children,
    } = this.props;
    moment.locale(root.language);

    if (!selectedTheme) {
      return <div></div>;
    }
    if (blockCountry.loading) {
      return <PageLoading />;
    }
    return (
      <>
        <div
          className="rootContainer"
          style={{
            position: APIString.COMMING_SOON === 'false' ? 'relative' : 'none',
          }}
        >
          {blockCountry.isBlock && <Redirect to="/block" />}
          {blockCountry.loaded && <DefaultRouter component={this} />}

          {/* <RequestSignIn /> */}
          {children}
          <SmartBanner
            title={'SCTVOnline'}
            // url={{
            //   android: 'https://play.google.com/store/apps/details?id=io.vimai.sctvonline&pli=1',
            //   ios: 'https://apps.apple.com/vn/app/sctv/id1564652065',
            // }}
            forceIcon={getConfigByKey('site_logo')}
          />
        </div>
        <LoginForm
          showForgotPassAction={() => dpShowForgotPassModal()}
          showRegisterAction={() => dpShowRegisterModal()}
          hideModal={key => dpHideLoginModalByKey(key)}
          modalShow={auth.isShowLogin}
          param={loginForm.param}
        />

        <RegisterForm
          modalShow={loginForm.showRegisterModal}
          showLoginAction={() => dpShowLoginModal()}
          hideModal={key => dpHideLoginModalByKey(key)}
          showModal={key => dpShowLoginModalByKey(key)}
        />

        <ResetPasswordForm
          modalShow={loginForm.showResetPasswordModal}
          hideModal={key => dpHideLoginModalByKey(key)}
        />

        <MessagePopup
          modalShow={loginForm.showMessagePopup}
          hideModal={key => dpHideLoginModalByKey(key)}
          showLoginAction={() => dpShowLoginModal()}
        />
        <Toast />
        <Backdrop />
        <DialogAppSubscription />
      </>
    );
  }
}

const mapDispatchToProps = {
  dispatchInitialApp: () => initialApp(),
  dispatchCheckGeoBlocking: () => checkGeoBlocking(),
  dispatchSetIsShowLogin: value => setIsShowLogin(value),
  dispatchGetMuxEnvKey: () => getMuxEnvKey(),
  dispatchGetRegionSubscription: () => getRegionSubscription(),
  dpSetPreviousLocation: preLocation => setPreviousLocation(preLocation),
  dispatchGetPaidContent: () => getPaidContent(),
  dispatchResetPaymentMethod: () => resetPaymentMethod(),
  dpShowMenu: () => showMenu(),
  dpGetAccountInfo: () => getAccountInfo(),
  dpShowForgotPassModal: () => showForgotPassModal(),
  dpShowLoginModal: param => showLoginModal(param),
  dpShowRegisterModal: () => showRegisterModal(),
  dpHideLoginModalByKey: key => hideLoginModalByKey(key),
  dpShowLoginModalByKey: key => showLoginModalByKey(key),
};

const mapStateToProps = state => ({
  account: state.auth.account,
  root: state.root,
  blockCountry: state.blockCountry,
  isLoading: state.loading.isLoading,
  auth: state.auth,
  tenantConfigs: state.tenantConfigs,
  loginForm: state.global.loginForm,
});

const connectedApp = withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
export { connectedApp as App };
