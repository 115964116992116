// import camelcaseKeys from 'camelcase-keys';

import { store } from '@/store/store';

import { getDeviceResolution, getNewImageType } from '@/shared/utils/utils';

import { getConfigByKey } from '@/shared/features/tenantConfig/services';
import { BASE_URL, BASE_URL_NO_CDN, REACT_APP_ENV } from '@/constants/apiString';
import { v4 as uuidv4 } from 'uuid';
import { getDefaultTypeImage } from '@/core/method/contentMethod';

interface IConfig {
  baseUrl: string;
  timezone: string;
  configuration: { basePath: string };
  noCDNBaseUrl: string;
  tenantSlug?: string;
  platformSlug?: string;
  acceptLanguage?: string;
  authorization?: string;
  userId?: string;
  accountId?: string;
  imageResolutionScale?: string;
  environment?: string;
  imageFormat?: string;
  session: string;
}

const getImageResolutionScale = () => {
  const { image_resolution_scale: imageResolutionScale = '' } = getDeviceResolution();
  return imageResolutionScale;
};

const getLocale = (): string => {
  const lng = getConfigByKey('currentLanguage') || getConfigByKey('default_locale');
  return lng || '';
};

const getUserId = () => {
  try {
    const { account, guestAccount } = (store.getState() as any).auth;
    const acc = account || guestAccount;

    const accountId = acc.profile.id || '';
    return accountId;
  } catch (error) {
    return undefined;
  }
};

const getAuthorization = () => {
  try {
    const { account, guestAccount } = (store.getState() as any).auth;
    const acc = account || guestAccount;

    let authorization = '';
    if (acc) {
      authorization = acc.access_token ? acc.access_token : '';
    }
    return authorization;
  } catch (error) {
    return undefined;
  }
};

const getImageFormat = () => {
  const { image_format: imageFormat } = getNewImageType();
  return imageFormat;
};

export const appConfig: IConfig = {
  baseUrl: BASE_URL || '',
  configuration: {
    basePath: BASE_URL || '',
  },
  noCDNBaseUrl: BASE_URL_NO_CDN || '',
  environment: REACT_APP_ENV,
  timezone: getConfigByKey('timezone'),
  session: uuidv4(),
  get tenantSlug() {
    return (getConfigByKey('ui_theme') as string) || '';
  },
  get platformSlug() {
    return (getConfigByKey('platform_slug') as string) || '';
  },
  get acceptLanguage() {
    return getLocale();
  },
  get userId() {
    return getUserId();
  },
  get accountId() {
    return getUserId();
  },
  get authorization() {
    return getAuthorization();
  },
  get imageResolutionScale() {
    return getImageResolutionScale();
  },
  get imageFormat() {
    return getImageFormat();
  },
};
