import { BaseSyntheticEvent, useCallback, useRef } from 'react';
import {
  FieldValues,
  SubmitHandler,
  useForm,
  UseFormProps,
  UseFormSetError,
} from 'react-hook-form';

export type UseAugmentedFormProps<TFormFields extends FieldValues = FieldValues> =
  UseFormOwnProps<TFormFields> & Omit<UseFormProps<TFormFields>, 'onSubmit'>;

export type UseFormOwnProps<TFormFields extends FieldValues = FieldValues> = {
  defaultValues?: TFormFields;
  onSubmit?: SubmitHandler<TFormFields>;
};
// export const setSubmissionErrors = <TFormFields extends FieldValues = FieldValues>(
//   errors: TFormFields,
//   setError: UseFormSetError<TFormFields>,
// ) => {
//   const setErrorFromObject = (errors: TFormFields) => {
//     Object.entries(errors).forEach(([name, error]) => {
//       setError(name, { message: error });
//     });
//   };
//   setErrorFromObject(errors);
// };
export const useAugmentedForm = <TFormFields extends FieldValues = FieldValues>({
  onSubmit,
  ...rest
}: UseAugmentedFormProps<TFormFields>) => {
  // submit callbacks
  const form = useForm(rest);
  const formRef = useRef(form);

  const handleSubmit = useCallback(
    async (values, event) => {
      let errors;
      const finalValues = values;
      if (onSubmit) {
        errors = await onSubmit(finalValues, event);
      }
    },
    [onSubmit],
  );

  const formHandleSubmit = useCallback(
    (event: BaseSyntheticEvent) => {
      if (!event.defaultPrevented) {
        // Prevent outer forms to receive the event
        event.stopPropagation();
        form.handleSubmit(handleSubmit)(event);
      }
      return;
    },
    [form, handleSubmit],
  );

  return {
    form,
    handleSubmit,
    formHandleSubmit,
  };
};
