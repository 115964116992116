import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import { clsx } from 'clsx';
import { ReactNode, RefObject, useCallback, useEffect, useMemo } from 'react';
import { FieldValues, FormProvider, useForm, UseFormProps } from 'react-hook-form';
import { Button } from '@/shared/components/button/Button';
import { twMerge } from 'tailwind-merge';
import { TitleForm, TitleFormProps } from './TitleForm';
import { useAugmentedForm, UseAugmentedFormProps } from './useAugmentedForm';
import { ThemeFormContext, ThemeFormProvider, TThemeFormProviderProps } from './ThemeFormContext';
import { compact, forIn, isEmpty, valuesIn, keysIn } from 'lodash';
import { HelperTextForm } from './HelperTextForm';

export type FormProps<TFormFields extends FieldValues = FieldValues> = {
  onSubmit: UseAugmentedFormProps<TFormFields>['onSubmit'];
  confirmTermAndPrivacy?: boolean;
  systemMessage?: string | null;
  systemMessageType?: 'error' | 'info';
  initialForm: Omit<UseAugmentedFormProps<TFormFields>, 'onSubmit'>;
  children: (params: any) => ReactNode;
  className?: string;
  submitLabel?: string;
  timer?: ReactNode;
  actionClassName?: string;
  footer?: ReactNode;
  titleForm?: string;
  detailDescription?: string;
  detailDescriptionJsx?: JSX.Element;
  titleFormProps?: Omit<TitleFormProps, 'children'>;
  serverErrors?: TFormFields;
  schema: any;
  formRef: RefObject<HTMLElement>;
  submitRef: RefObject<HTMLButtonElement>;
  isLoading?: boolean;
  errorEachField?: boolean;
  displaySystemMessage?: boolean;
} & Omit<TThemeFormProviderProps, 'children'>;

export const Form = <TFormFields extends FieldValues = FieldValues>({
  onSubmit,
  children,
  systemMessage = '',
  initialForm,
  className,
  submitLabel = 'Xác nhận',
  timer = null,
  actionClassName,
  footer,
  systemMessageType = 'error',
  titleForm,
  detailDescription,
  titleFormProps = {},
  themeMode,
  detailDescriptionJsx,
  serverErrors,
  schema,
  formRef,
  submitRef,
  isLoading = false,
  errorEachField = false,
  displaySystemMessage = true,
}: FormProps<TFormFields>) => {
  const { formHandleSubmit, form } = useAugmentedForm<TFormFields>({ onSubmit, ...initialForm });
  const {
    formState: { errors, isValid, isSubmitted, isSubmitting, submitCount },
    formState,
    getValues,
    setError,
    watch,
  } = form;

  const values = watch();
  const isFillFullyForm = useMemo(
    () =>
      !keysIn(values)
        .filter(nameField => {
          const isRequired = schema?.fields?.[nameField]?.exclusiveTests?.required === false;
          const isStringType = schema?.fields?.[nameField]?.type === 'string';
          const isAgreeField = nameField === 'agree';
          const isBooleanType = schema?.fields?.[nameField]?.type === 'boolean';

          if ((isStringType && !isRequired) || (isBooleanType && !isAgreeField)) {
            return false;
          }
          return true;
        })
        .some(nameField => {
          return !values?.[nameField];
        }),
    [schema, values],
  );

  const arrayErrors = useMemo(() => {
    return compact(Object.values(formState?.errors).map(err => err?.message));
  }, [formState]);

  const disabledButton = isLoading || (!isSubmitted ? !isFillFullyForm : isSubmitting || !isValid);
  // const disabledButton = isLoading || !isFillFullyForm || isSubmitting;
  const handleServerError = useCallback(() => {
    if (!serverErrors) {
      return;
    }
    for (const [fieldName, errorMessage] of Object.entries(serverErrors)) {
      setError(fieldName as any, { message: errorMessage, type: 'custom' });
    }
  }, [serverErrors, setError]);

  useEffect(() => {
    handleServerError();
  }, [handleServerError]);

  return (
    <ThemeFormProvider themeMode={themeMode}>
      <FormProvider {...form}>
        <form
          className={clsx('form', twMerge('', className))}
          onSubmit={formHandleSubmit}
          data-mode={themeMode}
          ref={formRef}
        >
          {titleForm && (
            <TitleForm className="mt-0" {...titleFormProps}>
              {titleForm}
            </TitleForm>
          )}
          {detailDescriptionJsx && detailDescriptionJsx}
          {detailDescription && (
            <p className="detail-description mb-[26px] mt-[7px] text-center text-[16px] text-[#1E232A] md:mb-[41px] dark:text-[#9B9B9B]">
              {detailDescription}
            </p>
          )}

          <Box className="inputs">
            {children(form, { arrayErrors })}
            {displaySystemMessage ? (
              <HelperTextForm type={systemMessageType} message={systemMessage}></HelperTextForm>
            ) : null}
          </Box>
          <Box className={clsx('actions', twMerge('mb-[20px] mt-[15px]', actionClassName))}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={disabledButton}
              size="standard"
              padding="standard"
              ref={submitRef}
              className="submit-button"
            >
              {submitLabel}
            </Button>
          </Box>
          {timer}
          {!!footer && <div className="form__footer">{footer}</div>}
        </form>
      </FormProvider>
    </ThemeFormProvider>
  );
};
