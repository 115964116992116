import { loginPhoneNumberSCTV, registerEmail_SCTV } from '@/shared/features/auth/services';
import { confirmEmail } from '@/shared/features/confirmation/services';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

export type TUseVerifyPhoneNumberFormProps = {
  onSuccess?: (res: any) => void;
  onError?: (error: any) => void;
  onIsLoading?: (isLoading: boolean) => void;
  userRegisterInfo: {
    phoneNumber: string;
    password: string;
    firstName?: string;
    lastName?: string;
    promoCodeDetail?: any;
    accept: boolean;
  };
};
export const useVerifyPhoneNumberForm = ({ userRegisterInfo }: TUseVerifyPhoneNumberFormProps) => {
  const dispatch = useDispatch();
  const [systemMessageError, setSystemMessageError] = useState('');

  const handleValidateOtp = ({ password, phoneNumber, otp }: any) => {
    const { promoCodeDetail } = userRegisterInfo;

    const { code: promo_code } = promoCodeDetail?.redeem_code ?? {};
    let params = {
      phone_number: phoneNumber,
      validate_type: 'mobi',
      otp,
    };
    if (promo_code) {
      params.promo_code = promo_code;
    }
    setSystemMessageError('');
    return confirmEmail(params)(dispatch).catch((error: any) => {
      const { message = '' } = error;
      setSystemMessageError(message);
      throw error;
    });
  };

  const handleLogin = ({ password, phoneNumber }: any) => {
    const { promoCodeDetail } = userRegisterInfo;
    return loginPhoneNumberSCTV(phoneNumber, password, { promoCode: promoCodeDetail })(dispatch);
  };

  const handleResendOTP = (
    userRegisterInfo: TUseVerifyPhoneNumberFormProps['userRegisterInfo'],
  ) => {
    let params = {
      firstName: (userRegisterInfo?.firstName ?? '').split(' ').slice(-1).join(' '),
      lastName: (userRegisterInfo?.lastName ?? '').split(' ').slice(0, -1).join(' '),
      phoneNumber: userRegisterInfo.phoneNumber,
      password: userRegisterInfo.password,
      type: 'requestOTP',
      accept: userRegisterInfo?.accept,
    };
    setSystemMessageError('');

    return registerEmail_SCTV(params)(dispatch).catch((error: any) => {
      const { message = '' } = error;
      setSystemMessageError(message);
      throw error;
    });
  };

  return {
    handleValidateOtp,
    handleLogin,
    handleResendOTP,
    systemMessageError,
  };
};
