import { useAppDispatch, useAppSelector } from '@/core/hook/appHook';
import { profileSelector } from '@/features/auth/reducers';
import { changeProfile } from '@/features/profile/services';
import { useCallback } from 'react';

export const useVerifyTermOfUse = () => {
  const profile = useAppSelector(profileSelector);

  const dispatch = useAppDispatch();
  const updateTermOfUse = useCallback(
    (acceptTermOfUse: boolean) => {
      const { first_name, last_name, phone_number, gender, dob } = profile ?? {};
      return changeProfile(
        first_name,
        last_name,
        phone_number,
        gender,
        dob,
        acceptTermOfUse,
      )(dispatch);
    },
    [dispatch, profile],
  );
  return { updateTermOfUse, profile };
};
