import React, { useState, useEffect } from 'react';
import UAParser from 'ua-parser-js';
import Cookies from 'js-cookie';
import CloseIcon from '@material-ui/icons/Close';
import './smart-banner.scss';
import { IconButton } from '@material-ui/core';
import { getConfigByKey } from '@/features/tenantConfig/services';
import smartBanner from '@/assets/img/sctv/smart-banner.png';
import { useTranslation } from 'react-i18next';

const mobileDeepLinkConfig = getConfigByKey('mobile_deep_link_config') || {};

const { mobile_ios_deep_link, mobile_android_deep_link, mobile_app_deep_link_banner } =
  mobileDeepLinkConfig;

// const {
//   mobile_ios_deep_link = getConfigByKey('appDeepLink.ios'),
//   mobile_android_deep_link = getConfigByKey('appDeepLink.android'),
//   mobile_app_deep_link_banner = 'https://s3.cloud.cmctelecom.vn/quangtritv-storage/tenants/none_tenant/photos/smart-banner_ee642097.png',
// } = mobileDeepLinkConfig;

// https://s3.cloud.cmctelecom.vn/quangtritv-storage/tenants/none_tenant/photos/smart-banner_ee642097.png
const setCookie = Cookies.set;
const getCookie = Cookies.get;

interface SmartBannerProps {
  daysHidden?: number;
  daysReminder?: number;
  appStoreLanguage?: string;
  button?: React.ReactNode;
  storeText?: Record<string, string>;
  price?: Record<string, string>;
  force?: string;
  title?: string;
  author?: string;
  position?: string;
  url?: Record<string, string>;
  ignoreIosVersion?: boolean;
  appMeta?: {
    android: string;
    ios: string;
    windows: string;
    kindle: string;
  };
  onClose?: () => void;
  onInstall?: () => void;
  forceIcon?: string;
}

export const SmartBanner: React.FC<SmartBannerProps> = ({
  daysHidden = 15,
  daysReminder = 90,
  button = 'Mở',
  storeText = {
    ios: 'On the App Store',
    android: 'In Google Play',
    windows: 'In Windows Store',
    kindle: 'In the Amazon Appstore',
  },
  price = {
    ios: 'Free',
    android: 'Free',
    windows: 'Free',
    kindle: 'Free',
  },
  force = '',
  title = '',
  author = '',
  position = 'top',
  url = {
    ios: mobile_ios_deep_link,
    android: mobile_android_deep_link,
    windows: '',
    kindle: '',
  },
  appMeta = {
    ios: 'apple-itunes-app',
    android: 'google-play-app',
    windows: 'msApplication-ID',
    kindle: 'kindle-fire-app',
  },
  onClose,
  onInstall,
  forceIcon,
  ignoreIosVersion = true,
  ...props
}) => {
  const [type, setType] = useState<string>('');
  const [appId, setAppId] = useState<string>('');
  const [settings, setSettings] = useState<Record<string, any>>({});
  const { i18n } = useTranslation();

  const { appStoreLanguage = i18n.language } = props;

  useEffect(() => {
    setTypeFromUA();
    updatePositionClass(position);
  }, [position]);

  useEffect(() => {
    if (settings && type) {
      parseAppId(type);
    }
  }, [settings, type]);

  const setTypeFromUA = () => {
    const parser = new UAParser();
    const result = parser.getResult();

    let detectedType = '';

    if (force) {
      detectedType = force;
    } else if (result.os.name === 'Windows Phone' || result.os.name === 'Windows Mobile') {
      detectedType = 'windows';
    } else if (
      result.os.name === 'iOS' &&
      (ignoreIosVersion ||
        parseInt(result.os.version, 10) < 6 ||
        result.browser.name !== 'Mobile Safari')
    ) {
      detectedType = 'ios';
    } else if (result.device.vendor === 'Amazon' || result.browser.name === 'Silk') {
      detectedType = 'kindle';
    } else if (result.os.name === 'Android') {
      detectedType = 'android';
    }
    setType(detectedType);
    setSettingsByType(detectedType);
  };

  const setSettingsByType = (type: string) => {
    const typeSettings = {
      ios: {
        appMeta: () => appMeta.ios,
        iconRels: ['apple-touch-icon-precomposed', 'apple-touch-icon'],
        getStoreLink: () => `https://itunes.apple.com/${appStoreLanguage}/app/id`,
      },
      android: {
        appMeta: () => appMeta.android,
        iconRels: ['android-touch-icon', 'apple-touch-icon-precomposed', 'apple-touch-icon'],
        getStoreLink: () => 'http://play.google.com/store/apps/details?id=',
      },
      windows: {
        appMeta: () => appMeta.windows,
        iconRels: ['windows-touch-icon', 'apple-touch-icon-precomposed', 'apple-touch-icon'],
        getStoreLink: () => 'http://www.windowsphone.com/s?appid=',
      },
      kindle: {
        appMeta: () => appMeta.kindle,
        iconRels: ['windows-touch-icon', 'apple-touch-icon-precomposed', 'apple-touch-icon'],
        getStoreLink: () => 'amzn://apps/android?asin=',
      },
    };

    setSettings(typeSettings[type] || {});
  };

  const parseAppId = (type: string) => {
    const metaTag = document.querySelector(`meta[name="${settings.appMeta()}"]`);
    if (!metaTag) return '';

    let parsedAppId = '';
    if (type === 'windows') {
      parsedAppId = metaTag.getAttribute('content') || '';
    } else {
      const match = /app-id=([^\s,]+)/.exec(metaTag.getAttribute('content') || '');
      parsedAppId = match && match[1] ? match[1] : '';
    }
    setAppId(parsedAppId);
  };

  const retrieveInfo = () => {
    const link = url[type] || settings.getStoreLink() + appId;
    const inStoreText = `${price[type]} - ${storeText[type]}`;
    let icon = forceIcon ?? {};

    if (!forceIcon) {
      for (let i = 0; i < settings.iconRels.length; i++) {
        const iconTag = document.querySelector(`link[rel="${settings.iconRels[i]}"]`);
        if (iconTag) {
          icon = iconTag.getAttribute('href') || '';
          break;
        }
      }
    }

    return { icon, link, inStoreText };
  };

  const hide = () => {
    document.querySelector('html')?.classList.remove('smartbanner-show');
  };

  const show = () => {
    document.querySelector('html')?.classList.add('smartbanner-show');
  };

  const close = () => {
    hide();
    setCookie('smartbanner-closed', 'true', { path: '/', expires: getExpirationDate(daysHidden) });
    onClose?.();
  };

  const install = () => {
    hide();
    setCookie('smartbanner-installed', 'true', {
      path: '/',
      expires: getExpirationDate(daysReminder),
    });
    onInstall?.();
  };

  const getExpirationDate = (days: number) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  };

  const updatePositionClass = (position: string) => {
    const htmlElement = document.querySelector('html');
    if (position === 'top') {
      htmlElement?.classList.add('smartbanner-margin-top');
      htmlElement?.classList.remove('smartbanner-margin-bottom');
    } else if (position === 'bottom') {
      htmlElement?.classList.add('smartbanner-margin-bottom');
      htmlElement?.classList.remove('smartbanner-margin-top');
    }
  };
  if (
    !type ||
    navigator.standalone ||
    getCookie('smartbanner-closed') ||
    getCookie('smartbanner-installed')
  ) {
    return null;
  }
  const { icon, link, inStoreText } = retrieveInfo();

  if (!link || !mobile_app_deep_link_banner) {
    return null;
  }

  show();
  const bannerClass = `smartbanner smartbanner-${type} smartbanner-${position}`;
  const iconStyle = { backgroundImage: `url(${icon})` };

  return (
    <div className={bannerClass}>
      <div className="smartbanner-container">
        <IconButton type="button" className="smartbanner-close" aria-label="close" onClick={close}>
          <CloseIcon style={{ fontSize: '24px' }} />
        </IconButton>
        {/* <span className="smartbanner-icon" style={iconStyle} />
        <div className="smartbanner-info">
          <div className="smartbanner-description">Nâng tầm trải nghiệm của bạn với</div>

          <div className="smartbanner-title">{title}</div>
          <div className="smartbanner-description smartbanner-description--center">
            trên ứng dụng điện thoại
          </div>
        </div>
        <div className="smartbanner-wrapper">
          <a href={link} onClick={install} className="smartbanner-button">
            <span className="smartbanner-button-text">{button}</span>
          </a>
        </div> */}
        <a href={link} onClick={install} className="smartbanner-banner">
          <img src={mobile_app_deep_link_banner} />
        </a>
      </div>
    </div>
  );
};
