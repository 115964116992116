import { VIMAI_API_PROTECTION_V1_CONFIG_ID } from '@/constants/envConfig';

import moment from 'moment';
import { sha256 } from 'js-sha256';
import { getBuildSecretKey } from './buildSecretKey';
import { debug } from '@/utils/utils';

//SHA256({accountId} + "," + {current timestamp in (s)} + "," {api path} + "," + {call to obfuscated get-build-secret-key function}}
export const requestSignatureFromBuildSecretKey: (
  accountId?: string,
  apiPath?: string,
  currentTime?: number,
) => string = (accountId = '', apiPath = '', currentTime = 0) => {
  const shaInput = `${accountId},${currentTime},${apiPath},${getBuildSecretKey()}`;
  // debug(() => {
  //   console.log('requestSignatureFromBuildSecretKey: ', apiPath, shaInput);
  // });
  return sha256(shaInput);
};
// {API Protection Config Id}.{Current Timestamp In (s)}.{Request Signature}
export const getRequestAuthHeader = (accountId?: string, apiPath?: string) => {
  const currentTime = moment().unix();
  return `${VIMAI_API_PROTECTION_V1_CONFIG_ID}.${currentTime}.${requestSignatureFromBuildSecretKey(accountId, apiPath, currentTime)}`;
};
