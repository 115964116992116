import { Global, css } from '@emotion/react';
import { detectTheme } from '@/shared/features/theme/detectTheme';
import { getConfigByKey } from '@/shared/features/tenantConfig/services';

export const globalStyles = theme => css`
  body {
    background: ${theme.body.background};
    font-family: ${theme.body.font};
    .rootContainer {
      /* background: ${theme.homePage.background}; */
      font-family: ${theme.body.font};
      .v-home--container,
      .ribbonPageContainer,
      .ribbonContainer {
        background: ${theme.homePage.backgroundRibbon};
      }
      .ribbonHeadAll,
      .texttruncate {
        color: ${theme.ribbon.itemNameColor};
        display: ${theme.ribbon.displayTitleItem};
      }
      .language--name {
        color: ${theme.primaryColor};
      }
    }
  }
`;
export const GlobalStyles = () => {
  const theme = detectTheme(getConfigByKey('tenant_slug'));

  return <Global styles={globalStyles(theme)} />;
};
