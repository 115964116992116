import { useState } from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormHelperText } from '@material-ui/core';
import i18n from 'i18next';
import { verifyEmailFormat, verifyPhoneNumber } from '@/shared/utils/utils';
import { loginEmail } from '@/shared/features/auth/services';
import { useDispatch } from 'react-redux';
import { Form } from '@/shared/components/form/Form';
import { BaseInput } from '../BaseInput';
import { PasswordInput } from '../PasswordInput';
import { Button } from '@/shared/components/button/Button';
import clsx from 'clsx';

const schema = yup
  .object({
    email: yup
      .string()
      .required('Vui lòng nhập email hoặc số điện thoại')
      .test({
        name: 'email',
        test: (emailValue = '', ctx) =>
          !/^[0-9]+$/.test(emailValue) ? verifyEmailFormat(emailValue) : true,
        message: i18n.t('alertWrongEmailFormat'),
      })
      .test({
        name: 'phone',
        test: (emailValue, ctx) =>
          /^[0-9]+$/.test((emailValue = '')) ? verifyPhoneNumber(emailValue) : true,
        message: i18n.t('invalid_phone_number'),
      }),
    password: yup.string().required('Vui lòng nhập password'),
  })
  .required();

type FormFields = yup.InferType<typeof schema>;

const initValues: FormFields = {
  email: '',
  password: '',
};

type LoginFormProps = {
  onSubmitCallback?: () => void;
  onForgotPasswordClick?: () => void;
};
export const LoginForm = ({
  onSubmitCallback = () => {},
  onForgotPasswordClick = () => {},
}: LoginFormProps) => {
  const dispatch = useDispatch();

  const [systemMessage, setSystemMessage] = useState('');

  const handleOnSubmit = async (values: any) => {
    try {
      await loginEmail(values?.email, values?.password)(dispatch);
      onSubmitCallback();
    } catch (error: any) {
      setSystemMessage(error?.message);
    }
  };

  return (
    <Box>
      <Form
        className="form"
        onSubmit={handleOnSubmit}
        // systemMessage={message}
        submitLabel="Đăng Nhập"
        initialForm={{
          defaultValues: initValues,
          resolver: yupResolver(schema) as any,
        }}
        titleFormProps={{ variant: 'short', className: 'mb-[43px] md:mb-[28px]' }}
        titleForm="Đăng Nhập"
      >
        {({ formState: { errors }, control }) => (
          <Box className="fields">
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <BaseInput
                  {...field}
                  fullWidth
                  label="Địa chỉ email hoặc số điện thoại người dùng"
                  error={errors?.email?.message}
                  required
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <PasswordInput
                  {...field}
                  fullWidth
                  label="Mật khẩu"
                  error={errors?.password?.message}
                  required
                  // twClassName={clsx('mb-[15px]')}
                />
              )}
            />
            <FormHelperText
              className={clsx(
                'system-error',
                'mb-[16px] mt-[-10px] text-[12px] [&.Mui-error]:text-primary',
              )}
              error
            >
              {systemMessage}
            </FormHelperText>
            <Button
              className="mt-[-5px] block text-[16px] text-[#1E232A] hover:underline md:text-[16px]"
              type="button"
              padding={'none'}
              onClick={onForgotPasswordClick}
            >
              Quên mật khẩu?
            </Button>
          </Box>
        )}
      </Form>
    </Box>
  );
};
