import FormHelperText from '@material-ui/core/FormHelperText';
import clsx from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  message?: string | null;
  type?: 'error' | 'info';
  className?: string;
};

export const HelperTextForm = ({ message, type, className }: Props) => {
  if (!message) {
    return null;
  }
  return (
    <FormHelperText
      className={twMerge(
        clsx('system-error', 'mb-[20px] text-[12px] [&.Mui-error]:text-[#E30000] [&_p]:m-0', {
          'info [&.info]:text-[#2E88FF]': type !== 'error',
        }),
        className,
      )}
      error={type === 'error'}
    >
      {message}
    </FormHelperText>
  );
};
