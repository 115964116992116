export const TVOD_PAYMENT_TYPE = 'tvod';
export const SVOD_PAYMENT_TYPE = 'svod';
export const FREE_PAYMENT_TYPE = 'free';
export const HYBRID_PAYMENT_TYPE = 'hybrid';

export enum PaymentTypeEnum {
  TVOD = TVOD_PAYMENT_TYPE,
  SVOD = SVOD_PAYMENT_TYPE,
  FREE = FREE_PAYMENT_TYPE,
  HYBRID = HYBRID_PAYMENT_TYPE,
}
