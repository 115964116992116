import { RootState } from '@/store/store';
import { createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IAuthenticationSlice {
  isOpenLoginDialog: boolean;
}

const initialState: IAuthenticationSlice = { isOpenLoginDialog: false };
const authenticationSlice = createSlice({
  name: 'authenticationSlice',
  initialState,

  reducers: {
    setOpenLoginDialog: (
      state,
      { payload }: PayloadAction<IAuthenticationSlice['isOpenLoginDialog']>,
    ) => {
      state.isOpenLoginDialog = payload;
    },
    toggleLoginDialog: state => {
      state.isOpenLoginDialog = !state.isOpenLoginDialog;
    },
    closeLoginDialog: state => {
      state.isOpenLoginDialog = false;
    },
    openLoginDialog: state => {
      state.isOpenLoginDialog = false;
    },
  },
});

export const { setOpenLoginDialog, toggleLoginDialog, closeLoginDialog, openLoginDialog } =
  authenticationSlice.actions;

const authenticationReducer = authenticationSlice.reducer;
const selectSelf = (state: RootState) => state;
export const selectIsOpenLoginDialog = createDraftSafeSelector(
  selectSelf,
  state => state.authentication.isOpenLoginDialog,
);
export { authenticationReducer };
