import { getConfigByKey } from '@/shared/features/tenantConfig/services';

const en = {
  translations: {
    locale: {
      vi: 'Vietnamese',
      en: 'English',
      ko: '한국인',
    },
    vietnam: `Vietnamese`,
    english: `English`,
    formatDob: `dd/MM/yyyy`,
    premium: {
      on: `VIP On`,
      off: `Turn Off Ads`,
    },
    month: 'month',
    month_plural: 'months',
    monthly: `Month`,
    invalid_phone_number: 'The phone number is not valid.',
    alertWrongEmailFormat: `The email format is not valid. Please recheck your email and try again.`,
    alertNullEmailPass: `Please enter email and password.`,
    ribbon: {
      view: `View`,
      like: `like`,
      all: `See all`,
      txtViewMore: `View More`,
      slide: {
        txtWatchLive: 'Watch Live',
        txtMoreInfo: 'More Info',
        watch: 'Watch Now',
      },
      removeFavorite: {
        txtTitle: `Are you sure?`,
        txtDes: `Remove this content from My List page.`,
      },
      txtSelectCategory: `categories`,
    },
    navigation: {
      login: `Sign In`,
      logout: `Sign Out`,
      profile: `Profile`,
      search: `Search`,
      account: `Accounts`,
    },
    search: {
      message: `There is no result with the search keywords `,
      result: `There are {{number}} results with the search keywords " {{name}} "`,
      tags: `Tags`,
      hint: `Recommendation - Same category`,
      movie: `Hot movie`,
    },
    popupMessage: {
      cancelPlan: {
        title: `Do you want to cancel your current subscription?`,
        description: `After cancelling, you will still be able to use the service until {{daytime}}`,
        btnNo: `No. I clicked wrong.`,
        btnYes: `Yes. I want to cancel!`,
      },
      cancelPlanSuccess: {
        title: `You have successfully cancelled your subscription`,
        description: `Your subscription has been cancelled and will be expired on {{daytime}}. Thank you for using ${getConfigByKey(
          'name',
        )}!`,
      },
      payment: {
        title: `Payment Check Out`,
        service: `Service`,
        CardInfo: `Debit/Credit card information`,
        autoActive: `automatically renewed monthly`,
        buttonCheckOut: `Checkout \${{money}}`,
        txtTitlePaymentSuccess: `Your transaction is completed`,
        txtDesPaymentSuccess: `Your subscription has been successfully changed to {{name}}.`,
        txtMoMoDesc: `*Sau khi bạn bấm hoàn tất mua hàng bằng Momo, hệ thống sẽ hiển thị mã QR kèm hướng dẫn. Bạn cần tải ứng dụng Momo trên điện thoại và sử dụng để quét mã QR trên để thanh toán. Gói dịch vụ sẽ được kích hoạt sau khi bạn thanh toán thành công.`,
        txtPaymentConfirmMoMo: `Complete Payment With MoMo`,
        month: `month`,
        year: `year`,
        day: `day`,
        hour: `hour`,
        hours: `hours`,
      },
      messageEmailSucces: {
        description1: `A confirmation email has been sent to your mailbox.`,
        description2: `Please find the confirmation email in your mailbox and click on confirmation link to confirm your email.`,
        description3: `Your email will only be changed after`,
        description4: `you\'ve confirmed your new email.`,
      },
      buttonYes: `Yes`,
      buttonNo: `No`,
      buttonExit: `Close`,
      buttonOkie: `OK`,
      updateSubscriptionSuccess: `Updated Success!`,
      desscriptionUpdateSubscriptionSuccess: `Payment is completed, your subscription has been upgraded and effects immediately`,
    },
    loginScreen: {
      title: `To continue, please sign in`,
      ondemandviet: `${getConfigByKey('name')}`,
      Or: `Or`,
      placeholderEmail: {
        default: `Your email`,
        odv: `Your email`,
        sctv: 'Your email or phone number',
        projectw: `Your email`,
      },
      placeholderPassword: `Your password`,
      textNotAccount: `Don\'t have an account yet?`,
      buttonTextRegis: ` Register new account`,
      buttonTextForgotPass: `Forgot your password?`,
      buttonTextSignIn: `Sign In`,
      buttonContinueFb: `Sign in with Facebook`,
      buttonTextSignInPhone: `Login via phone number`,
      buttonTextSignUpFacebook: `Sign up with Facebook`,
      buttonTextSignInFacebook: `Sign in with Facebook`,
      buttonTextSignInGoogle: `Login via Google`,
      buttonTextForgotPassword: `Forgot your password?`,
      buttonTextSignUp: `Register new account`,
      loginFBErrorMessage: `There are some problems while logging in via facebook, please try again.`,
      alertLoginFail: `There are some problems while logging in, please try again.`,
      alertNetWorkError: `There are some problems while connecting to the system, please try again.`,
      cbRememberMe: `Remember me`,
    },
    registerScreen: {
      gender: {
        male: `Male`,
        female: `Female`,
        underfind: `Prefer not to answer`,
      },
      signUp: `Sign Up`,
      signIn: `Sign In`,
      txtName: `Name`,
      txtRePassword: `Re-enter Password`,
      titleRegis: `Register with your email`,
      titleBinding: `The fields with (*) is mandatory`,
      placeholderEmail: `Email `,
      placeholderFirstName: `Last name `,
      placeholderLastName: `First name `,
      placeholderPassword: `Password `,
      placeholderPhone: `Phone number `,
      placeholderName: `Full name `,
      placeholderGender: `Gender `,
      placeholderBirthday: `Date of birth `,
      buttonTextSignUp: `Register`,
      buttonTextSignIn: `Login`,
      textHaveAccount: `Already a member?`,
      textSucces: `You have successfully register a new account.`,
      textLogin1: `Congratulation! Please `,
      login: `login `,
      textLogin2: `to continue using our service.`,
      alertPassLength: `Password must have at least 5 characters`,
      alertNull: `The registration information is mandatory, please fill in the information`,
      alertSuccess: `You have successfully registered a new account, please check your mailbox to confirm the account email`,
      alertError: `There are some problems while registering a new account. Please check and try again.`,
      lblBirthday: `Your birthday`,
      msgAgeWarning: `* You must be 13 years old or above to sign up`,
    },
    profile: {
      overview: `Account Overview`,
      brief: `Profile`,
      authEmail: `Your email has not been confirmed, please go to your mailbox and confirm your email to continue using the service`,
      change: `Change`,
      cancelPlan: `Cancel subscription`,
      'number account': `Account code`,
      name: `Full name`,
      email: `Email`,
      're email': `Resend confirmation email`,
      dob: `Date of birth`,
      phone: `Phone number`,
      sex: `Gender`,
      'service pack': `Your subscription`,
      'privacy policy': `Privacy policy`,
      'box streaming': `Terms of use`,
      'otp odv': `Login code for ${getConfigByKey('short_name')} TV Box`,
      'countdown time': `Automatically refreshed after `,
      second: `seconds`,
      'auth otp odv': `The login code for is used to quickly login/activate the `,
      odv: `${getConfigByKey('short_name')} TV Box`,
      'auto plan': `Automatically renewed on`,
      'cancel plan': `The subscription has been cancelled and will be expired on`,
      'not registration plan': `( You have not subscribed )`,
      activated: `Activated`,
      notUpdate: `Not updated`,
      accountFree: `Free Account`,
      accountNoStb: `{{name}} Account`,
      detailAccFree: `Free account can\'t be used to login/activate the ${getConfigByKey(
        'short_name',
      )} TV Box. Please subscribe to get the login code for ${getConfigByKey(
        'short_name',
      )} TV Box.`,
      detailAccNostb: `{{name}} account can\'t be used to login/activate the ${getConfigByKey(
        'short_name',
      )} TV Box. Please subscribe to get the login code for ${getConfigByKey(
        'short_name',
      )} TV Box.`,
      buttonRegPlan: `Upgrade subscription`,
      navigation: {
        overView: `Profile`,
        updateInfo: `Update profile`,
        upgratePlan: `Change subscription`,
        transaction: `Payment history`,
        changePass: `Change password`,
        contactUs: `Contact us`,
        paymentInformation: `Credit card information`,
      },
      expireTimePackage: 'The service package will expire on',
    },
    changeInformation: {
      updateAccount: `Update information`,
      email: `Email`,
      brief: `Profile`,
      password: `Password`,
      phone: `Phone number`,
      name: `Name`,
      firstName: `First name`,
      lastName: `Last name`,
      gender: `Gender`,
      changeEmail: `Change email`,
      dob: `Date of birth`,
      loginFb: `You have not sign in via Facebook`,
      male: `Male`,
      female: `Female`,
      another: `Prefer not to answer`,
      select: `Select gender...`,
      buttonSubmit: `Submit`,
      changeEmailScreen: {
        buttonChange: `Update`,
        newEmail: `Type your new email`,
        reNewEmail: `Retype your new email`,
        warring: `* Email will be used for login into the account. After changing the email, you will need to use this new email for next login, and the old email will be invalid.`,
      },
      alert: {
        nullEmail: `Please type your email, please check and try again.`,
        validEmail: `Email format is not valid, please check and try again.`,
        wrongEmail: `The email is not valid, please check and try again.`,
        nullFirstName: `Please enter your first name at least 2 characters, please check and try again.`,
        nullLastName: `Please enter your last name at least 2 characters, please check and try again.`,
        nullbod: `Please enter your birthday`,
        nullgender: `Please select your gender`,
        validbod: `The birthday is not valid, please check and try again.`,
        validPhone: `The phone number is not valid, please check and try again.`,
        messageSucces: `Your information has been changed successfully.`,
      },
    },
    transactionHistory: {
      bill: `Invoices`,
      myPlan: `your subscription`,
      cancelPlan: `Cancelled and will expire on`,
      autoPlan: `Automatically renew on`,
      timeUse: `Time to use`,
      cancel: `Cancel the subscription {{name}}`,
      description: `Description`,
      odv: `${getConfigByKey('short_name')} TV Box`,
      total: `Total`,
      durationDay: `Subscription {{name}} from {{periodStart}} to {{periodEnd}}`,
      txtBuyBox: `STB Price (one-time)`,
      txtRentBox: `STB Monthly Rental`,
      freeAccount: `Free account`,
      servicePackageUpgrade: 'Upgrade Subscription',
      package: `Package`,
    },
    availableplan: {
      plan: `Subscription plans`,
      buttonUpdate: `Update subscription plan`,
      countDownTime: ` trial days remaining`,
      MisscountDownTime: '',
      autoActive: `Automatic monthly renewal`,
      alertPopup: {
        txtTitle: `Your service package selected requires TiVi ${getConfigByKey('short_name')} Box`,
        btnHaveBox: `I already have an ${getConfigByKey('name')} TV Box`,
        btnHaveNotBox: `I need an ${getConfigByKey('name')} TV Box`,
      },
      promoZero: `Your {{discount}} off promo code will be applied to your {timesApplied} next payment.`,
      willArrive: `Your ${getConfigByKey(
        'name',
      )} TV box(s) will arrive within 7 days of signing up`,
    },
    forgotPasswordScreen: {
      txtForgot: `Forgot your password?`,
      placeholderEmail: `Email`,
      placeholderCode: `Confirmation code`,
      placeholderNewPass: `New password`,
      buttonTextSignIn: `Confirm`,
      buttonTextReSendCode: `Resend confirmation code`,
      alertMessFailCode: `Invalid confirmation code`,
      alertMessFailEmail: `The email doesn\'t exists.`,
      alertMessIsvalidEmail: `The email is not valid.`,
      alertMessSendLink: `An email for changing password has been sent to your {{email}} mailbox. Please check the email to get the link for confirming changing password.`,
      alertNullEmail: `Please enter your email`,
      alertNullCodePass: `Please enter your new password and confirmation code`,
      alertSuccess: `New password has been changed successfully`,
      alertError: `The email doesn\'t exist, please check and try again`,
      confirmation: `Change Your Password`,
      messageChange: `Please change your password to continue using our service`,
      'sucsses confirmation': `You have successfully changed your password`,
      'message login': `Login to continue using our service`,
      're login': `Re-login`,
      'new password': `New password :`,
      're password': `Retyped new password :`,
      submit: `Submit`,
      alert: {
        wrong: `The new password is not same as the retyped new password, please check and try again.`,
        wrongs: `The password is not same as the retyped password, please check and try again.`,
        valid: `Password length must be at least 6 characters`,
        null: `Please enter your password`,
        exit: `Exit`,
      },
      forgotPassword: `Forgot password`,
      titleForgot: `Enter your email that you used to register. We’ll send you an email with verify code to reset your password.`,
      emailAddress: `Email`,
      buttonTextSent: `Send`,
      supportEmail: `For further assistance, please contact our customer service at `,
    },
    changePasswordScreen: {
      updatePass: `Change password`,
      placeholderOldPassword: `Old password`,
      placeholderPassword: `New password`,
      rePlaceholderPassword: `Retyped new password`,
      buttonTextSignIn: `Confirm`,
      alertSuccess: `Password changed successfully`,
      alertPassLength: `Password length must be greater than or equal to 6`,
      alertProvideInfomation: `Please fill in all the information`,
      alertErrorNewPass: `The "New password" is not same as "Retyped new password", please check and try again`,
      alertErrorOldPass: `The old password is incorrect, please check and try again`,
    },
    contactUs: {
      txtTitle: `Contact us`,
      txtSmallTitle: `Please send us your feedbacks`,
      labelForm: `Email inquiry`,
      txtHelp: `The daily work time is from <span>9AM</span> to <span>6PM</span> <span>PST (Pacific Standard Time)</span>
      from Monday to Friday. The contacts and feedbacks outside of work hours
      will be proceeded and replied on the next working day.
      Thank you.`,
      subjectLbl: `Subject`,
      commentLbl: `Content`,
      submitBtn: `Submit`,
      txtContentSubject: `Request to downgrade from {{current_package_name}} to {{new_package_name}} and return box. I want to downgrade the package of my account {{account_code}}/{{account_email}}`,
      txtContent: `I want to downgrade the package of my account {{account_code}}/{{account_email}} from {{current_package_name}} to {{new_package_name}}, and return the box that I'm renting`,
      placeholder: {
        email: `Email`,
        name: `Full name`,
        subject: `Subject`,
        comment: `Content`,
        txtContactName: `Contact Name`,
      },
      alert: {
        nullEmail: `Please enter your email`,
        validEmail: `The email format is not valid`,
        nullName: `Please enter your name`,
        nullSubject: `Please enter the subject`,
        nullComment: `Please enter the feedback content`,
        messageSucces: `Thank you for contacting us. We will reach back to you as soon as possible!`,
      },
      popup: {
        txtTitle: `Your service plan selected is lower than your current plan`,
        txtDes: `Please contact our customer service to return the <b> ${getConfigByKey(
          'short_name',
        )} TV Box </b>`,
        txtPhone: `${getConfigByKey('phone_number_1')}`,
        txtOr: `Or`,
        btnSubmit: `Send`,
      },
    },
    faq: {
      txtQuestion: `Frequently asked questions`,
      txtSub: `Can help you troubleshoot problems faster`,
    },
    landingPage: {
      watch: `Watch Now`,
      'continue watching': `Resume`,
      'episodes season': `Eps {{episodes}} - Season {{season}}`,
      episodes: `Eps {{episodes}}`,
      TxtTotalEps: `{{episodes}} Eps`,
      buttonUpgrade: `Upgrade to watch`,
      buttonLogin: `Login to watch`,
      btnLogin: `Login`,
      directors: `Directors: `,
      author: `Author: `,
      updating: `Updating`,
      actor: `Actors: `,
      day: ` Day `,
      month: ` Month `,
      movie: `Movie`,
      addList: `Add to My List`,
      removeList: `Remove from My List`,
      min: ` m | `,
      duration: `Duration: `,
      recommended: {
        recommended: ` Related contents`,
      },
      alert: {
        txtTitle: `This is VIP content. Please upgrade your account to watch.`,
        btnUpgrade: `Upgrade`,
        btnCancel: `Cancel`,
      },
      replayShow: `Rewatch from Eps 1`,
      replayMovie: `Restart `,
      season: {
        txtSort: `Sort`,
        txtNewEps: `Newest`,
        txtOldestEps: `Oldest`,
      },
    },
    box: {
      alertNullEmail: `Please enter your email`,
      alertWrongEmailFormat: `Email format is not valid. `,
      'unlimited movies': `Unlimited movies,`,
      ondeman: `${getConfigByKey('sologan')}`,
      tvShow: `TV shows, and more.`,
      watch: `Watch anywhere. Cancel anytime.`,
      'ready to watch': `Ready to watch? Enter your email to create or restart your membership.`,
      'join now': `Join now`,
      sorry: `Sorry, ${getConfigByKey('name')} is not available in your country yet.`,
      txtBlockContrySubscription: `Sorry, this service pack is not available in your country`,
      txtHome: `Home`,
      titlePlan: `Subscription plans`,
      btnContinue: `Continue with free subscription`,
      txtAds: `with ads and limited contents`,
      subscriptionPlanListShortDescription: {
        default: `Choose the plan that’s right for you. All plans include a 14 day free trial.`,
        sctv: `Choose the plan that’s right for you.`,
      },
    },
    device: {
      txtTitle: `Device Availability`,
      txtDes: `Please choose a subscription option `,
      txtNotBox: `I Need an ${getConfigByKey('name')} TV Box`,
      txtInfomation: `You need an ${getConfigByKey(
        'name',
      )} TV Box to enjoy our service. You can purchase or rent it if you do not have a device yet.`,
      txtHasBox: `Already Have Device`,
      txtHasBoxinfo: `You can subscribe ${getConfigByKey('name')} with any device below`,
      txtSelect1: `${getConfigByKey('name')} TV Box `,
      txtSelect2: `Chromecast with Google TV `,
      txtSelect3: `Android TV`,
      btnContinue: `Continue`,
    },
    boxDetail: {
      title: `BUY ${getConfigByKey('short_name')} TV BOX`,
      'buy now': ` Buy now`,
      'free 30 day': `for 30 days Free Trial`,
      'auto 30 day': `Autopay & Auto-renewal`,
      'confirm & payment': `Confirm & payment`,
      total: `Total`,
      box: {
        tv: `1 x ${getConfigByKey('name')} TV Box`,
        remote: `1 x IR Remote Control `,
        power: `1 x Power Adaptor `,
        hdmi: `1 x HDMI cable`,
        pin: `2 x AAA batteries`,
        user: `1 x User Manual`,
      },
      'TV Box': `${getConfigByKey('name')} Set-top TV Box`,
      referralPrice: `Intro Price`,
      return30day: `30-days return/exchange`,
      onlyOne: `One-time payment`,
      quantity: `Quantity`,
      requireDescription: `* You can keep the TV Box if you continue to use this subscription after trial period.`,
      requireDescription2: `If you cancel the subscription after the trial period, you will need to return the TV Box to us so that you don\'t have to pay $50 for the TV Box.`,
    },
    boxLogin: {
      'login/register': `Login/ Register`,
      email: `Email`,
      password: `Password`,
      repassword: `Confirm password`,
      forgotpass: `Forgot password ?`,
      'login with facebook': `Sign in with Facebook`,
      alertNullEmail: `Please enter your email`,
      alertNullPass: `Please enter your password`,
      alertValidEmail: `The email format is not valid, please check and try again`,
      login: `Sign In`,
      register: `Register`,
      txtCheckbox: `By creating the account, I hereby agree to`,
      txtAnd: `and`,
    },
    boxConfirm: {
      txtTitleBox: {
        default: `Your transaction is completed`,
        sctv: 'Your transaction is complete',
      },
      txtTitleFree: {
        default: `Registration Completed`,
        sctv: 'Registration Completed',
      },
      description1: `An email has been sent to your mailbox to verify your email.`,
      description2: ` Please confirm your email to start using ${getConfigByKey('name')}`,
      actived: `Please continue if your email is verified`,
      txtGoHome: `Continue to return to the home page`,
      getCodeOtp: `Get PIN Code`,
      sentEmail: `Still can’t find the email?`,
      reSentEmail: `Resend Verification Email`,
      continue: `Start watching`,
      txtNeedHelp: `Need help?`,
      txtContactUs: `Contact Us`,
    },
    subscriptions: {
      paypal: {
        txtTitlePaypal: `The payment will be handled by PayPal. <br /> You\'ll be redirected back to this page once the PayPal payment is completed.`,
        btnComplete: 'Complete Purchase with PayPal',
        btnBackHomePage: 'Back to home page',
        btnTryAgain: 'Do it again',
        txtSuccess: 'Your transaction is completed',
        txtSuccess2: 'Return to the homepage to continue watching interesting programs',
        txtFail: 'Payment failed',
        txtFail2: 'Could there have been errors in the checkout, redo?',
      },
      bootpay: {
        txtTitleBootpay: `The payment will be handled by Bootpay. <br /> You\'ll be redirected back to this page once the Bootpay payment is completed.`,
        btnComplete: 'Complete Purchase with Bootpay',
      },
      footer: {
        'privacy policy': `Privacy policy`,
        'box streaming': `Terms of use`,
        termsOfUse: `terms of use`,
        ContactUs: `Contact us`,
        doNotSell: `Do Not Sell My Information`,
        phone: `${getConfigByKey('phone_number_1')}`,
        email: `{{email}}`,
        copyright: `Copyright 2020 ODK Media, Inc.`,
        txtFaq: `FAQs`,
      },
      'for web': `for web`,
      'comming soon': ` Coming soon`,
      'subscribe here': `Subscribe here`,
      'sign in': `Sign in`,
      profile: `Go to Profile`,
      month: `m`,
      tháng: `m`,
      year: `year`,
      năm: `year`,
      'privacy policy': `Privacy policy`,
      'box streaming': `Terms of use`,
      'finish and pay': `Finish and pay`,
      dayTrial: `Free for {{day}} days`,
      'Debit/ credit card': `Debit/ credit card`,
      'Card number': `Card number`,
      Expory: `Expiry`,
      titleUser: `Please fill in the information below so that we can send the ${getConfigByKey(
        'short_name',
      )} TV Box to you`,
      name: `Full name `,
      address: `Steet Address `,
      apt: `Apt, Ste, Unit (optional) `,
      city: `City ( San Jose , Westminster, Los Angeles, ... )`,
      zip: `Zip code `,
      state: `State `,
      btnSave: `Save & Continue`,
      btnEdit: `Edit`,
      alertnullName: `Please enter your full name at least 2 characters`,
      alertnullAddress: `Please enter your address`,
      alertnullCity: `Please enter your City`,
      alertnullApt: `Please enter your Atp, Ste, Unit`,
      alertnullZipCode: `Please enter your zip code`,
      alertnullstate: `Please enter your state`,
      alertSucessPay: `Payment succeeded, your subscription has been upgraded`,
      alertValidTitle: `Please fill in all information of your debit/credit card`,
      alertmostName: `Please enter your full name at most 47 characters.`,
      alertmostAddress: `Please enter your address at most 64 characters.`,
      alertmostApt: `Please enter your Apt, Ste, Unit at most 6 characters.`,
      alertmostZipCode: `Please enter your zipcode at most 5 characters.`,
      alertmostState: `Please enter your state at most 50 characters.`,
      alertmostCity: `Please enter your City at most 50 characters.`,
      alertErrorNet: `There is some information while connecting to the internet, please check and try again`,
      percentageOff: `{{percentage}}% off`,
      viewDetail: {
        odv: `Start Free Trial`,
        sctv: `Sign Up for Packages`,
      },
      txtChangePlans: `Change to {{name}}`,
      txtplanActived: `Your current subscription`,
      txtService: `Service `,
      titleCheckout: `Checkout`,
      txtShippingAddress: `Shipping Address`,
      txtOrderSummary: `Order Summary`,
      txtTotal: `Total`,
      placeholderCouponCode: `Coupon Code`,
      btnApplyCoupon: `Apply`,
      txtPayment: `Payment Details`,
      lblCardHolderName: `Cardholder Name`,
      txtWhatsCVC: `What\'s CVC?`,
      txtCVCDescription: `The CVC refers to the 3-4 digit code on the front or back of the card.`,
      txtTermsOfUse: `Terms of Use`,
      txtPrivacyPolicy: `Privacy Policy`,
      txtAnd: `and`,
      txtCompletePurchase: `Complete Purchase`,
      txtDiscount: `Discount`,
      txtSubtotal: `Subtotal`,
      txtPlanTrial: `{{plans}} trial`,
      txtOverAge: `I\'m over 18 and I hereby agree to the`,
    },
    video: {
      subtitle: `No subtitle`,
      vietnam: `Vietnamese`,
      english: `English`,
      eps: `Episode`,
      messagePopup: {
        txtWarning: `Warning`,
        txtPolicy: `{{tenantSlug}} do not take liability or responsibility for the broadcasted content of this live channel. We\'re committed to doing our best to filter out sensitive contents of the channel`,
        txtTitle: `The content is limited`,
        txtDes: `This program requires a login to continue watching`,
        txtDesHaveSupcription: `Please subscribe to the service pack to continue watching`,
        btnUpgrade: `Service pack`,
      },
    },
    notification: {
      confirmSuccessEmail: `Email confirmation succeeded`,
      selectDoneToContinueWithTheServiceExperience: `Please click on "Finish" to continue`,
      done: `Finish`,
      emailConfirmationFailed: `Email confirmation failed`,
      anErrorOccurredWhileAuthenticatingTheEmail: `There are some information while confirming the email, please try again.`,
    },
    myList: {
      txtTitle: `My list`,
      txtNullData: `You have not added any content to your list`,
    },
    report: {
      txtReport: `Report`,
      txtTitle: `Error Report`,
      txtDescription1: `We apologize for the inconvenience caused to you.`,
      txtDescription2: `To improve product quality better please help us to choose the error report suggested below to help us fix it faster.`,
      btnSend: `Send Error Report`,
      txtDesComplete: `Thank you for reporting errors!`,
      txtTitleComplete: `Complete Error Reporting`,
      btnComplete: `Back to the movie`,
      btnInput: `Upload image`,
      txtOrther: `Others/details`,
      txtErrorFile: `The file you have selected is too large. The maximum size is 10MB`,
      txtErrorSpace: `The white space does not allow`,
      txtErrorAcc: `Please login!`,
      placeholder: {
        txtComment: `Write a description`,
        txtFile: `Input file`,
      },
    },
    update: `Update`,
    cancel: `Cancel`,
    delete: `OK`,
    creditDebitCard: `Credit / debit card`,
    txtPalpalPortal: `Online trading portal`,
    expire: `Expire`,
    updateCard: `Update card`,
    removeCard: `Remove card`,
    warningRemoveCard: `Your card will be removed completely from the account. Are you sure to remove the card?`,
    changePaymentInformation: `change card information`,
    youAreNotConnectedToTheInternet: `youAreNotConnectedToTheInternet`,
    selectBoxPage: {
      convenientWayToStream: `Convenient way to stream`,
      purchaseOnDemandVietTvBoxToday: `Purchase ${getConfigByKey('name')} TV box today!`,
      onDemandVietTvBox: `${getConfigByKey('name')} TV Box`,
      unit: `{{count}} Box`,
      unit_plural: `{{count}} Boxes`,
      buy: `Buy`,
      purchaseOptions: `Purchase Options`,
      continue: `Continue`,
      iAlreadyHaveAnOnDemandVietTvBoxToday: `I already have an ${getConfigByKey('name')} TV Box`,
      onDemandVietTvBoxIncludedARemoveControl: `${getConfigByKey(
        'name',
      )} TV box included a remote control, a <br />HDMI cable, 2 AAA batteries, and a changer.`,
      rent: `Rent`,
      lblBoxQuantity: `TV Box Quantity`,
      txtOneTimePayment: `One-time payment`,
      txtAutoRenewal: `Auto-renewal`,
      txtShortPerMonth: `/mo`,
    },
    announcement: {
      title: `Announcement`,
      txt1: `The current broadcasting content is being block due to sensitive or political contents.`,
      txt2: `${getConfigByKey(
        'name',
      )} is fully our audiences while trying to balance between blocking or cutting off partial of the Drama or show due to the nature of live channel broadcast time vs the scheduled broadcast contents.`,
      txt3: `Please be aware that some of these sensitive contents might be leaked as it not our intention to display offensive contents due to live broadcast from Vietnam broadcast center`,
      txt4: `${getConfigByKey(
        'name',
      )} team sincerely thank you for your understanding and being our value customers.`,
    },
    noBroadcastSchedule: `No broadcast schedule`,
    broadcastSchedule: `Broadcast schedule`,
    rentBox: {
      questionMark: {
        txt1: `- Free Replacement for Malfunction Hardware during rental period. Please contact Customer Services for free shipping label.`,
        txt2: `- {{trialDays}} days return without any fee during the trial period.`,
        txt3: `- Free shipping.`,
      },
      refund: `Full refund within {{trialDays}} days trial period.`,
    },
    filter: {
      filterFilm: `Filter film`,
      removeAll: `Remove all`,
      removeFilter: `Remove filter`,
      regions: `Regions`,
      genres: `Genres`,
      languages: `Localization`,
      provider: `Content provider`,
      people: `Actor/Director/Producer`,
      all: `All`,
      txtFitlerNondata: `Sorry, we couldn't find any results.`,
      txtFilterHistory: `Filter history: `,
    },
    notDataToShow: `No data to show`,
    cancelSubscription: {
      survey: {
        sendSurvey: `Send survey`,
        back: `Back`,
        title: `Service survey`,
        description1: `We are sorry about your decision. To help the customer experience better, please provide reasons to stop using the service.`,
        description2: `Thank you!`,
        reason: `Reason for service cancellation: `,
        txtError1000: `Please enter no more than 1000 characters.`,
        txtSelect: `Multiple questions can be selected`,
        txtDes: `Contents`,
        txt1k: `1000 characters`,
      },
      confirm: {
        title: `Cancel service`,
        description1: `Our company is working on improving product quality every day, and within a few months you will see improvements in both the content and the new features that are right for you. Please accompany the progress of our company!`,
        description2: `The special offer is for you only if you continue to use the service`,
        discount: `Get 20% off for  next 6 months`,
        anotherTry: `I\'ll give it another try`,
        no: `No..., Sorry can\'t help you`,
      },
      agree: {
        title: `Continue to use the service`,
        description: `Thank you for your trust and decision to continue using ${getConfigByKey(
          'short_name',
        )}\'s services. Your comments will be reviewed and corrected as soon as possible to ensure your experience will be improved in the best way.`,
        continue: `Continue to watch the movie`,
      },
      completed: {
        title: `Complete service cancellation`,
        description1: `Click "Complete Cancellation" below to complete your cancellation.`,
        description2: `- The cancellation will be effective at the end of your current billing period at: {{restTime}}`,
        description3: `- You can re-activate your subscription at any time. Meanwhile you can still use ${getConfigByKey(
          'name',
        )} with limited content ... `,
        thanks: `Thank you sincerely!`,
        finish: `Complete Cancellation`,
        back: `Back`,
      },
    },
    cooldown: {
      program: `The next program`,
      nameEpg: `{{name}}`,
      timeStart: `Will start in: `,
      hours: `h`,
      minute: `m`,
      second: `s`,
    },
    recommedation: {
      txtComing: `Because you watched {{name}}`,
      txtStart: `The next program will start after: `,
    },
    localizedMessages: {
      invalid_number: `The card number is not a valid credit card number.`,
      invalid_expiry_month: `The card\'s expiration month is invalid.`,
      invalid_expiry_year: `The card\'s expiration year is invalid.`,
      invalid_cvc: `The card\'s security code is invalid.`,
      incorrect_number: `The card number is incorrect.`,
      incomplete_number: `The card number is incomplete.`,
      incomplete_cvc: `The card\'s security code is incomplete.`,
      incomplete_expiry: `The card\'s expiration date is incomplete.`,
      expired_card: `The card has expired.`,
      incorrect_cvc: `The card\'s security code is incorrect.`,
      incorrect_zip: `The card\'s zip code failed validation.`,
      invalid_expiry_year_past: `The card\'s expiration year is in the past`,
      card_declined: `The card was declined.`,
      missing: `There is no card on a customer that is being charged.`,
      processing_error: `An error occurred while processing the card.`,
      invalid_request_error: `Unable to process this payment, please try again or use alternative method.`,
      invalid_sofort_country: `The billing country is not accepted by SOFORT. Please try another country.`,
      email_invalid: `Invalid email address, please correct and try again.`,
    },
    player: {
      txtMind: `Disclaimer: `,
      txtContent: `The videos in this category are directly from YouTube. <b>{{tenantSlug}}</b> is only simply acting as a bridge to connect our users to the original content source and it is the rightful owner. You will be seeing Ads as they are directly from YouTube.`,
      blockAds: {
        txtTitle: `Please turn off the ad-blocker`,
        txtDes: `You can only watch the free content with ads. Please turn off your browser’s ad-blocker plugin to be able to continue watching.`,
        txtButton: `Guide on how to turn-off ad-blocker`,
      },
      warning: {
        txtHeader: 'WARNING',
        txtTitle: 'Adults only',
        txtDescription:
          'Please be advised that this is sexually explicit and violent material and cannot be viewed by minors under are of 18. If you are not of the legal age, please leave this page immediately.',
        txtContentUnConfirm: 'Leave this Page',
        txtContentConfirm: `I'm over 18`,
      },
    },
    youtube: {
      txtRemoveTitle: `Remove this content from the watching list?`,
      txtRemoveDescription: `You will not be able to continue watching where you left off.`,
      btnCancel: `Cancel`,
      btnRemove: `Ok`,
    },
    share: {
      txtCopy: `Copy`,
      txtCopied: `Copied`,
      txtShare: `Share`,
    },
    continue: 'Continue',
    payperview: {
      day: 'days',
      hour: 'hour',
      hours: 'hours',
      movie: 'movie',
      show: 'show',
      plan: {
        month: {
          txtPlan: 'Buy Monthly Subscription',
          txtDes: 'View all contents without limitations',
          txtOption1: 'FREE trial for 14 days',
          txtOption2: 'Over 17,000 hours of PREMIUM contents',
          btn: 'View Details',
        },
        views: {
          txtBuyDrama: 'Buy Show',
          txtBuyMovie: 'Buy Movie',
          timePaid: `Watch in {{timePaid}} {{unit}}`,
          btn: 'Continue',
        },
      },
      success: {
        txtTitle: 'The transaction is completed',
        txtDesDrama: `You've bought the show successfully,<br /> you have {{timePaid}} {{unit}} to watch this show.`,
        txtDesMovie: `You've bought the movie successfully,<br /> you have {{timePaid}} {{unit}} to watch this movie.`,
        btn: 'Continue',
      },
    },
    footer: {
      home: 'Home',
      products: 'Products',
      company: 'Company',
      blog: 'Blog',
    },
    Remove: 'Remove',
    ppvDescription:
      'VOD contents can be viewed immediately after the payment, and refunds are not supported after payment is complete on exception of Live contents.',
    ppvRefundCondition:
      'Content can be watched immediately after purchase, and if you wish to cancel your purchase, we will refund you only if have no viewing history of the purchased content. Please refer to the Terms of Use for details regarding refunds.',

    page404: {
      title: 'Lost your way?',
      description: "Sorry, we can't find that page. You'll find lots to explore on the home page.",
      btnToHomePage: 'Back to home page',
    },
  },
};

export default en;
