import React, { forwardRef, useState } from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';

import { BaseInput, BaseInputProps } from './BaseInput';
import Icon from '@material-ui/core/Icon';

export const PasswordInput = forwardRef((props: BaseInputProps, ref) => {
  const [visible, setVisible] = useState(false);
  const handleClick = () => {
    setVisible(!visible);
  };

  const classIcon = !visible ? 'fa fa-eye-slash' : 'fa fa-eye';
  return (
    <BaseInput
      {...props}
      ref={ref}
      type={visible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="small" onClick={handleClick} style={{ color: '#94a3ad' }}>
              <Icon className={classIcon} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
});
