import React from 'react';
import {
  TVerifyPhoneNumberFormProps,
  VerifyPhoneNumberForm,
} from '@/shared/components/form/VerifyPhoneNumberForm/VerifyPhoneNumberForm';
import { BaseDialog, TBaseDialogProps } from '../BaseDialog';

type Props = TVerifyPhoneNumberFormProps & Omit<TBaseDialogProps, 'children'>;

export const VerifyOTPDialog = ({ open, onClose, ...props }: Props) => {
  return (
    <BaseDialog open={open} onClose={onClose} size="small" clickOutsize={false}>
      <VerifyPhoneNumberForm {...props} />
    </BaseDialog>
  );
};
