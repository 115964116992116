import FETCH from '../utils/security/fetch';
import * as APIString from '../constants/apiString';
import { store } from '@/store/store';

const postCouponCancel = (params = {}) => {
  const { account } = store.getState().auth;
  if (!account) {
    return Promise.resolve(true);
  }

  let Authorization = '';
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  const accountId = (account && account.profile && account.profile.id) || '';
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/coupon/`;
  return FETCH(
    path,
    'POST',
    {
      Authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify(params),
  );
};

const getPaypalMethod = () => {
  const { account } = store.getState().auth;
  if (!account) {
    return Promise.resolve(true);
  }

  let Authorization = '';
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  const accountId = (account && account.profile && account.profile.id) || '';
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/paypal_payers
`;
  return FETCH(path, 'GET', {
    Authorization,
    'Content-Type': 'application/json',
  });
};

export const getDevices = () => {
  const { account } = store.getState().auth;
  if (!account) {
    return Promise.resolve(true);
  }

  let Authorization = '';
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  const accountId = (account && account.profile && account.profile.id) || '';
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/sessions
`;
  return FETCH(path, 'GET', {
    Authorization,
    'Content-Type': 'application/json',
  });
};

export const forceLogout = (sessionId = '') => {
  const { account } = store.getState().auth;
  if (!account) {
    return Promise.resolve(true);
  }

  let Authorization = '';
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  const accountId = (account && account.profile && account.profile.id) || '';
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/force_logout/`;
  return FETCH(
    path,
    'POST',
    {
      Authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify({ session_id: sessionId }),
  );
};

export { postCouponCancel, getPaypalMethod };
