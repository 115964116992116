import React, { Component } from 'react';
import { styled } from 'twin.macro';
import Spinner from './Spinner';

const DivStyled = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export default class PageLoading extends Component {
  render() {
    return (
      <DivStyled>
        <Spinner color="rgb(37, 116, 212)" />
      </DivStyled>
    );
  }
}
