import { tenantEnvConfig } from '../../constants/tenantConfig/tenantEnvConfig';
import { tenantStrings } from '../../constants/tenantConfig/tenantStrings';
import { tenantFeatureFlags } from '../../constants/tenantConfig/tenantFeatureFlags';
import { integrationConfigs } from '../../constants/tenantConfig/integrationConfigs';
import { getDeepProp } from '../../utils/utils';

let currentLanguage = 'vi';

export let tenantConfig = {
  _ssr: false,
  ...tenantEnvConfig,
  ...tenantStrings,
  features: {
    ...tenantFeatureFlags,
  },
  integrations: {
    ...integrationConfigs,
  },
};

const getConfigByKey = (name = '') => {
  if (tenantEnvConfig?.tenant_slug && !tenantConfig?.tenant_slug) {
    tenantConfig = { ...tenantConfig, ...tenantEnvConfig };
  }
  const configs = tenantConfig;
  const value = getDeepProp(configs, name, currentLanguage) || '';
  return value;
};

export { getConfigByKey };
