import { combineReducers } from '@reduxjs/toolkit';
import { createMigrate, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { DEBUG, VERSION } from '@/constants/envConfig';

import { root } from '@/shared/app/reducers';
import { auth } from '@/shared/features/auth/reducers';
import home from '@/shared/features/homepage/reducers';
import alertModal from '@/shared/components/alertModal/reducers';
import toast from '@/shared/components/toast/reducers';
import landingPage from '@/shared/features/landingpage/reducers';
import detailPage from '@/shared/features/detailpage/reducers';
import box from '@/shared/features/box/reducers';
import loading from '@/shared/features/loadingPage/reducers';
import filter from '@/shared/components/filter/filterSlice';
import backdrop from '@/shared/components/backdrop/backdropSlice';
import faq from '@/shared/features/Faq/reducers';
import tenantConfig from '@/shared/features/tenantConfig/reducers';
import youtubePage from '@/shared/features/youtubePage/reducers';
import blockCountry from '@/shared/features/blockCountry/blockingSlice';
import historyReducer from '@/shared/features/history/historySlice';
import svodAppSlice from '@/shared/components/svodApp/svodAppSlice';
import globalReducer from '@/shared/components/global/globalSlice';
import alertPopup from '@/shared/components/alertPopup/alertPopupSlice';
import { authenticationReducer } from '@/shared/features/authentication/authenticationSlice';
import { withReduxStateSync } from 'redux-state-sync';
import { contentSlice } from '@/shared/slices/content/contentSlice';
import { tvodReducer, tvodSlice } from '@/shared/features/tvod/tvodSlice';
import { apiCacheSSRReducer, apiCacheSSRSlice } from '@/slices/api/apiCacheSSRSlice';

const version = parseInt(VERSION.split('.').pop() || '1');

const migrations = {
  [version]: (state: any) => ({
    auth: state.auth,
  }),
};
const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    'backdrop',
    // 'tenantConfig',
    'blockCountry',
    'global',
    'alertPopup',
    'loading',
    'authentication',
    'contents',
    apiCacheSSRSlice.name,
    // 'tvod',
  ],
  version: version,
  migrate: createMigrate(migrations as any, { debug: __SERVER__ ? false : DEBUG }),
};

const rootReducers = {
  root,
  auth,
  home,
  alertModal,
  toast,
  landingPage,
  detailPage,
  box,
  loading,
  filter,
  backdrop,
  faq,
  tenantConfig,
  youtubePage,
  blockCountry,
  svodApp: svodAppSlice,
  history: historyReducer,
  global: globalReducer,
  alertPopup,
  authentication: authenticationReducer,
  [contentSlice.name]: contentSlice.reducer,
  [tvodSlice.name]: tvodReducer,
  [apiCacheSSRSlice.name]: apiCacheSSRReducer,
};

const reducers = combineReducers(rootReducers);

export type TRootReducer = ReturnType<typeof reducers>;
const createRootReducer: () => TRootReducer = () => {
  if (!__BROWSER__) {
    return reducers;
  }

  return persistReducer(persistConfig, withReduxStateSync(reducers));
  // return reducers;
};

export default createRootReducer;
