import { createContext, FC, PropsWithChildren } from 'react';

export type ThemeFormContextProps = {
  themeMode?: 'dark' | 'light';
};
const initialThemeFormContext: ThemeFormContextProps = {
  themeMode: 'light',
};

export const ThemeFormContext = createContext(initialThemeFormContext);

export type TThemeFormProviderProps = PropsWithChildren<ThemeFormContextProps>;

export const ThemeFormProvider: FC<ThemeFormContextProps> = ({ children, themeMode = 'light' }) => {
  const valueContext = {
    themeMode,
  };
  return <ThemeFormContext.Provider value={valueContext}>{children}</ThemeFormContext.Provider>;
};
