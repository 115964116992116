import { Box } from '@material-ui/core';
import { styled } from 'twin.macro';
import clsx from 'clsx';
import { Button } from '@/shared/components/button/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { BaseDialog } from '@/shared/components/diglog/BaseDialog';

const AlertModalStyled = styled.div`
  font-family: Open Sans;
  .v-alert {
    &--title {
      font-family: Open Sans;
      font-size: 24px;
      color: #283237;
      text-decoration: none solid rgb(40, 50, 55);
      text-align: center;
      margin-top: 10px;
      margin-bottom: 5%;
      font-weight: bold;
      &.error {
        color: #e30000;
      }
    }
    &--description {
      font-family: Open Sans;
      font-size: 16px;
      color: #495963;
      text-decoration: none solid rgb(73, 89, 99);
      text-align: center;
      margin-bottom: 35px;
      /* padding: 0 10%; */
    }
    &--red {
      color: #e72d30;
      font-weight: bold;
    }

    &--btn {
      background-color: #e72d30;
      width: 100%;
      border-radius: 10px;
      /* font-family: Noto Sans; */
      font-size: 16px;

      color: #ffffff;
      text-decoration: none solid rgb(255, 255, 255);
      text-align: center;
      /* font-weight: bold; */
      /* padding: 15px; */
      font-weight: normal;
      margin-right: 7px;
    }
    &--cancel {
      background-color: #555;
    }
  }
  .actions {
    /* padding-top: 12px; */
  }
  @media (min-width: 768px) {
    .v-alert {
      &--title {
        font-size: 30px;
      }
      &--description {
        font-size: 16px;
        padding: 0;
      }
      &--btn {
        padding: 10px;
      }
    }
  }
`;

export const BaseAlertPopup = props => {
  const {
    handleClose,
    isDisplayCancelButton = false,
    isDisplayOklButton = true,
    open,
    hasIcon,
    isError,
    title,
    descriptions,
    txtConfirmButton = 'OK',
    txtCancelButton = 'Hủy',
    handleConfirm = () => {},
    actionClasses = '',
    handleCancel = () => {},
  } = props;

  return (
    <BaseDialog open={open} onClose={handleClose} size="small" contentClassName="pb-[55px]">
      <AlertModalStyled className="v-alert">
        {hasIcon && (
          <div className="mb-[10px] mt-[-6px] text-center lg:mb-[24px]">
            {isError ? (
              <ErrorIcon className="text-[60px] text-[#cc0404] lg:text-[76px]" />
            ) : (
              <CheckCircleIcon className="text-[60px] text-[#04cc2f] lg:text-[76px]" />
            )}
          </div>
        )}
        <h2 className={clsx('v-alert--title', { error: isError })}>{title}</h2>
        {descriptions.map(description => (
          <Box className="v-alert--description">{description}</Box>
        ))}

        <Box
          className={clsx('actions', actionClasses)}
          display={'flex'}
          justifyContent={'space-between'}
        >
          {isDisplayOklButton && (
            <Button className="v-alert--btn w-full" onClick={handleConfirm}>
              {txtConfirmButton}
            </Button>
          )}
          {isDisplayCancelButton && (
            <Button className="v-alert--btn v-alert--cancel cancel" onClick={handleCancel}>
              {txtCancelButton}
            </Button>
          )}
        </Box>
      </AlertModalStyled>
    </BaseDialog>
  );
};
