const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  open: false,
  title: 'Thông báo',
  descriptions: [],
  txtConfirmButton: 'Đồng ý',
  isError: false,
  requireConfirm: false,
};
const alertPopupSlice = createSlice({
  name: 'alertPopup',
  initialState,
  reducers: {
    close: state => {
      state.open = false;
    },
    setData: (state, action) => {
      const {
        title,
        descriptions,
        txtConfirmButton = initialState?.txtConfirmButton,
        isError = false,
        requireConfirm = initialState.requireConfirm,
        hasIcon = false,
        nextPath = null,
      } = action.payload;
      state.title = title;
      state.descriptions = Array.isArray(descriptions) ? descriptions : [descriptions];
      state.open = true;
      state.isError = isError;
      state.requireConfirm = requireConfirm;
      state.hasIcon = hasIcon;
      state.nextPath = nextPath;
      state.txtConfirmButton = txtConfirmButton;
    },
  },
});

export const { actions } = alertPopupSlice;

export const FORCE_LOGOUT_MESSAGE = {
  title: 'Thiết bị của bạn đã bị đăng xuất',
  descriptions: [
    'Tài khoản của bạn trên thiết bị này đã bị đăng xuất bởi một thiết bị khác. Vui lòng đăng nhập để tiếp tục trải nghiệm dịch vụ.',
  ],
  txtConfirmButton: 'Đồng ý',
  requireConfirm: true,
};

export const normalAlertMessageCreator = ({
  title,
  message,
  isError,
  requireConfirm,
  txtConfirmButton,
  hasIcon = false,
  nextPath = '',
}) => ({
  title: title ?? null,
  descriptions: message,
  txtConfirmButton: txtConfirmButton ?? 'Đồng ý',
  requireConfirm: requireConfirm ?? false,
  isError: isError ?? false,
  hasIcon,
  nextPath,
});

export default alertPopupSlice.reducer;
