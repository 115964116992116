import React, { Dispatch, useState } from 'react';
import { Controller, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Checkbox from '@/shared/components/Input/CheckBox';
import { Box, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';

import { getConfigByKey } from '@/shared/features/tenantConfig/services';
import { styled } from 'twin.macro';
import { registerEmail_SCTV } from '@/shared/features/auth/services';
import { useDispatch } from 'react-redux';
import { splitFullNameVietnamese } from '@/shared/utils/human';
import { Form } from '@/shared/components/form/Form';
import { BaseInput } from '../BaseInput';
import { PasswordInput } from '../PasswordInput';
import { verifyPhoneNumber } from '@/shared/utils/utils';
import i18n from 'i18next';

const LinkStyled = styled.span`
  font-family: 'Open Sans';
  font-size: 12px;
  color: #1e232a;
  text-align: left;
  margin-top: 9px;
  display: inline-block;
  a {
    text-decoration: underline;
    color: #1e232a;
  }
  .MuiCheckbox-root {
    padding-block: 0;
    padding-left: 0;
  }
`;

const schema = yup.object({
  fullname: yup.string().trim().required('Vui lòng nhập họ tên'),
  phone: yup
    .string()
    .trim()
    .required('Vui lòng nhập số điện thoại')
    .test({
      name: 'phone',
      test: (phoneNumber: string = '', ctx) => verifyPhoneNumber(phoneNumber),
      message: i18n.t('invalid_phone_number'),
    }),
  password: yup.string().trim().required('Vui lòng nhập mật khẩu'),
  re_password: yup
    .string()
    .trim()
    .when('password', ([password]: any, schema) => {
      return password
        ? schema.test('compare', 'Mật khẩu không trùng khớp', (value: any) => value === password)
        : schema;
      // return schema;
    }),
  agree: yup
    .boolean()
    .required()
    .test({
      name: 'email',
      test: (agreeValue: boolean) => agreeValue === true,
      message: 'Vui lòng chọn đồng ý',
    }),
});

type FormFields = yup.InferType<typeof schema>;

const initValues: FormFields = {
  fullname: '',
  re_password: '',
  agree: false,
  phone: '',
  password: '',
};

type TSignUpFormProps = {
  accountRegister?: any;
  setAccountRegister?: Dispatch<React.SetStateAction<any>>;
  onSubmitCallback?: () => void;
  openConfirmOTP?: boolean;
  setOpenConfirmOTP?: Dispatch<React.SetStateAction<boolean>>;
};

export const SignUpForm = ({
  onSubmitCallback = () => {},
  accountRegister,
  setAccountRegister,
  setOpenConfirmOTP,
}: TSignUpFormProps) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');

  const handleOnSubmit: SubmitHandler<FormFields> = async ({
    fullname,
    phone,
    password,
    agree,
    ...rest
  }) => {
    const [firstName, lastName] = splitFullNameVietnamese(fullname || '');
    setMessage('');
    try {
      const accountInfo = {
        firstName,
        lastName,
        password,
        phoneNumber: phone,
        accept: agree,
        ...rest,
      };
      await registerEmail_SCTV(accountInfo)(dispatch);
      setAccountRegister?.(accountInfo);
      setOpenConfirmOTP?.(true);
    } catch (error: any) {
      setMessage(error?.message);
    }
  };

  return (
    <Box>
      <Form<FormFields>
        onSubmit={handleOnSubmit}
        submitLabel="Đăng Ký"
        initialForm={{
          defaultValues: initValues,
          resolver: yupResolver(schema) as any,
        }}
        systemMessage={message}
        titleForm="Đăng Ký"
        titleFormProps={{ variant: 'short', className: 'mb-[43px] md:mb-[28px]' }}
      >
        {({ control, formState: { errors } }) => (
          <>
            <Controller
              control={control}
              name="fullname"
              render={({ field }) => (
                <BaseInput
                  {...field}
                  label="Tên"
                  fullWidth
                  error={errors?.fullname?.message}
                  required
                />
              )}
            />
            <Controller
              control={control}
              name="phone"
              render={({ field }) => (
                <BaseInput
                  {...field}
                  label="Số điện thoại"
                  fullWidth
                  error={errors?.phone?.message}
                  required
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <PasswordInput
                  {...field}
                  autoComplete="new-password"
                  label="Mật khẩu"
                  fullWidth
                  error={errors?.password?.message}
                  required
                />
              )}
            />
            <Controller
              control={control}
              name="re_password"
              render={({ field }) => (
                <PasswordInput
                  {...field}
                  autoComplete="new-password"
                  label="Nhập lại mật khẩu"
                  fullWidth
                  error={errors?.re_password?.message}
                  required
                />
              )}
            />
            <Controller
              control={control}
              name="agree"
              render={({ field }) => (
                <FormControl className="mt-[-10px] md:mt-[-8px]">
                  <FormControlLabel
                    className="agree-start ml-[-9px] items-start text-left"
                    label={
                      <LinkStyled className="link">
                        Tôi đã đọc và đồng ý với{' '}
                        <a
                          target="_blank"
                          href={getConfigByKey('privacyPolicyLink')}
                          rel="noreferrer"
                        >
                          Chính sách bảo mật
                        </a>{' '}
                        và{' '}
                        <a target="_blank" href={getConfigByKey('termsOfUseLink')} rel="noreferrer">
                          Điều khoản sử dụng dịch vụ
                        </a>
                      </LinkStyled>
                    }
                    control={<Checkbox {...field}></Checkbox>}
                  />
                  {/* {errors.agree?.message && (
                    <FormHelperText error variant="outlined">
                      {errors.agree?.message}
                    </FormHelperText>
                  )} */}
                </FormControl>
              )}
            />
          </>
        )}
      </Form>
    </Box>
  );
};
