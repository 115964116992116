import * as types from './actionTypes';
import { getConfigByKey } from '@/shared/features/tenantConfig/services';

const initialState = {
  menu: [],
  isHomePage: false,
  page: null,
  pageSlug: null,
  isLoadingPage: true,
  isRefreshPage: false,
  ribbonTarget: '',
  removeProcess: {
    isOpen: false,
    items: {},
    removeId: '',
  },
  extraBanners: [],
  pageById: {},
};

export default function home(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_MENU:
      return {
        ...state,
        menu: Array.isArray(action.menu) ? action.menu : [],
      };
    case types.GET_PAGE:
      let isHomePage = false;
      let bannerExtra = [];
      const { requestParams } = action?.page ?? {};

      if (requestParams?.homePageSlug === action?.page?.slug) {
        isHomePage = true;
      }

      const tenantPageSlug = action?.page?.slug;
      const page = {
        ...action.page,
        isHomePage,
      };

      return {
        ...state,
        page: {
          ...page,
          banners: [...page.banners, ...bannerExtra],
          isHomePage,
        },
        isLoadingPage: false,
        // isHomePage,
        pageById: {
          ...state.pageById,
          [tenantPageSlug]: page,
        },
        // pageSlug: action.page.pageSlug,
      };
    case types.GET_RECENT:
      return {
        ...state,
        page: {
          ...state.page,
          ribbons: [...action.payload, ...state.page.ribbons],
        },
        isLoadingPage: false,
      };
    case types.GET_PAGE_REQUEST:
      return {
        ...state,
        isLoadingPage: true,
      };
    case types.REFRESH_PAGE:
      return {
        ...state,
        isRefreshPage: action.isRefreshPage,
      };
    case types.GET_FINAL:
      return {
        ...state,
        isLoadingPage: false,
      };
    case types.GET_RIBBON_TARGET:
      return {
        ...state,
        ribbonTarget: action.idRibbonTarget,
      };
    case types.UPDATE_STATUS_REMOVE_PROCESS:
      return {
        ...state,
        removeProcess: action.payload,
      };
    case types.UPDATE_SEARCH:
      return {
        ...state,
        page: {
          ...state.page,
          search: action.queryParams,
        },
      };
    case types.UPDATE_PAGE_SLUG:
      return {
        ...state,
        pageSlug: action.payload,
      };
    case types.SET_EXTRA_BANNERS:
      return {
        ...state,
        extraBanners: action.payload,
      };
    default:
      return state;
  }
}
