import { getConfigByKey } from '@/features/tenantConfig/services';

const vi = {
  translations: {
    locale: {
      vi: 'Việt Nam',
      en: 'English',
      ko: '한국인',
    },
    vietnam: `Việt Nam`,
    english: `English`,
    formatDob: `dd/MM/yyyy`,
    premium: {
      on: `Tài Khoản VIP`,
      off: `Tắt Quảng Cáo`,
    },
    month: 'tháng',
    month_plural: 'tháng',
    invalid_phone_number: 'Số điện thoại không đúng',
    alertWrongEmailFormat: `Email không đúng định dạng`,
    alertNullEmailPass: `Bạn chưa nhập email hoặc mật khẩu`,
    ribbon: {
      view: `Lượt xem`,
      like: `Yêu thích`,
      all: `Tất cả`,
      txtViewMore: `Xem thêm`,
      slide: {
        txtWatchLive: 'Xem Live',
        txtMoreInfo: 'Thông tin',
        watch: 'Xem phim',
      },
      removeFavorite: {
        txtTitle: `Bạn có chắc?`,
        txtDes: `Xoá nội dung này khỏi danh sách của bạn.`,
      },
      txtSelectCategory: `Thể loại`,
    },
    navigation: {
      login: `Đăng nhập`,
      logout: `Đăng xuất`,
      profile: `Thông tin tài khoản`,
      search: `Nhập từ khóa`,
      account: `Tài khoản`,
    },
    search: {
      message: `Có 0 kết quả với từ khóa `,
      result: `Có {{number}} kết quả với từ khóa " {{name}} "`,
      tags: `Tags`,
      hint: `Gợi ý - Cùng thể loại`,
      movie: `Phim hot`,
    },
    popupMessage: {
      cancelPlan: {
        title: `Bạn có muốn huỷ gói dịch vụ hiện tại của bạn?`,
        description: `Gói dịch vụ của bạn còn thời hạn đến {{daytime}}`,
        btnNo: `Không. Tôi bấm nhầm.`,
        btnYes: `Có. Tôi muốn huỷ!`,
      },
      cancelPlanSuccess: {
        title: `Bạn đã huỷ thành công gói dịch vụ`,
        description: `Gói dịch vụ của bạn đã bị huỷ và sẽ hết hạn vào {{daytime}}. Cảm ơn bạn đã sử dụng dịch vụ của ${getConfigByKey(
          'name',
        )}!`,
      },
      payment: {
        title: `Chi tiết giao dịch`,
        service: `Dịch vụ`,
        CardInfo: `Thông tin thẻ tín dụng`,
        autoActive: `tự động kích hoạt hàng tháng`,
        buttonCheckOut: `Checkout \${{money}}`,
        txtTitlePaymentSuccess: `Giao dịch của bạn đã hoàn thành`,
        txtDesPaymentSuccess: `Gói đăng ký của bạn đã được thay đổi thành {{name}}.`,
        txtMoMoDesc: `*Sau khi bạn bấm hoàn tất mua hàng bằng Momo, hệ thống sẽ hiển thị mã QR kèm hướng dẫn. Bạn cần tải ứng dụng Momo trên điện thoại và sử dụng để quét mã QR trên để thanh toán. Gói dịch vụ sẽ được kích hoạt sau khi bạn thanh toán thành công.`,
        txtMoMoPayment: `Ví MoMo`,
        txtPaymentConfirm: `Hoàn Tất Thanh Toán`,
        txtPaymentConfirmMoMo: `Hoàn tất mua hàng bằng MoMo`,
        month: `tháng`,
        year: `năm`,
        day: `ngày`,
        hour: `giờ`,
        hours: `giờ`,
      },
      messageEmailSucces: {
        description1: `Một e-mail xác nhận sẽ được gửi về địa chỉ email của bạn.`,
        description2: `Vui lòng vào hộp thư để thực hiện việc xác nhận email.`,
        description3: `Thông tin đăng nhập của bạn sẽ chỉ thay đổi sau`,
        description4: `khi bạn thực hiện việc xác nhận email mới.`,
      },
      buttonYes: `Có`,
      buttonNo: `Không`,
      buttonExit: `Thoát`,
      buttonOkie: `Ok`,
      updateSubscriptionSuccess: `Cập nhật thành công!`,
      desscriptionUpdateSubscriptionSuccess: `Thanh toán hoàn tất, đăng ký của bạn đã được nâng cấp và có hiệu lực ngay lập tức`,
    },
    loginScreen: {
      title: `Để tiếp tục, hãy đăng nhập vào`,
      ondemandviet: `${getConfigByKey('name')}`,
      Or: `Hoặc`,
      placeholderEmail: {
        default: 'Địa chỉ email người dùng',
        odv: `Địa chỉ email người dùng`,
        sctv: 'Địa chỉ email hoặc số điện thoại người dùng',
        projectw: `Địa chỉ email người dùng`,
      },
      placeholderPassword: `Mật khẩu`,
      textNotAccount: `Bạn chưa có tài khoản?`,
      buttonTextRegis: ` Đăng ký tài khoản`,
      buttonTextForgotPass: `Quên mật khẩu?`,
      buttonTextSignIn: `Đăng nhập`,
      buttonContinueFb: `Đăng nhập bằng facebook`,
      buttonTextSignInPhone: `Đăng nhập bằng số điện thoại`,
      buttonTextSignUpFacebook: `Đăng ký bằng Facebook`,
      buttonTextSignInFacebook: `Đăng nhập bằng Facebook`,
      buttonTextSignInGoogle: `Đăng nhập bằng Google`,
      buttonTextForgotPassword: `Quên mật khẩu?`,
      buttonTextSignUp: `Đăng ký tài khoản`,
      loginFBErrorMessage: `Đăng nhập với Facebook đã xảy ra lỗi, vui lòng thử lại`,
      alertLoginFail: `Đăng nhập không thành công`,
      alertNetWorkError: `Có lỗi xảy ra khi kết nối đến hệ thống, vui lòng thử lại`,
      cbRememberMe: `Lưu đăng nhập`,
    },
    registerScreen: {
      gender: {
        male: `Nam`,
        female: `Nữ`,
        underfind: `Xin không trả lời`,
      },
      signIn: `Đăng nhập`,
      signUp: `Đăng ký`,
      txtName: `Họ tên`,
      txtRePassword: `Nhập lại mật khẩu`,
      titleRegis: `Đăng ký tài khoản với email của bạn`,
      titleBinding: `Các thông tin có dấu * là bắt buộc`,
      placeholderEmail: `Email `,
      placeholderFirstName: `Họ `,
      placeholderLastName: `Tên `,
      placeholderPassword: `Mật khẩu `,
      placeholderPhone: `Số điện thoại `,
      placeholderName: `Họ tên `,
      placeholderGender: `Giới tính `,
      placeholderBirthday: `Ngày sinh `,
      buttonTextSignUp: `Đăng ký tài khoản`,
      buttonTextSignIn: `Đăng Nhập tài khoản`,
      textHaveAccount: `Bạn đã có tài khoản? `,
      textSucces: `Đăng ký tài khoản mới thành công`,
      textLogin1: `Chúc mừng bạn, hãy `,
      login: `đăng nhập `,
      textLogin2: `để tiếp tục sử dụng các dịch vụ của chúng tôi.`,
      alertPassLength: `Mật khẩu phải lớn hơn 5 ký tự`,
      alertNull: `Thông tin đăng ký là bắt buộc, vui lòng cung cấp đầy đủ`,
      alertSuccess: `Đăng ký tài khoản mới thành công, vui lòng kiểm tra email của bạn để kích hoạt tài khoản`,
      alertError: `Đã có lỗi khi đăng ký tài khoản mới, vui lòng kiểm tra và thử lại`,
      lblBirthday: `Ngày sinh của bạn`,
      msgAgeWarning: `* Bạn chỉ có thể đăng ký khi đủ 13 tuổi trở lên`,
    },
    profile: {
      overview: `Tổng quan về tài khoản`,
      brief: `Hồ sơ`,
      authEmail: `Email của bạn chưa được kích hoạt, vui lòng kích hoạt để sử dụng dịch vụ`,
      change: `Thay đổi`,
      cancelPlan: `Hủy gói dịch vụ`,
      'number account': `Mã Tài Khoản`,
      name: `Họ Tên`,
      email: `Email`,
      're email': `Gửi lại email kích hoạt`,
      dob: `Ngày sinh`,
      phone: `Số điện thoại`,
      sex: `Giới tính`,
      'service pack': `Gói dịch vụ của bạn`,
      'privacy policy': `Chính sách bảo mật`,
      'box streaming': `Điều khoản sử dụng dịch vụ`,
      'otp odv': `Mã đăng nhập Hộp TV ${getConfigByKey('short_name')}`,
      'countdown time': `Tự động làm mới sau `,
      second: `giây`,
      'auth otp odv': `Mã đăng nhập dùng để đăng nhập nhanh vào thiết bị `,
      odv: `Hộp TV ${getConfigByKey('short_name')}`,
      'auto plan': `Gói dịch vụ sẽ tự động gia hạn vào ngày`,
      'cancel plan': `Gói dịch vụ đã bị huỷ và sẽ hết hạn vào ngày`,
      'not registration plan': `( Bạn chưa đăng ký gói dịch vụ )`,
      activated: `Đã kích hoạt`,
      notUpdate: `Chưa cập nhật`,
      accountNoStb: `Tài khoản {{name}}`,
      accountFree: `Tài khoản miễn phí`,
      detailAccFree: `Tài khoản miễn phí không hỗ trợ xem trên hộp TV của ${getConfigByKey(
        'short_name',
      )}. Hãy đăng ký gói để lấy mã kích hoạt cho hộp TV ${getConfigByKey('short_name')}.`,
      detailAccNoStb: `Tài khoản {{name}} không hỗ trợ xem trên hộp TV của ${getConfigByKey(
        'short_name',
      )}. Hãy đăng ký gói để lấy mã kích hoạt cho hộp TV ${getConfigByKey('short_name')}.`,
      buttonRegPlan: `Đăng ký gói`,
      navigation: {
        overView: `Tài khoản của bạn`,
        updateInfo: `Cập nhật thông tin`,
        upgratePlan: `Thay đổi dịch vụ`,
        transaction: `Hóa đơn`,
        changePass: `Đổi mật khẩu`,
        contactUs: `Liên lạc`,
        paymentInformation: `Thông tin thẻ tín dụng`,
      },
      expireTimePackage: 'Gói dịch vụ sẽ hết hạn vào ngày',
    },
    changeInformation: {
      updateAccount: `Cập nhật tài khoản`,
      email: `Email`,
      brief: `Hồ sơ`,
      password: `Mật khẩu`,
      phone: `Số điện thoại`,
      name: `Họ tên`,
      firstName: `Họ`,
      lastName: `Tên`,
      gender: `Giới tính`,
      changeEmail: `Thay đổi email`,
      dob: `Ngày sinh`,
      loginFb: `Bạn chưa đăng nhập bằng Facebook`,
      male: `Nam`,
      female: `Nữ`,
      another: `Xin không trả lời`,
      select: `Chọn giới tính ...`,
      buttonSubmit: `Xác nhận`,
      changeEmailScreen: {
        buttonChange: `Đồng ý thay đổi`,
        newEmail: `Email mới`,
        reNewEmail: `Nhập lại email mới`,
        warring: `* Email được dùng để đăng nhập vào tài khoản. Sau khi thay đổi email, bạn sẽ cần phải dùng email mới cho các lần đăng nhập sau.`,
      },
      alert: {
        nullEmail: `Vui lòng nhập địa chỉ email`,
        validEmail: `Email không đúng định dạng`,
        wrongEmail: `Email không chính xác`,
        nullFirstName: `Vui lòng nhập họ có ít nhất 2 ký tự, vui lòng kiểm tra và thử lại.`,
        nullLastName: `Vui lòng nhập tên có ít nhất 2 ký tự, vui lòng kiểm tra và thử lại.`,
        nullbod: `Vui lòng nhập nhập ngày sinh của bạn`,
        validbod: `Ngày sinh không hợp lệ`,
        nullgender: `Vui lòng chọn giới tính của bạn`,
        nullPhone: `Vui lòng nhập số điện thoại của bạn`,
        validPhone: `Số điện thoại không hợp lệ`,
        messageSucces: `Bạn đã thay đổi thông tin thành công`,
      },
    },
    transactionHistory: {
      bill: `Hóa đơn`,
      myPlan: `gói dịch vụ của bạn`,
      cancelPlan: `Gói dịch vụ sẽ hết hạn vào ngày`,
      autoPlan: `Gói dịch vụ sẽ tự động gia hạn vào ngày`,
      timeUse: `Thời gian sử dụng`,
      cancel: `Hủy gói {{name}}`,
      description: `Mô tả`,
      odv: `TV ${getConfigByKey('short_name')}`,
      total: `Tổng`,
      durationDay: `Gói {{name}} từ {{periodStart}} đến {{periodEnd}}`,
      txtBuyBox: `STB Price (one-time)`,
      txtRentBox: `STB cho thuê hàng tháng`,
      freeAccount: `Tài khoản miễn phí`,
      servicePackageUpgrade: 'Nâng cấp gói dịch vụ',
      package: `Gói`,
    },
    availableplan: {
      plan: `Các gói dịch vụ`,
      buttonUpdate: `Cập nhật gói dịch vụ `,
      countDownTime: ` ngày dùng thử miễn phí`,
      MisscountDownTime: ` Còn`,
      autoActive: `tự động kích hoạt hàng tháng`,
      alertPopup: {
        txtTitle: `Gói dịch vụ của bạn chọn yêu cầu phải có ${getConfigByKey(
          'short_name',
        )} Tivi Box`,
        btnHaveBox: `Tôi đã có hộp ${getConfigByKey('short_name')} TV Box`,
        btnHaveNotBox: `Mua hộp TV ${getConfigByKey('name')}`,
      },
      promoZero: `Mã khuyến mãi giảm {{discount}} của bạn sẽ được áp dụng vào {{timesApplied}} lần thanh toán kế tiếp.`,
      willArrive: `Your ${getConfigByKey(
        'name',
      )} TV box(s) will arrive within 7 days of signing up`,
    },
    forgotPasswordScreen: {
      txtForgot: `Quên mật khẩu ?`,
      placeholderEmail: `Email`,
      placeholderCode: `Mã xác thực`,
      placeholderNewPass: `Mật khẩu mới`,
      buttonTextSignIn: `Xác nhận`,
      buttonTextReSendCode: `Gửi lại mã xác nhận`,
      alertMessFailCode: `Mã xác thực không đúng`,
      alertMessFailEmail: `Email không tồn tại`,
      alertMessIsvalidEmail: `Email không hợp lệ`,
      alertMessSendLink: `Email thay đổi mật khẩu đã được gửi về hộp thư {{email}} của bạn. Vui lòng kiểm tra email này để lấy link xác nhận thay đổi mật khẩu.`,
      alertNullEmail: `Bạn chưa nhập email`,
      alertNullCodePass: `Bạn chưa nhập mã xác thực hoặc mật khẩu mới`,
      alertSuccess: `Cập nhật mật khẩu mới thành công`,
      alertError: `Tài khoản không tồn tại, vui lòng kiểm tra lại`,
      confirmation: `Đổi Mật Khẩu`,
      messageChange: `Vui lòng thay đổi mật khẩu để tiếp tục sử dụng dịch vụ`,
      'sucsses confirmation': `Bạn đã thay đổi mật khẩu thành công`,
      'message login': `Đăng nhập lại để tiếp tục sử dụng dịch vụ`,
      're login': `Đăng Nhập Lại`,
      'new password': `Mật khẩu mới :`,
      're password': `Nhập lại mật khẩu mới :`,
      submit: `Xác nhận`,
      alert: {
        wrong: `Mật khẩu không trùng khớp`,
        wrongs: `Mật khẩu không trùng khớp`,
        valid: `Mật khẩu phải có ít nhất 6 ký tự`,
        null: `Vui lòng nhập mật khẩu`,
        exit: `Thoát`,
      },
      forgotPassword: `Quên mật khẩu`,
      titleForgot: `Nhập địa chỉ email của bạn mà bạn đã sử dụng để đăng ký. Chúng tôi sẽ gửi cho bạn một email với mã xác minh để đặt lại mật khẩu của bạn.`,
      emailAddress: `Địa chỉ email`,
      buttonTextSent: `Gửi`,
      supportEmail: `Để được hỗ trợ thêm, vui lòng liên hệ với dịch vụ chăm sóc khách hàng của chúng tôi tại `,
    },
    changePasswordScreen: {
      updatePass: `Cập nhật mật khẩu`,
      placeholderOldPassword: `Mật khẩu cũ`,
      placeholderPassword: `Mật khẩu mới`,
      rePlaceholderPassword: `Nhập lại khẩu mới`,
      buttonTextSignIn: `Xác nhận`,
      alertSuccess: `Đổi mật khẩu thành công`,
      alertPassLength: `Độ dài mật khẩu phải từ 6 ký tự trở lên`,
      alertProvideInfomation: `Vui lòng cung cấp đầy đủ thông tin`,
      alertErrorNewPass: `Mật khẩu mới và mật khẩu nhập lại không giống nhau`,
      alertErrorOldPass: `Mật khẩu cũ không chính xác, vui lòng nhập lại`,
    },
    contactUs: {
      txtTitle: `Liên lạc`,
      txtSmallTitle: `Vui lòng gửi cho chúng tôi nội dung phản hồi của bạn`,
      labelForm: `Email inquiry`,
      txtHelp: `Giờ làm việc từ <span>9:00</span> giờ sáng đến <span>6:00</span> giờ chiều <span>(Giờ Thái Bình Dương)</span>
      từ Thứ Hai đến Thứ Sáu. Những vấn đề được cập nhật ngoài giờ làm việc
      sẽ được phản hồi trong ngày làm việc hôm sau.
      Xin thông cảm nếu có gì bất tiện.`,
      subjectLbl: `Tiêu đề`,
      commentLbl: `Nội dung`,
      submitBtn: `Gửi đi`,
      txtContentSubject: `Yêu cầu thay đổi hạ cấp từ {{current_package_name}} xuống {{new_package_name}} và trả lại hộp TV (STB). Tôi muốn hạ cấp gói tài khoản của mình {{account_code}}/{{account_email}}`,
      txtContent: `Tôi muốn hạ cấp gói tài khoản {{account_code}}/{{account_email}} của mình từ {{current_package_name}} xuống {{new_package_name}}, và trả lại hộp TV mà tôi đã thuê`,
      placeholder: {
        email: `Địa chỉ email`,
        name: `Họ tên`,
        subject: `Tiêu đề`,
        comment: `Nội dung`,
        txtContactName: `Contact Name`,
      },
      alert: {
        nullEmail: `Vui lòng nhập địa chỉ email`,
        validEmail: `Email không đúng định dạng`,
        nullName: `Vui lòng nhập họ tên`,
        nullSubject: `Vui lòng nhập subject`,
        nullComment: `Vui lòng nhập comment`,
        messageSucces: `Cảm ơn bạn đã gửi phản hồi. Chúng tôi sẽ liên lạc với bạn sớm nhất có thể!`,
      },
      popup: {
        txtTitle: `Gói dịch vụ của bạn chọn thấp hơn gói hiện tại`,
        txtDes: `Vui lòng liên hệ bộ phận chăm sóc khách hàng để gửi trả lại <b> ${getConfigByKey(
          'short_name',
        )} TV Box </b>`,
        txtPhone: `Gọi: ${getConfigByKey('phone_number_1')}`,
        txtOr: `hoặc`,
        btnSubmit: `Gửi`,
      },
    },
    faq: {
      txtQuestion: `Một số câu hỏi thường gặp`,
      txtSub: `Có thể giúp bạn khắc phục sự cố nhanh hơn`,
    },
    landingPage: {
      watch: `Xem phim`,
      'continue watching': `Xem tiếp`,
      'episodes season': `Tập {{episodes}} - Mùa {{season}}`,
      episodes: `Tập {{episodes}}`,
      TxtTotalEps: `{{episodes}} Tập`,
      buttonUpgrade: `Nâng cấp để xem`,
      buttonLogin: `Đăng nhập để xem`,
      btnLogin: `Đăng nhập`,
      directors: `Đạo diễn: `,
      author: `Tác giả: `,
      updating: `Đang cập nhật`,
      actor: `Diễn viên: `,
      day: ` Ngày `,
      month: ` Tháng `,
      movie: `Phim lẻ`,
      addList: `Thêm vào danh sách yêu thích`,
      removeList: `Xóa khỏi danh sách yêu thích`,
      duration: `Thời lượng: `,
      min: ` phút | `,
      recommended: {
        recommended: ` Nội dung liên quan`,
      },
      alert: {
        txtTitle: `Đây là nội dung VIP. Vui lòng nâng cấp tài khoản của bạn để xem.`,
        btnUpgrade: `Nâng cấp`,
        btnCancel: `Hủy`,
      },
      replayShow: `Xem lại tập 1`,
      replayMovie: `Xem lại `,
      season: {
        txtSort: `Sắp xếp`,
        txtNewEps: `Tập mới nhất`,
        txtOldestEps: `Tập cũ nhất`,
      },
    },
    box: {
      alertNullEmail: `Vui lòng nhập địa chỉ email`,
      alertWrongEmailFormat: `Email không đúng định dạng. `,
      'unlimited movies': `XEM PHIM`,
      ondeman: `${getConfigByKey('sologan')}`,
      tvShow: `THEO Ý BẠN !`,
      watch: `Mọi lúc mọi nơi. Gọi để huỷ bất kỳ khi nào.`,
      'join now': `Đăng ký để xem ngay`,
      'ready to watch': `Bạn đã sẵn sàng? Nhập email để tạo hoặc đăng ký lại gói.`,
      sorry: `Xin lỗi, ${getConfigByKey('name')} chưa hỗ trợ quốc gia của bạn`,
      txtBlockContrySubscription: `Xin lỗi, gói dịch vụ này không hỗ trợ ở quốc gia của bạn`,
      txtHome: `Trang chủ`,
      titlePlan: `Các gói dịch vụ`,
      btnContinue: `Tiếp tục xem miễn phí`,
      txtAds: `với quảng cáo & nội dung giới hạn`,
      subscriptionPlanListShortDescription: {
        default: `Chọn gói dịch vụ phù hợp với nhu cầu của bạn. Trải nghiệm 14 ngày dùng thử Miễn Phí.`,
        sctv: `Chọn gói dịch vụ phù hợp với nhu cầu của bạn.`,
      },
    },
    device: {
      txtTitle: `Thiết Bị Hỗ Trợ`,
      txtDes: `Vui lòng chọn loại đăng ký`,
      txtNotBox: `Tôi Cần Một Hộp TV ${getConfigByKey('name')}`,
      txtInfomation: `Bạn cần một hộp TV ${getConfigByKey(
        'name',
      )} để tận hưởng dịch vụ của chúng tôi. Bạn có thể mua hoặc thuê nếu bạn chưa có thiết bị.`,
      txtHasBox: `Đã Có Device`,
      txtHasBoxinfo: `Bạn có thể đăng ký ${getConfigByKey('name')} với bất kỳ thiết bị dưới đây`,
      txtSelect1: `Hộp TV ${getConfigByKey('name')}`,
      txtSelect2: `Chromecast và Google TV`,
      txtSelect3: `Android TV`,
      btnContinue: `Tiếp tục`,
    },
    boxDetail: {
      title: `MUA HỘP TV ${getConfigByKey('short_name')}`,
      'buy now': `ĐẶT MUA`,
      'free 30 day': `cho 30 ngày Dùng Thử Miễn Phí`,
      'auto 30 day': `Tự động kích hoạt & gia hạn sau 1 tháng`,
      'confirm & payment': `Hoàn Thành Giao Dịch`,
      total: `Tổng`,
      box: {
        tv: `1 x ${getConfigByKey('name')} TV Box`,
        remote: `1 x Điều Khiển Từ Xa `,
        power: `1 x Cục sạc `,
        hdmi: `1 x Dây màn hình HDMI `,
        pin: `2 x Cục pin AAA `,
        user: `1 x Sách hướng dẫn sử dụng `,
      },
      'TV Box': `TV Box ${getConfigByKey('name')}`,
      referralPrice: `Giá giới thiệu`,
      return30day: `Đổi trả trong vòng 30 ngày`,
      onlyOne: `Chỉ trả một lần`,
      quantity: `Số lượng`,
      requireDescription: `* Hộp TV sẽ được miễn phí nếu bạn tiếp tục sử dụng gói này sau thời gian dùng thử.`,
      requireDescription2: `Nếu bạn không tiếp tục sử dụng gói này sau thời gian dùng thử, bạn sẽ cần gửi trả lại hộp TV để không bị mất phí $50 cho hộp TV này.`,
    },
    boxLogin: {
      'login/register': `Đăng Nhập/ Đăng Ký`,
      email: `Địa chỉ hộp thư điện tử `,
      password: `Mật khẩu`,
      repassword: `Xác nhận mật khẩu`,
      forgotpass: `Quên mật khẩu ?`,
      'login with facebook': `Đăng nhập bằng FaceBook`,
      alertNullEmail: `Vui lòng nhập Email`,
      alertNullPass: `Vui lòng nhập Mật khẩu`,
      alertValidEmail: `Email không đúng định dạng`,
      login: `Đăng nhập`,
      register: ` Đăng ký`,
      txtCheckbox: `Tôi đã đọc và đồng ý với `,
      txtAnd: `và`,
    },
    boxConfirm: {
      txtTitleBox: {
        default: `Giao Dịch Thành Công`,
        sctv: 'Giao dịch của bạn đã hoàn thành',
      },
      txtTitleFree: {
        default: `Đăng Ký Hoàn Thành`,
        sctv: 'Đăng Ký Hoàn Thành',
      },
      description1: ` Một email đã được gửi về email của bạn, vui lòng kiểm tra email để kích hoạt tài khoản.`,
      description2: ` Vui lòng thực hiện việc kích hoạt để bắt đầu sử dụng dịch vụ.`,
      actived: `Vui lòng tiếp tục nếu email của bạn đã được xác thực`,
      txtGoHome: `Tiếp tục quay lại trang chủ`,
      getCodeOtp: `Lấy mã đăng nhập hộp TV`,
      sentEmail: `Chưa nhận được email kích hoạt?`,
      reSentEmail: `Gửi lại email kích hoạt?`,
      continue: `Bắt đầu xem phim`,
      txtNeedHelp: `Cần trợ giúp?`,
      txtContactUs: `Liên lạc`,
    },
    subscriptions: {
      paypal: {
        txtTitlePaypal: `Việc thanh toán sẽ được xử lý bởi PayPal. <br /> Bạn sẽ được chuyển hướng trở lại trang này sau khi thanh toán PayPal hoàn tất.`,
        btnComplete: 'Hoàn tất mua hàng bằng PayPal',
        btnBackHomePage: 'Quay lại trang chủ',
        btnTryAgain: 'Thử lại lần nữa',
        txtSuccess: 'Thanh toán thành công',
        txtSuccess2: 'Quay trở lại trang chủ để tiếp tục xem các chương trình hấp dẫn',
        txtFail: 'Thanh toán thất bại',
        txtFail2: 'Có thể đã có lỗi trong quá trình thanh toán, làm lại?',
      },
      bootpay: {
        txtTitleBootpay: `Thanh toán sẽ do Bootpay xử lý. <br /> Bạn sẽ được chuyển hướng trở lại trang này sau khi Bootpay thanh toán đã hoàn tất.`,
        btnComplete: 'Hoàn tất mua hàng bằng Bootpay',
      },
      footer: {
        'privacy policy': `Chính sách bảo mật`,
        'box streaming': `Điều khoản sử dụng dịch vụ`,
        termsOfUse: `Điều khoản sử dụng dịch vụ`,
        doNotSell: `Do Not Sell My Information`,
        ContactUs: `Liên Lạc `,
        phone: `${getConfigByKey('phone_number_1')}`,
        email: `{{email}}`,
        copyright: `Copyright 2020 ODK Media, Inc.`,
        txtFaq: `FAQs`,
      },
      'for web': `Cho trình duyệt`,
      'comming soon': ` Sắp ra mắt`,
      'subscribe here': `Đăng ký gói ngay`,
      'sign in': `Đăng nhập`,
      month: `th`,
      tháng: `th`,
      year: `năm`,
      năm: `năm`,
      profile: `Tài khoản của bạn`,
      'privacy policy': `Chính sách bảo mật`,
      'box streaming': `Điều khoản sử dụng`,
      titleBox: `Hoàn toàn miễn phí trong tháng đầu `,
      titleBox2: `nhập thông tin thanh toán để tự động gia hạn từ tháng thứ 2`,
      'finish and pay': `Hoàn Thành Giao Dịch`,
      dayTrial: `Miễn phí {{day}} ngày`,
      'Debit/ credit card': `Thẻ Credit/Debit`,
      'Card number': `Mã thẻ`,
      Expory: `Ngày hết hạn`,
      titleUser: `Điền thông tin sau để chúng tôi có thể gửi Box cho bạn`,
      name: `Họ tên `,
      address: `Địa chỉ nhà `,
      apt: `Apt, Ste, Unit (optional) `,
      city: `Thành phố (San Jose , Westminster, Los Angeles,...)`,
      zip: `Mã bưu chính `,
      state: `Tiểu bang `,
      btnSave: `Lưu và tiếp tục`,
      btnEdit: `Thay đổi`,
      alertnullName: `Vui lòng nhập họ tên có ít nhất 2 ký tự.`,
      alertnullAddress: `Vui lòng nhập địa chỉ`,
      alertnullCity: `Vui lòng nhập Thành phố`,
      alertnullApt: `Vui lòng nhập Atp, Ste, Unit`,
      alertnullZipCode: `Vui lòng nhập Zip code`,
      alertnullstate: `Vui lòng nhập tiểu bang`,
      alertmostName: `Vui lòng nhập họ tên không quá 47 ký tự, vui lòng kiểm tra và thử lại.`,
      alertmostAddress: `Vui lòng nhập địa chỉ không quá 64 ký tự, vui lòng kiểm tra và thử lại.`,
      alertmostApt: `Vui lòng nhập Atp, Ste, Unit không quá 6 ký tự, vui lòng kiểm tra và thử lại.`,
      alertmostZipCode: `Vui lòng nhập Zip code không quá 5 ký tự, vui lòng kiểm tra và thử lại.`,
      alertmostState: `Vui lòng nhập tiểu bang không quá 50 ký tự, vui lòng kiểm tra và thử lại.`,
      alertmostCity: `Vui lòng nhập Thành phố không quá 50 ký tự, vui lòng kiểm tra và thử lại.`,
      alertSucessPay: `Thanh toán thành công, gói dịch vụ của bạn đã được cập nhật`,
      alertValidTitle: `Vui lòng nhập đầy đủ thông tin card của bạn`,
      alertErrorNet: `Có lỗi khi kết nối đến máy chủ, vui lòng thử lại`,
      percentageOff: `Giảm {{percentage}}%`,
      viewDetail: {
        odv: `Dùng thử Miễn Phí`,
        sctv: `Đăng Ký Gói Cước`,
      },
      txtChangePlans: `Thay đổi qua gói {{name}}`,
      txtplanActived: `gói cước hiện tại của bạn`,
      txtService: `Dịch vụ `,
      titleCheckout: `Chi Tiết Giao Dịch`,
      txtShippingAddress: `Địa chỉ giao hàng`,
      txtOrderSummary: `Tóm tắt đơn hàng`,
      txtTotal: `Tổng Cộng`,
      placeholderCouponCode: `Mã Giảm Giá`,
      btnApplyCoupon: `Sử dụng mã`,
      txtPayment: `Thông tin thẻ tín dụng`,
      lblCardHolderName: `Tên Chủ Thẻ`,
      txtWhatsCVC: `Mã CVC là gì?`,
      txtCVCDescription: `CVC là mã số gồm 3-4 kí tự nằm mặt trước hoặc mặt sau của thẻ.`,
      txtTermsOfUse: `Điều khoản sử dụng`,
      txtPrivacyPolicy: `Chính sách bảo mật`,
      txtAnd: `và`,
      txtCompletePurchase: `Hoàn thành giao dịch`,
      txtDiscount: `Giá giảm`,
      txtSubtotal: `Tổng chưa giảm`,
      txtPlanTrial: `Dùng thử {{plans}}`,
      txtOverAge: `Tôi trên 18 tuổi và tôi đồng ý với`,
    },
    video: {
      subtitle: `Tắt phụ đề`,
      vietnam: `Việt Nam`,
      english: `Tiếng Anh`,
      eps: `Tập`,
      messagePopup: {
        txtWarning: `Lưu ý`,
        txtPolicy: `{{tenantSlug}} không chịu trách nhiệm về nội dung của các kênh trực tiếp này, chúng tôi cam kết cố gắng hết sức để chặn bất kỳ nội dung nhạy cảm nào`,
        txtTitle: `Nội dung bị giới hạn`,
        txtDes: `Chương trình này cần đăng nhập để tiếp tục xem`,
        txtDesHaveSupcription: `Vui lòng đăng ký gói dịch vụ để tiếp tục xem`,
        btnUpgrade: `gói dịch vụ`,
      },
    },
    notification: {
      confirmSuccessEmail: `Xác nhận email thành công`,
      selectDoneToContinueWithTheServiceExperience: `Chọn Hoàn Tất để tiếp tục trải nghiệm dịch vụ`,
      done: `Hoàn tất`,
      emailConfirmationFailed: `Xác nhận email thất bại`,
      anErrorOccurredWhileAuthenticatingTheEmail: `Có lỗi xảy ra khi xác thực email.`,
    },
    myList: {
      txtTitle: `Danh sách của tôi`,
      txtNullData: `Bạn chưa thêm nội dung nào vào danh sách`,
    },
    report: {
      txtReport: `Báo lỗi`,
      txtTitle: `Báo Cáo Lỗi`,
      txtDescription1: `Chúng tôi chân thành xin lỗi và rất tiếc về sự cố quý khách gặp phải`,
      txtDescription2: `Để cải thiện chất lượng sản phẩm tốt hơn quý khách vui lòng chọn báo cáo lỗi được gợi ý bên dưới để giúp chúng tôi trong việc khắc phục nhanh hơn.`,
      btnSend: `Gửi báo cáo lỗi`,
      txtDesComplete: `Báo cáo lỗi thành công. Cảm ơn bạn đã gửi báo cáo!`,
      txtTitleComplete: `Báo Cáo Lỗi Hoàn Tất`,
      btnComplete: `Trở về xem phim`,
      btnInput: `Upload image`,
      txtOrther: `Lý do khác / chi tiết`,
      txtErrorFile: `File bạn đã chọn quá lớn. Kích thước tối đa là 10MB`,
      txtErrorSpace: `Vui lòng không nhập khoảng trắng`,
      txtErrorAcc: `Vui lòng đăng nhập!`,
      placeholder: {
        txtComment: `Nội dung`,
        txtFile: `Chọn hình ảnh`,
      },
    },
    update: `Cập nhật`,
    cancel: `Hủy`,
    delete: `Xóa`,
    creditDebitCard: `Thẻ tín dụng/Ghi nợ`,
    txtPalpalPortal: `Cổng giao dịch trực tuyến`,
    expire: `Hết hạn`,
    updateCard: `Cập nhật thẻ`,
    removeCard: `Xóa thẻ`,
    warningRemoveCard: `Thẻ credit/debit của bạn sẽ bị xoá hoàn toàn khỏi tài khoản này. Bạn có thật sự muốn xoá thẻ?`,
    changePaymentInformation: `Thay đổi thông tin thẻ`,
    youAreNotConnectedToTheInternet: `Bạn chưa được kết nối mạng`,
    selectBoxPage: {
      convenientWayToStream: `Xem phim một cách tiện lợi`,
      purchaseOnDemandVietTvBoxToday: `Mua hộp TV ${getConfigByKey('name')} ngay hôm nay!`,
      onDemandVietTvBox: `Hộp TV ${getConfigByKey('name')}`,
      unit: `{{count}} Hộp`,
      unit_plural: `{{count}} Hộp`,
      buy: `Mua`,
      purchaseOptions: `Phương thức mua`,
      continue: `Tiếp tục`,
      iAlreadyHaveAnOnDemandVietTvBoxToday: `Tôi đã có hộp TV ${getConfigByKey('name')}`,
      onDemandVietTvBoxIncludedARemoveControl: `Hộp TV ${getConfigByKey(
        'name',
      )} bao gồm một remote, một <br />dây HDMI, 2 viên pin AAA, và một cục sạc.`,
      rent: `Thuê`,
      lblBoxQuantity: `Số lượng hộp TV`,
      txtOneTimePayment: `Trả một lần`,
      txtAutoRenewal: `Tự động gia hạn`,
      txtShortPerMonth: `/th`,
    },
    announcement: {
      title: `Thông báo`,
      txt1: `Nội dung phát sống hiện tại đang bị chặn vì có chứa nội dung nhạy cảm hoặc chính trị`,
      txt2: `${getConfigByKey(
        'name',
      )} luôn đứng sau lưng quyền tự do của quý vị. Chúng tôi luôn cố gắng cân bằng giữa việc ngăn chặn nội dung chính trị hoặc cắt một phần của Phim truyền hình, chương trình của quý khán giả. Tuy nhiên nếu có sai sót xin quý vị thông cảm do tính chất phát sóng kênh trực tiếp có thể khác so với nội dung phát sóng theo lịch trình.`,
      txt3: `Xin lưu ý rằng một số nội dung nhạy cảm này có thể bị rò rỉ, không phải vì chúng tôi muốn phát sóng những nội dung gây mất lòng, nhưng vì những chương trình này được phát trực tiếp từ đài truyền hình Việt Nam, cho nên sẽ có những thiếu xót ngoài ý muốn.`,
      txt4: `${getConfigByKey(
        'name',
      )} Team chân thành cảm ơn quý khách đã hiểu, thông cảm cho và là khách hàng quý của chúng tôi`,
    },
    noBroadcastSchedule: `Không có lịch phát sóng`,
    broadcastSchedule: `Lịch phát sóng`,
    rentBox: {
      questionMark: {
        txt1: `- Đổi hộp ${getConfigByKey(
          'short_name',
        )} miễn phí khi phần cứng hộp bị lỗi trong khoảng thời gian thuê hộp. Xin vui lòng liên lạc bộ phận chăm sóc khách hàng để lấy tem giao hàng miễn phí.`,
        txt2: `- {{trialDays}} ngày hoàn trả không mất phí trong thời gian dùng thử.`,
        txt3: `- Miễn phí giao hàng.`,
      },
      refund: `Hỗ trợ hoàn tiền trong {{trialDays}} ngày dùng thử.`,
    },
    filter: {
      filterFilm: `Lọc phim`,
      removeAll: `Xóa tất cả`,
      removeFilter: `Xóa bộ lọc`,
      regions: `Quốc gia`,
      genres: `Thể loại`,
      languages: `Phụ đề`,
      provider: `Content provider`,
      people: `Diễn viên/Đạo diễn/Nhà sản xuất`,
      all: `Tất cả`,
      txtFitlerNondata: `Rất tiếc, không tìm thấy nội dung phù hợp với lựa chọn của bạn.`,
      txtFilterHistory: `Lịch sử bộ lọc: `,
    },
    notDataToShow: `Không có dữ liệu để hiển thị`,
    cancelSubscription: {
      survey: {
        sendSurvey: `Gửi khảo sát`,
        back: `Quay lại`,
        title: `Khảo sát dịch vụ`,
        description1: `Chúng tôi rất tiếc về quyết định của bạn. Để giúp trải nghiệm khách hàng được tốt hơn, xin vui lòng cung cấp lý do dẫn đến việc ngừng dịch vụ.`,
        description2: `Xin cảm ơn!`,
        reason: `Lý do hủy dịch vụ:`,
        txtError1000: `Vui lòng không nhập vượt quá 1000 kí tự`,
        txtSelect: `Có thể chọn nhiều câu hỏi`,
        txtDes: `Nội dung`,
        txt1k: `1000 ký tự`,
      },
      confirm: {
        title: `Hủy gói dịch vụ`,
        description1: `Công ty chúng tôi đang nỗ lực hoàn thiện chất lượng sản phẩm hàng ngày, chỉ trong vòng một vài tháng tới bạn sẽ thấy được những cải thiện về cả mặt nội dung và các tính năng mới phù hợp với bạn. Xin vui lòng đồng hành cùng những bước tiến của công ty!`,
        description2: `Ưu Đãi ĐẶC BIỆT dành riêng cho quý khách nếu tiếp tục sử dụng dịch vụ`,
        discount: `giảm {{percent}}% cho {{month}} tháng tiếp theo`,
        anotherTry: `Tôi sẽ thử lại lần nữa`,
        no: `Không..., Xin lỗi không thể giúp bạn`,
      },
      agree: {
        title: `Tiếp tục sử dụng dịch vụ`,
        description: `Cảm ơn bạn đã tin tưởng và quyết định tiếp tục sử dụng dịch vụ của ${getConfigByKey(
          'short_name',
        )}. Các ý kiến đóng góp của bạn sẽ được cân nhắc và khắc phục trong thời gian sớm nhất để đảm bảo trải nghiệm của bạn sẽ được cải thiện một cách tốt nhất.`,
        continue: `Tiếp tục xem phim`,
      },
      completed: {
        title: `Hoàn tất huỷ dịch vụ`,
        description1: `Nhấp vào "Hoàn tất Hủy" bên dưới để hoàn tất việc hủy dịch vụ của bạn.`,
        description2: `- Việc hủy gói dịch vụ sẽ có hiệu lực vào cuối kỳ hoá đơn hiện tại của bạn vào: {{restTime}}`,
        description3: `- Bạn có thể tái kích hoạt đăng ký của mình bất cứ lúc nào. Trong khi đó bạn vẫn có thể sử dụng ${getConfigByKey(
          'name',
        )} với nội dung giới hạn ...`,
        thanks: `Trân trọng cảm ơn quý khách!`,
        finish: `Hoàn tất hủy`,
        back: `Quay lại`,
      },
    },
    cooldown: {
      program: `Chương trình tiếp theo`,
      nameEpg: `{{name}}`,
      timeStart: `Sẽ bắt đầu sau: `,
      hours: `giờ`,
      minute: `phút`,
      second: `giây`,
    },
    recommedation: {
      txtComing: `Vì bạn đã xem {{name}}`,
      txtStart: `Chương trình tiếp theo sẽ bắt đầu sau: `,
    },
    localizedMessages: {
      invalid_number: `Số thẻ của quý khách đã nhập không phải là số thẻ tín dụng hợp lệ.`,
      invalid_expiry_month: `Tháng hết hạn của thẻ không hợp lệ.`,
      invalid_expiry_year: `Năm hết hạn của thẻ không hợp lệ.`,
      invalid_cvc: `Mã bảo mật của thẻ không hợp lệ.`,
      incorrect_number: `Số thẻ không chính xác.`,
      incomplete_number: `Số thẻ quý khách nhập chưa đầy đủ.`,
      incomplete_cvc: `Quý khách vui lòng nhập đầy đủ mã bảo mật của thẻ.`,
      incomplete_expiry: `Quý khách vui lòng nhập đầy đủ thông tin ngày hết hạn của thẻ.`,
      expired_card: `Thẻ đang thanh toán hết hạn.`,
      incorrect_cvc: `Mã bảo mật của thẻ không chính xác.`,
      incorrect_zip: `Không xác thực được mã zip của thẻ.`,
      invalid_expiry_year_past: `Năm hết hạn của thẻ không đúng.`,
      card_declined: `Thẻ của bạn đã từ chối.`,
      missing: `There is no card on a customer that is being charged.`,
      processing_error: `Đã có lỗi xảy ra trong quá trình xử lý thẻ.`,
      invalid_request_error: `Có lỗi khi xử lý giao dịch. Quý khách vui lòng thử lại sau hoặc sử dụng phương thức thanh toán khác.`,
      invalid_sofort_country: `Quốc gia thanh toán không được chấp nhận bởi SOFORT. Quý khách vui lòng thử lại.`,
      email_invalid: `Địa chỉ email không hợp lệ.`,
    },
    player: {
      txtMind: 'Lưu ý: ',
      txtContent: `<b>{{tenantSlug}}</b> không chịu trách nhiệm về nội dung của các kênh Youtube này.
      Bạn sẽ thấy quảng cáo như trên Youtube vì {{tenantSlug}} chỉ kết nối bạn đến với nội dung gốc và chủ sở hữu hợp pháp.`,
      blockAds: {
        txtTitle: `Vui lòng tắt ad-blocker`,
        txtDes: `Bạn chỉ có thể xem nội dung miễn phí kèm theo quảng cáo. Vui lòng tắt ad-blocker plugin của trình duyệt để có thể tiếp tục xem.`,
        txtButton: `Hướng dẫn tắt ad-blocker`,
      },
      warning: {
        txtHeader: 'CẢNH BÁO',
        txtTitle: 'NỘI DUNG NGƯỜI LỚN',
        txtDescription:
          'Vui lòng lưu ý rằng nội dung này có tính khiêu gợi hoặc bạo lực. Những người dưới 18 tuổi sẽ không được phép xem nội dung này. Nếu bạn không nằm trong độ tuổi hợp pháp, vui lòng rời khỏi trang này ngay tức thì.',
        txtContentUnConfirm: 'Rời khỏi',
        txtContentConfirm: 'Tôi đã trên 18 tuổi',
      },
    },
    youtube: {
      txtRemoveTitle: `Xoá nội dung này khỏi danh sách đang xem?`,
      txtRemoveDescription: `Bạn sẽ không thể tiếp tục xem đúng thời điểm bạn tạm dừng.`,
      btnCancel: `Hủy`,
      btnRemove: `Xóa`,
    },
    share: {
      txtCopy: `Sao chép`,
      txtCopied: `Sao chép thành công`,
      txtShare: `Chia sẻ`,
    },
    continue: 'Tiếp tục',
    payperview: {
      day: 'ngày',
      hour: 'giờ',
      hours: 'giờ',
      movie: 'phim',
      show: 'bộ phim',
      plan: {
        month: {
          txtPlan: 'Mua Gói Tháng',
          txtDes: 'Xem tất cả nội dung không giới hạn',
          txtOption1: 'Dùng thử 14 ngày MIỄN PHÍ',
          txtOption2: 'Gần 17,000 giờ giải trí Premium',
          btn: 'Xem chi tiết',
        },
        views: {
          txtBuyDrama: 'Mua Bộ Phim',
          txtBuyMovie: 'Mua Phim Lẻ',
          timePaid: `Thời hạn xem {{timePaid}} {{unit}}`,
          btn: 'Tiếp Tục',
        },
      },
      success: {
        txtTitle: 'Giao dịch của bạn đã hoàn thành',
        txtDesDrama: `Bộ phim của bạn đã được mua và kích hoạt thành công,<br /> bạn có thời hạn {{timePaid}} {{unit}} để xem bộ phim này.`,
        txtDesMovie: `Phim của bạn đã được mua và kích hoạt thành công,<br /> bạn có thời hạn {{timePaid}} {{unit}} để xem phim này.`,
        btn: 'Tiếp Tục',
      },
    },
    footer: {
      home: 'Home',
      products: 'Products',
      company: 'Company',
      blog: 'Blog',
    },
    Remove: 'Xóa',
    ppvDescription:
      'VOD contents can be viewed immediately after the payment, and refunds are not supported after payment is complete on exception of Live contents.',
    ppvRefundCondition: '',
    page404: {
      title: '',
      description: 'Nội dung không được tìm thấy',
      btnToHomePage: 'Quay lại trang chủ',
    },
  },
};

export default vi;
