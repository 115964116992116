export const SwitchFormButton = ({ handleOnClick = () => {}, label = '', actionLabel = '' }) => {
  return (
    <div className="text-center font-open-sans text-[16px] text-[#1E232A]">
      <span className="switch-form-label">{label} </span>
      <button
        className="border-none bg-[transparent] p-[0] capitalize text-[#e30000]"
        onClick={handleOnClick}
      >
        {actionLabel}
      </button>
    </div>
  );
};
