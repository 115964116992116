import React, { useEffect } from 'react';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import i18nextXHRBackend from 'i18next-xhr-backend';
import { I18nextProvider } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getLocale } from '../store/app/selectors';

import en from '@/shared/i18n/locales/en';
import vi from '@/shared/i18n/locales/vi';
import ko from '@/shared/i18n/locales/ko';

if (__BROWSER__) {
  i18next.use(i18nextXHRBackend);
}

// i18next.use(__BROWSER__ ? i18nextXHRBackend : {}).init({
i18next.init({
  // backend: {
  //   // for all available options read the backend's repository readme file
  //   loadPath: '/locales/{{lng}}/{{ns}}.json',
  // },

  debug: process.env.NODE_ENV === 'development' && __BROWSER__,
  fallbackLng: 'en',
  ns: ['translations'],
  defaultNS: 'translations',
  compatibilityJSON: 'v2',
  lng: 'vi',
  // This option is necessary to tell i18next to try loading missing resources via
  // i18next-xhr-backend, otherwise no calls will be made if resources are defined.
  partialBundledLanguages: true,
  resources: {
    // de_DE: { translation: deDE },
    // en_US: { translation: enUS },
    en: en,
    vi: vi,
    ko: ko,
  },
  parseMissingKeyHandler: (missing: any) => {
    if (process.env.NODE_ENV === 'development' && __BROWSER__) {
      console.warn('MISSING TRANSLATION:', missing);
    }

    return missing;
  },
  react: {
    wait: true,
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: false,
  },
});

i18next.languages = ['en', 'vi'];
const I18N: React.FC<any> = ({ children }) => {
  return <I18nextProvider i18n={i18next}>{children}</I18nextProvider>;
};

export default React.memo(I18N);
