import { pathToUrl } from '@/shared/utils/routes';

const baseUrl = '/:lg?';
// const baseUrl = isNode() ? '{/:lng}' : '/:lng?';
export const routePageNames = {
  homePage: '/',
  tenantPageBySlug: `/page/:slug`,
  accountConfirm: '/account/confirmation',
  loginSSO: '/auth/login/sso',
  termOfUse: '/terms-of-use',
  privacyPolicy: '/privacy-policy',
  termsOfUse: '/terms-of-use',
  contentDetailBySlug: '/detail/:slug',
  contentLandingBySlug: '/landing/:slug',
  contentLiveBySlug: '/live/:slug',
  ribbonDetailBySlug: '/ribbon/:slug',
  favorite: '/favorite',
  searchByKeyword: '/search/:keyword',
  searchByKeywordInOther: '/search/:keyword/page/:page',
  profileTab: '/profile/:id',
  help: '/help',
  faqSlug: '/faq/:slugBlockAds',
  faq: '/faq',
  categoryBySlug: '/category/:slug',
  passwordChange: '/password/change',
  blockCountry: '/block',
  signInSignUp: '/:signInUp(sign-in|sign-up)',
  signUpSuccessfully: '/sign-up-successfully',
  tvSignInUp: '/:tvMethod(tv-login|tv-signup)',
  tvLoginSuccessfully: '/tv-login-successfully',
  subscriptionChange: '/subscription',
  validateCode: '/validate-code',
  paymentPlan: '/:lg/box/:plan',
  boxConfirm: '/:lg?/box/confirm',
  contentPlayer: '/content-player/:contentSlug',
  documentPage: '/document/:documentSlug',
  box: '/:lg?/box',
  boxOffer: '/:lg?/box/offers',
};

export const getRoute = (
  path: string,
  params?: { [key: string]: string | number },
  routesConfig: any = routePageNames,
) =>
  path.split('.').reduce((routeBranch: any, pathItem: string) => {
    if (routeBranch && routeBranch[pathItem]) {
      const route = routeBranch[pathItem];
      if (typeof route === 'string') {
        if (!params || typeof params === 'undefined') {
          return route;
        }

        return Object.entries(params).reduce((replaced, [key, value]) => {
          return replaced.replace(`:${key}`, String(value));
        }, route);
      }
      return routeBranch[pathItem];
    }
  }, routesConfig);

export const generateTvLogin = () => pathToUrl(routePageNames.tvSignInUp, { tvMethod: 'tv-login' });

/**
 *
 * @param {String} contentId
 * @returns
 */
export const generateLandingPageByContentID = (contentId: string) =>
  pathToUrl(routePageNames.contentLandingBySlug, { slug: contentId });

/**
 *
 * @param {String} contentId
 * @returns
 */
export const generateDetailPageByContentId = (contentId: string) =>
  pathToUrl(routePageNames.contentDetailBySlug, { slug: contentId });
