import { TContent } from '@/core/models/Content';
import { RootState } from '@/store/store';
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

type TContentSliceState = {
  listById: Record<string, TContent>;
  loading: string;
  currentRequestId: string | undefined;
  error: string | null | undefined;
};

// const fetchUserById = createAsyncThunk(
//   'content/fetchByIdStatus',
//   async (userId, { getState, requestId }: any) => {
//     const { currentRequestId, loading } = getState().users;
//     if (loading !== 'pending' || requestId !== currentRequestId) {
//       return;
//     }
//     const response = await Promise.resolve([]);
//     return response.data;
//   },
// );

const initialState: TContentSliceState = {
  listById: {},
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
};

export const contentSlice = createSlice({
  name: 'contents',
  initialState,
  reducers: {
    addContent: (state, { payload: content }: PayloadAction<TContent>) => {
      state.listById[content.id as string] = content;
      state.listById[content.slug as string] = content;
    },
  },
  // extraReducers: builder => {
  //   builder
  //     .addCase(fetchUserById.pending, (state, action) => {
  //       if (state.loading === 'idle') {
  //         state.loading = 'pending';
  //         state.currentRequestId = action.meta.requestId;
  //       }
  //     })
  //     .addCase(fetchUserById.fulfilled, (state, action) => {
  //       const { requestId } = action.meta;
  //       if (state.loading === 'pending' && state.currentRequestId === requestId) {
  //         state.loading = 'idle';
  //         state.currentRequestId = undefined;
  //       }
  //     })
  //     .addCase(fetchUserById.rejected, (state, action) => {
  //       const { requestId } = action.meta;
  //       if (state.loading === 'pending' && state.currentRequestId === requestId) {
  //         state.loading = 'idle';
  //         state.error = action.error;
  //         state.currentRequestId = undefined;
  //       }
  //     });
  // },
});

export const { reducer: contentReducer } = contentSlice;
export const { addContent } = contentSlice.actions;

export const selectListById = (state: RootState) => state?.[contentSlice.name].listById;
export const selectContentID = (state: RootState, contentID: string) => contentID;
export const selectContentByID = createSelector(
  [selectListById, selectContentID],
  (contentList, contentID) => contentList?.[contentID],
);
