import { useDispatch, useSelector } from 'react-redux';
import { actions } from './alertPopupSlice';

import { useNavigate } from '@/core/hook/navigation/useNavigate';
import { BaseAlertPopup } from './BaseAlertPopup';

const AlertModal = props => {
  const { navigate } = useNavigate();
  const alertPopup = useSelector(state => state.alertPopup);
  const { nextPath } = alertPopup;
  const dispatch = useDispatch();

  const closeDefault = () => {
    if (nextPath) {
      navigate({ path: nextPath, isReplace: true });
    }
    dispatch(actions.close());
  };

  const onCancel = () => dispatch(actions.close());
  const { handleClose = closeDefault, handleConfirm = closeDefault, ...restProps } = props;

  return (
    <BaseAlertPopup
      {...alertPopup}
      {...restProps}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      handleCancel={onCancel}
    />
  );
};

export default AlertModal;
