// primary, secondary, tertiary, quaternary, quinary, senary, septenary, octonary, nonary, and denary
// Opacity %   255 Step        2 digit HEX prefix
// 0%          0.00            00
// 5%          12.75           0C
// 10%         25.50           19
// 15%         38.25           26
// 20%         51.00           33
// 25%         63.75           3F
// 30%         76.50           4C
// 35%         89.25           59
// 40%         102.00          66
// 45%         114.75          72
// 50%         127.50          7F
// 55%         140.25          8C
// 60%         153.00          99
// 65%         165.75          A5
// 70%         178.50          B2
// 75%         191.25          BF
// 80%         204.00          CC
// 85%         216.75          D8
// 90%         229.50          E5
// 95%         242.25          F2
// 100%        255.00          FF
export const data = {
  odv: {
    name: 'odv',
    primaryColor: '#2574d4',
    secondaryColor: '#2574d4',
    typography: {
      primaryColor: '#333333',
    },
    body: {
      background: '#fff',
      font: 'Open Sans',
      colors: '#fff',
    },
    header: {
      imglogo: '11rem',
      background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.88) 0%, rgba(0, 0, 0, 0) 100%)',
    },
    homePage: {
      background: '#fff',
      backgroundRibbon: '#f5f5f5',
      colorRibbon: '#000000',
    },
    newSlide: {
      btn: 'linear-gradient(0deg, #4257f5 5%, #2239e6 100% )',
      btnUpgrade: 'linear-gradient(0deg, #e8b600 5%, #e8b600 100%)',
      colorTextMobile: '#7d7d7d',
      backgroundMobile: '#fff',
    },
    filter: {
      colorBtn: '#2574d4',
      colorBtnActive: '#2239e6',
      colorTxt: '#333333',
    },
    ribbon: {
      background: '#f5f5f5',
      itemNameColor: '#555555',
      titleColor: '#444',
      displayTitle: 'block',
    },
    landingPage: {
      relatedName: '#555555',
      bannerColorDecription: 'rgb(125,125,125)',
      bannerBackgroundDecription: '#fff',
      bannerColorTitle: '#242323',
    },
    searchPage: {
      colorTitle: '#555555',
    },
    input: {
      color: '#283237',
      placeholder: '#999999',
      label: '#2574d4',
      error: '',
      focus: '#2574d4',
      backgroundColor: '#fff',
    },
    signin: {
      secondaryColor: '#596d79',
    },
    register: {
      backgroundColor: '#FFFFFF',
      color: '#283237',
      secondaryColor: '#b3bec4',
      link: '#2574d4',
      buttonSave: {
        color: '#f7f8fa',
        backgroundColor: '#2574d4',
      },
      buttonFB: {
        backgroundColor: '#ffffff',
        color: '#2574d4',
      },
    },
    plans: {
      color: '#333333',
      secondaryColor: '#596d79',
      plan: {
        buttonBorderColor: 'blue',
        renewalImage: '../../assets/img/autoRenewal.svg',
        buttonColorHover: '#ffffff',
      },
    },
    regitraton: {
      color: '#283237',
    },
  },
  sctv: {
    name: 'sctv',
    primaryColor: '#E72D30',
    secondaryColor: '#e30000',
    typography: {
      primaryColor: '#ffffff',
    },
    body: {
      background: '#171717',
      font: 'Open Sans',
      colors: '#fff',
    },
    header: {
      imglogo: '150px',
      background:
        'linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0.7) 70%, rgba(0, 0, 0, 0) 100%)',
    },
    homePage: {
      background: '#000000',
      backgroundRibbon: '#000000',
      ribbon: '#000000',
    },
    newSlide: {
      btn: 'linear-gradient(0deg, #4257f5 5%, #2239e6 100% )',
      btnUpgrade: 'linear-gradient(0deg, #e8b600 5%, #e8b600 100%)',
      colorTextMobile: '#fff',
      backgroundMobile: '#000000',
    },
    filter: {
      colorBtn: 'red',
      colorBtnActive: 'yellow',
      colorTxt: '#fff',
    },
    ribbon: {
      background: '#000000',
      itemNameColor: '#fff',
      titleColor: 'red',
      displayTitleItem: 'block',
    },
    landingPage: {
      relatedName: '#fff',
      bannerColorDecription: '#fff',
      bannerBackgroundDecription: '#000000',
      bannerColorTitle: '#fff',
    },
    searchPage: {
      colorTitle: '#fff',
    },
    input: {
      color: '#e30000',
      placeholder: '#94a3ad',
      label: '#94a3ad',
      error: '',
      focus: '#e30000',
      backgroundColor: '#000000',
    },
    register: {
      backgroundColor: '#000000',
      color: '#FFFFFF',
      secondaryColor: '#b3bec4',
      colorInput: '#94a3ad',
      link: '#b3bec4',
      buttonSave: {
        backgroundColor: '#e30000',
        color: '#ffffff',
      },
      buttonFB: {
        backgroundColor: '#fefefe',
        color: '#1977f3',
      },
    },
    signin: {
      secondaryColor: '#d6d6d6',
    },
    plans: {
      color: '#ffffff',
      secondaryColor: '#d6d6d6',
      plan: {
        buttonBorderColor: '#E72D30',
        renewalImage: '../../assets/img/sctv/auto-renewal.svg',
        buttonColorHover: '#ffffff',
      },
    },
    regitraton: {
      color: '#ffffff',
    },
  },
};
