import windowOrGlobal from 'global';

export let tenantEnvConfig = {};

export const getConfigFromEnv = () => windowOrGlobal?.ssrTenantConfig ?? {};

const {
  tenant_slug = '',
  platform_slug = '',
  ui_theme = '', // Not available on backend yet
  environment = '',
  ...others
} = getConfigFromEnv();

tenantEnvConfig = {
  tenant_slug,
  platform_slug,
  ui_theme, // Not available on backend yet
  environment,
  ...others,
};

export const setTenantEnvConfig = newEnvConfig => {
  windowOrGlobal.ssrTenantConfig = newEnvConfig;
  tenantEnvConfig = newEnvConfig;
};
