import * as actions from './actions';
import * as appAPIs from '@/api/appAPIs';
import { store } from '@/store/store';
import * as configApi from '@/api/config';

export { initialApp, changeLanguage, checkGeoBlocking, getMuxEnvKey, getRegionSubscription };

function initialApp() {
  return dispatch => {
    dispatch(actions.initialApp());
  };
}

function changeLanguage(value) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(actions.changeLanguage(value));
      resolve();
    });
  };
}

function checkGeoBlocking() {
  return dispatch => {
    return Promise.resolve();
  };
}

function getMuxEnvKey() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      configApi
        .getMuxEnvKey()
        .then(response => {
          dispatch(actions.updateMuxKey(response.results));
          resolve(response);
        })
        .catch(errors => {
          reject(errors);
        });
    });
  };
}

function getRegionSubscription() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      configApi
        .getRegionSubscription()
        .then(response => {
          dispatch(actions.updateRegionSubscription(true));
          resolve(response);
        })
        .catch(errors => {
          dispatch(actions.updateRegionSubscription(false));
          reject(errors);
        });
    });
  };
}
