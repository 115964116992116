import React, { useState } from 'react';
import { Form, FormProps } from '../Form';
import * as yup from 'yup';
import { TUseForgotPasswordFormProps, useForgotPasswordFormProps } from './useForgotPasswordForm';
import { Controller } from 'react-hook-form';
import { BaseInput } from '../BaseInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { getConfigByKey } from '@/shared/features/tenantConfig/services';
import { verifyEmailFormat, verifyPhoneNumber } from '@/shared/utils/utils';
import i18n from 'i18next';
import { routePageNames } from '@/core/routes';
import { useHistory, useLocation } from 'react-router-dom';
import { stringifyUrl } from 'query-string';

type TForgotPasswordFormProps = TUseForgotPasswordFormProps;

const schema = yup
  .object({
    username: yup
      .string()
      .required('Vui lòng nhập số điện thoại hoặc địa chỉ email hợp lệ')
      .test({
        name: 'email',
        message: i18n.t('alertWrongEmailFormat'),
        test: (emaiValue: string = '') =>
          !/^[0-9]+$/.test(emaiValue) ? verifyEmailFormat(emaiValue) : true,
      })
      .test({
        name: 'phone',
        test: (emaiValue: string = '', ctx) =>
          /^[0-9]+$/.test(emaiValue) ? verifyPhoneNumber(emaiValue) : true,
        message: i18n.t('invalid_phone_number'),
      }),
  })
  .required();

type FormFields = yup.InferType<typeof schema>;

const initValues: FormFields = {
  username: '',
};
const fieldName = 'username';
const email = getConfigByKey('email');

const successMessages = {
  email: `Một mã thay đổi mật khẩu đã được gửi về hộp thư {{${fieldName}}} của bạn. Vui lòng kiểm tra email để lấy mã xác nhận thay đổi mật khẩu`,
  phoneNumber: `Một mã thay đổi mật khẩu đã được gửi về tin nhắn số điện thoại {{${fieldName}}} của bạn. Vui lòng kiểm tra tin nhắn SMS để lấy mã xác nhận thay đổi mật khẩu.`,
};

const getSuccessMessage = (isPhoneNumber: boolean, username: string) => {
  const message = isPhoneNumber ? successMessages['phoneNumber'] : successMessages['email'];
  return message.replace(`{{${fieldName}}}`, username);
};

export const ForgotPasswordForm = (props: TForgotPasswordFormProps) => {
  const { pathname } = useLocation();
  const { handleRequestResetPassword } = useForgotPasswordFormProps();

  const [systemMessage, setSystemMessage] = useState('');
  const [systemMessageType, setsystemMessageType] =
    useState<FormProps['systemMessageType']>('info');
  const handleError = (errorMessage: string) => {
    setSystemMessage(errorMessage);
    setsystemMessageType('error');
  };
  const handleSuccess = (successMessage: string) => {
    setSystemMessage(successMessage);
    setsystemMessageType('info');
  };
  const handleSubmit = async ({ username }: FormFields) => {
    try {
      const isPhoneNumber = verifyPhoneNumber(username);
      const params = {
        [isPhoneNumber ? 'phoneNumber' : 'email']: username,
        resetPasswordUrl: stringifyUrl({
          url: `${window.location.origin}${routePageNames.passwordChange}`,
          // query: { form: 'resetPassword', next: pathname },
        }),
      };
      const res = await handleRequestResetPassword(params);
      handleSuccess(getSuccessMessage(verifyPhoneNumber(username), username));
    } catch (error: any) {
      handleError(error.message);
    }
  };

  const renderFooter = () => (
    <div className="text-center font-open-sans text-[16px] text-[#1E232A]">
      Để được hỗ trợ thêm, vui lòng liên hệ với dịch vụ chăm sóc khách hàng của chúng tôi tại <br />
      <a className="text-[16px] text-primary" href={`mailto:${email}`}>
        {email}
      </a>
    </div>
  );
  return (
    <div className="ForgotPasswordForm md:pb-[150px]">
      <Form
        initialForm={{
          defaultValues: initValues,
          resolver: yupResolver(schema),
        }}
        onSubmit={handleSubmit}
        detailDescription="Nhập số điện thoại hoặc email mà bạn sử dụng để đăng ký tài khoản. Chúng tôi sẽ gửi tới bạn email hoặc SMS với mã xác minh để đặt lại mật khẩu."
        footer={renderFooter()}
        titleForm="Quên mật khẩu?"
        systemMessage={systemMessage}
        systemMessageType={systemMessageType}
        submitLabel="Gửi"
        titleFormProps={{
          variant: 'long',
          className: 'mt-[17px]',
        }}
        actionClassName="mt-0 mb-[35px]"
        className="px-[10px] md:px-[16px]"
      >
        {({ control, formState: { errors } }) => (
          <>
            <Controller
              control={control}
              name="username"
              render={({ field }) => (
                <BaseInput
                  {...field}
                  label="Số điện thoại hoặc địa chỉ email người dùng"
                  fullWidth
                  error={errors?.username?.message}
                  required
                />
              )}
            />
          </>
        )}
      </Form>
    </div>
  );
};
