import { routePageNames as routes } from '@/core/routes';

import { tenantEnvConfig } from './tenantEnvConfig';
import { REACT_APP_URL_PRIVACY_POLICY } from '@/shared/features/footer/constants';

const stringsByTenantSlug = {
  default: {
    name: 'Vimai',
  },
  odv: {
    name: 'OnDemandViet',
    short_name: 'ODV',
    phone_number_1: '+1 (855) 910 - 0922',
    phone_number_2: '+1 (714) 278 - 3915',
    email: 'help@ondemandviet.com',
    sologan: 'OnDemand',
    address: {
      copyright: '© 2020 ODK Media Inc.',
      copyrightV2: '© 2012-2020 ODK Media, Inc. OnDemandKorea & Design Reg. U.S. Pat. & Tm. Off.',
      address: '221 N. Harbor Blvd., Suite E',
      state: 'Fullerton , California 92832, USA',
    },
    privacyPolicyLink: REACT_APP_URL_PRIVACY_POLICY,
  },
  sctv: {
    name: 'SCTV Online',
    sologan: 'SCTV',
    short_name: 'SCTV',
    phone_number_1: '19001878',
    email: 'sctvonline@sctv.vn',
    address: {
      copyright: 'Số ĐKKD: 0301463315 do Sở KHĐT TP.HCM cấp ngày 09/04/2010',
      address: '31-33 Đinh Công Tráng, P. Tân Định, Q.1, TP.HCM',
      state: '',
    },
    privacyPolicyLink: `${routes.privacyPolicy}`,
    termsOfUseLink: `${routes.termsOfUse}`,
    appDeepLink: {
      android: 'https://play.google.com/store/apps/details?id=io.vimai.sctvonline&pli=1',
      ios: 'https://apps.apple.com/vn/app/sctv/id1564652065',
    },
  },
  projectw: {
    name: 'Project W',
    phone_number_1: '02-749-0507',
    email: 'cs@illuon.net',
    address: {
      copyright: '© 2021 WizOnsen Co., Ltd.',
      copyrightV2: 'Mail-order business report number 2021-Seoul Gangnam-06782',
      address:
        'Representative Park Gwan-woo 5th floor, Sinsacheongho Building, 644-5, Sinsa-dong, Gangnam-gu, Seoul',
      state: '',
      copyright_ko: '© 2021 주식회사 위즈온센',
      copyrightV2_ko: '통신판매업 신고번호 제 2021-서울강남-06782호',
      address_ko: '대표 박관우 서울특별시 강남구 신사동 644-5, 신사청호빌딩 5층',
    },
    companyReg: 'Company Reg. 736-87-01910',
    companyReg_ko: '사업자등록증번호 736-87-01910',
  },
};

export const tenantStrings =
  tenantEnvConfig.tenant_slug in stringsByTenantSlug
    ? stringsByTenantSlug[tenantEnvConfig.tenant_slug]
    : stringsByTenantSlug['default'];
