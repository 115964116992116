import {
  ALL_LIVE_TYPE,
  CHANNEL_TYPE,
  CONTENT_TYPE_STRING_MAP,
  EPISODE_TYPE,
  SEASON_TYPE,
  SHOW_TYPE,
  TRAILER_TYPE,
  TYPE_NO_LANDING_PAGE,
} from '@/core/constants/contentConstants';
import i18n from 'i18next';
import qs from 'query-string';
import {
  subscriptionContent,
  subscriptionAccount,
  renderBadgeContent,
  checkSubscriptionContentTheHighest,
} from '@/core/method/subScriptionPlanMethod';
import _, { isBoolean, isEmpty } from 'lodash';
import videojs from 'video.js';
import { getConfigByKey } from '@/shared/features/tenantConfig/services';
import { AGE_RATING, CONTENT_RATING } from '@/core/constants/ageContentRating';
import moment from 'moment';
import {
  IMAGE_BACKDROP_TYPE,
  IMAGE_BANNER_TYPE,
  IMAGE_POSTER_TYPE,
  IMAGE_THUMBNAIL_TYPE,
  SUPPORT_FORMAT_WEBP,
} from '@/core/constants/imageConstants';
import { getImageWidth, getResolutionScaleDevice } from '@/shared/utils/utils';
import { CONTENT_YOUTUBE_SOURCE_TYPE } from '../constants/contentConstants';
import {
  FREE_PAYMENT_TYPE,
  HYBRID_PAYMENT_TYPE,
  PaymentTypeEnum,
  SVOD_PAYMENT_TYPE,
  TVOD_PAYMENT_TYPE,
} from '../constants/paymentType';
import { routePageNames } from '../routes';
import { pathToUrl } from '@/shared/utils/routes';

export function getLinkUpgradeToWatch() {
  try {
    const flagEnbTier = getConfigByKey('features.multiTierSubscriptionEnabled');
    const { tierPackage, entity } = this;
    const { tier_subscription = 1, type, slug, id, min_sub_tier } = entity;
    const lng = i18n.language;
    const callbackUrl = type === SHOW_TYPE ? `${lng}/landing/${slug}` : `${lng}/detail/${slug}`;
    return `/${lng}/box/offers?${qs.stringify({
      callbackUrl,
      tier: flagEnbTier ? tierPackage : null,
    })}`;
  } catch (error) {
    return '';
  }
}

export function getPaidContentById(paidContents = []) {
  const { id } = this;
  return paidContents.find(paidContent => paidContent?.content_id === id);
}

export function canWatchTvod({ inListing = true, paidContents = [] }) {
  try {
    const { id, payment_infors, show_info = {}, show } = this;
    const showInstance = show ?? show_info;

    const { id: showId } = showInstance;
    if (payment_infors) {
      return true;
    }
    const paidContent = getPaidContentById.call({ id: showId ?? id }, paidContents);
    if (paidContent && paidContent?.remaining_time > 0) {
      return true;
    }
  } catch (error) {}
  return false;
}

export function canWatchSvod({ inListing = true, account }) {
  try {
    const { min_sub_tier } = this;
    const { profile = {} } = account || {};
    if (_.isEmpty(profile)) {
      return false;
    }
    const { subscription_plan_info = {}, has_subscription } = profile || {};
    const { sub_tier } = subscription_plan_info ?? {};

    if (has_subscription && !min_sub_tier) {
      return true;
    }

    if (min_sub_tier && sub_tier && min_sub_tier?.tier >= sub_tier.tier) {
      return true;
    }
  } catch (error) {}
  return false;
}

export function canWatch({ account = null, inListing = true, paidContents = [] }) {
  try {
    const {
      is_watchable = null,
      has_free_content,
      payment_type,
      type,
      can_preview,
      is_premium,
      min_sub_tier,
      show_info,
      prefetchRelatedContent = {},
      title,
    } = this;
    const { show } = prefetchRelatedContent;

    const showInstance = show ?? show_info;
    const paymentTypeMerged = showInstance?.payment_type ?? payment_type;

    const isEpisodeInSeason = type === EPISODE_TYPE && showInstance;

    const validateWithSVOD =
      [SVOD_PAYMENT_TYPE, HYBRID_PAYMENT_TYPE].includes(paymentTypeMerged) ||
      (!payment_type && min_sub_tier);

    const validateWithTVOD =
      [TVOD_PAYMENT_TYPE, HYBRID_PAYMENT_TYPE].includes(paymentTypeMerged) || isEpisodeInSeason;

    const isWatchableMerged = is_watchable || can_preview;

    const watchableDefault = new Set([
      type === TRAILER_TYPE || is_premium === false,
      inListing && type === SHOW_TYPE && has_free_content,
      isWatchableMerged === true,
    ]);

    if (watchableDefault.has(true)) {
      return true;
    }

    let tvodWatchable = false;

    let svodWatcbable = false;

    if (validateWithSVOD) {
      svodWatcbable = canWatchSvod.call(this, { inListing, account });
    }
    if (validateWithTVOD && !svodWatcbable) {
      tvodWatchable = canWatchTvod.call(this, { inListing, paidContents });
    }
    const watchable = tvodWatchable || svodWatcbable;

    return watchable;
  } catch (error) {}
  return false;
}

export function needUpgrade({ account = null, inListing = true, paidContents = [] } = {}) {
  if (!this) {
    return false;
  }
  return !canWatch.call(this, { account, inListing, paidContents });
}

export function checkBadge() {
  try {
    const { account, entityDetail, paidContents } = this;

    if (needUpgrade.call(entityDetail, { account, paidContents })) {
      return renderBadgeContent.call(entityDetail);
    }

    return '';
  } catch (err) {
    return '';
  }
}

export function renderBadge() {
  try {
    const { account, entityDetail } = this;
    if (!account) {
      return renderBadgeContent.call(entityDetail);
    }
    return checkBadge.call(this);
  } catch (error) {}
}

export function getDrmLinkPlay() {
  const self = this;

  try {
    const {
      link_play,
      play_info = {},
      drm_session_info,
      type,
      current_live_cursor,
      contentFullyData,
    } = self;

    const { dash_link_play, hls_link_play } = play_info.data || {};
    if (current_live_cursor?.link_play) {
      return current_live_cursor?.link_play;
    }
    if (_.isEmpty(drm_session_info)) {
      return current_live_cursor?.link_play || link_play;
    }

    const { provider } = drm_session_info;
    if (provider === 'qnet' && !isHLS()) {
      return dash_link_play;
    }

    return hls_link_play;
    // return 'https://devstreaming-cdn.apple.com/videos/streaming/examples/bipbop_adv_example_hevc/master.m3u8';
    // return 'https://e11.endpoint.cdn.sctvonline.vn/sctv3/master.m3u8';
  } catch (error) {}
  return '';
}

export function isHLS() {
  const { IS_ANY_SAFARI, IS_IOS, IS_IPAD } = videojs.browser;
  const hlsSupport = [IS_ANY_SAFARI, IS_IOS, IS_IPAD];
  return hlsSupport.includes(true);
}

export function formatUnitOfferCount() {
  const { unit_count = 0, offer_unit_count = 0, text_unit } = this;
  try {
    return `${unit_count >= 1 ? `${unit_count} ` : ''}${text_unit}${
      offer_unit_count > 1 ? ` + ${offer_unit_count} ${text_unit}` : ''
    }`;
  } catch (error) {
    return '';
  }
}

export function getAgeContentRating() {
  const limitAge = 0;
  try {
    const {
      metadata: { content_ratings },
    } = this;
    const ageRating = (content_ratings || []).filter(
      rating => rating.type === AGE_RATING && (rating?.min_age ?? -1) >= limitAge,
    );
    return ageRating.length > 0 ? ageRating[0] : null;
  } catch (error) {
    return null;
  }
}

export function getContentRatingList() {
  const limitAge = 0;
  try {
    const {
      metadata: { content_ratings },
    } = this;
    const contentRatings = (content_ratings || []).filter(rating => rating.type === CONTENT_RATING);
    return contentRatings;
  } catch (error) {
    return [];
  }
}

export function getProgressHasCache(progressCache) {
  const { progress, id } = this;

  try {
    const progressCacheById = progressCache[id];

    const { value, expireTime } = progressCacheById || {};
    if ((value || 0) > 0 && expireTime > new Date().getTime()) {
      return value;
    }
    return progress;
  } catch (error) {
    return progress;
  }
}

export function isDVRRewatchEnabled() {
  try {
    return !!this?.license_period?.dvr_rewatch_enabled;
  } catch (error) {}
  return false;
}

export function isBeforeLiveEvent(nowMomentInstance = null) {
  try {
    const { start_on } = this;
    const now = nowMomentInstance || moment();
    return now.isBefore(moment(start_on));
  } catch (error) {
    return false;
  }
}

export function isEndLiveEvent(nowMomentInstance = null) {
  try {
    const { start_on, duration, unpublished_time } = this;
    const now = nowMomentInstance || moment();

    return (
      now.isAfter(moment(unpublished_time)) || now.isAfter(moment(start_on).add(duration, 'second'))
    );
  } catch (error) {
    return false;
  }
}

export function activeLiveEvent(nowMomentInstance = null) {
  try {
    const now = nowMomentInstance || moment();

    return !(isBeforeLiveEvent.call(this, now) || isEndLiveEvent.call(this, now));
  } catch (error) {
    return false;
  }
}

export function getCategoryName() {
  try {
    const { content_categories } = this;
    if (content_categories.length > 0) {
      return content_categories[0].name;
    }
    return '';
  } catch (error) {
    return '';
  }
}

export function getTrailers() {
  const self = this;

  try {
    return self.trailers || [];
  } catch (error) {}
  return [];
}

export function getFirstTrailer() {
  const self = this;

  try {
    const trailers = getTrailers.call(self);
    return trailers[0];
  } catch (error) {}
  return null;
}

export function getTypeString() {
  try {
    return CONTENT_TYPE_STRING_MAP.get(this?.type) ?? '';
  } catch (error) {
    return '';
  }
}

export function getDefaultTypeImage() {
  return SUPPORT_FORMAT_WEBP ? 'webp' : 'jpeg';
}

export function addParamsLinkImage(linkImage, params = {}) {
  const defaultParams = { type: getDefaultTypeImage() };
  try {
    const url = new URL(linkImage);
    const oldSearchParams = qs.parse(url.search);
    const newSearchParams = { ...oldSearchParams, ...defaultParams };
    url.search = qs.stringify(newSearchParams);
    return url.href;
  } catch (error) {}
  return linkImage;
}
export function getBanner19_6() {
  const self = this;

  try {
    const { banner_19_6_ratio, banner, backdrop, thumbnail_9_5_ratio, thumbnail } =
      self.images || {};

    const result = banner_19_6_ratio || banner || backdrop || thumbnail_9_5_ratio || thumbnail;
    return addParamsLinkImage(result, {
      width: getImageWidth(getResolutionScaleDevice(), IMAGE_BANNER_TYPE),
    });
  } catch (error) {
    return '';
  }
}

export function getThumbnail9_5() {
  const self = this;

  try {
    const { thumbnail_9_5_ratio, thumbnail } = self.images || {};
    const result = thumbnail_9_5_ratio || thumbnail || '';
    return addParamsLinkImage(result, {
      width: getImageWidth(getResolutionScaleDevice(), IMAGE_THUMBNAIL_TYPE),
    });
  } catch (error) {
    return '';
  }
}

export function getBackdrop() {
  const self = this;

  try {
    const { backdrop, banner_19_6_ratio, banner } = self.images || {};
    const result = backdrop || banner_19_6_ratio || banner || '';
    return addParamsLinkImage(result, {
      width: getImageWidth(getResolutionScaleDevice(), IMAGE_BACKDROP_TYPE),
    });
  } catch (error) {
    return '';
  }
}

export function getPoster() {
  const self = this;

  try {
    const { poster, banner, banner_19_6_ratio } = self.images || {};
    const result = poster || banner_19_6_ratio || banner || '';
    return addParamsLinkImage(result, {
      width: getImageWidth(getResolutionScaleDevice(), IMAGE_POSTER_TYPE),
    });
  } catch (error) {
    return '';
  }
}

export function isTVOD() {
  try {
    return this?.payment_type === PaymentTypeEnum.TVOD && this?.price;
  } catch (error) {
    return false;
  }
}

export function isSVOD() {
  try {
    return this?.payment_type === PaymentTypeEnum.SVOD;
  } catch (error) {
    return false;
  }
}

export function isHybrid() {
  try {
    return this?.payment_type === PaymentTypeEnum.HYBRID;
  } catch (error) {
    return false;
  }
}

export function hasVideoAds() {
  try {
    return !!this?.videoAds?.ad_tag;
  } catch (error) {
    return false;
  }
}

export function isYoutubeSource() {
  try {
    return this?.video_source === CONTENT_YOUTUBE_SOURCE_TYPE;
  } catch (error) {
    return false;
  }
}

//PrefetchRelatedContent
export function getPrefetchRelatedContent() {
  try {
    return this?.prefetchRelatedContent;
  } catch (error) {
    return null;
  }
}

export function getFirstSeason() {
  try {
    const { seasons = [] } = this;
    return seasons?.[0];
  } catch (error) {
    return null;
  }
}

export function getTrailerAndEpisodeMerged() {
  try {
    const { trailers = [], prefetchRelatedContent } = this;
    const { season } = prefetchRelatedContent;
    const { episodes = [] } = season;
    return [...trailers, ...episodes];
  } catch (error) {
    return [];
  }
}

export function getFirstTrailerOrEpisode() {
  try {
    return getTrailerAndEpisodeMerged.call(this)?.[0];
  } catch (error) {
    return null;
  }
}

export function isShowOrSeason() {
  try {
    return [SHOW_TYPE, SEASON_TYPE].includes(this?.type);
  } catch (error) {
    return false;
  }
}

export function getPlayingContent() {
  return this?.prefetchRelatedContent?.trailer ?? this;
}
//PrefetchRelatedContent

export function getPrefixFilm() {
  try {
    const { type } = this;
    if (ALL_LIVE_TYPE.includes(type)) {
      return 'kênh';
    }
    return type === SHOW_TYPE ? 'phim bộ' : 'phim';
  } catch (error) {
    return '';
  }
}
export function getLandingPath() {
  try {
    const { type, slug } = this;
    let path = routePageNames.contentLandingBySlug;
    if (TYPE_NO_LANDING_PAGE.includes(type)) {
      path = routePageNames.contentDetailBySlug;
    }
    return pathToUrl(path, { slug });
  } catch (error) {}
}

export function hasTextTrackSources() {
  return this?.type !== CHANNEL_TYPE && !isEmpty(this?.subtitles);
}
