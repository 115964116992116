import windowOrGlobal from 'global';

if (!windowOrGlobal.gtmVar) {
  windowOrGlobal.gtmVar = {};
}

export const gtmVar_setIsLoggedIn = value => {
  windowOrGlobal.gtmVar.isLoggedIn = value;
};

export const gtmVar_setHasSubscription = value => {
  windowOrGlobal.gtmVar.hasSubscription = value;
};
