import React, { useEffect } from 'react';
import { SignInSignUpPopup } from './components/SignInSignUpPopup/SignInSignUpPopup';
import { useSelector } from 'react-redux';
import { accountSelector } from '../auth/reducers';
import { useAppDispatch } from '@/core/hook/appHook';
import { closeLoginDialog } from './authenticationSlice';
import { VerifyTermOfUse } from './components/VerifyTermOfUse/VerifyTermOfUse';

type Props = {};

export const Authentication = (props: Props) => {
  const dispatch = useAppDispatch();
  const account = useSelector(accountSelector);

  useEffect(() => {
    if (account) {
      dispatch(closeLoginDialog());
    }
  }, [account, dispatch]);
  return (
    <>
      <SignInSignUpPopup />
      <VerifyTermOfUse />
    </>
  );
};
