import { TVODPrice } from '@/core/models/Model';
import { RootState } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ITVODPrice {
  isOpenCheckout: boolean;
  tvodPrice: null | TVODPrice;
  paymentId?: string;
}

const initialState: ITVODPrice = { isOpenCheckout: false, tvodPrice: null };
export const tvodSlice = createSlice({
  name: 'tvod',
  initialState,

  reducers: {
    openCheckout: state => {
      state.isOpenCheckout = true;
    },

    closeCheckout: state => {
      state.isOpenCheckout = false;
    },
    setIsOpenCheckout: (state, { payload }: PayloadAction<ITVODPrice['isOpenCheckout']>) => {
      if (state.isOpenCheckout === payload) {
        return state;
      }
      state.isOpenCheckout = payload;
    },
    setTvodPrice: (state, { payload }: PayloadAction<ITVODPrice['tvodPrice']>) => {
      state.tvodPrice = payload;
    },
    setPaymentId: (state, { payload }: PayloadAction<ITVODPrice['paymentId']>) => {
      state.paymentId = payload;
    },
    resetTvod: () => {
      return initialState;
    },
  },
});

export const {
  openCheckout,
  closeCheckout,
  setIsOpenCheckout,
  setTvodPrice,
  setPaymentId,
  resetTvod,
} = tvodSlice.actions;

const tvodReducer = tvodSlice.reducer;
export const selectIsOpenCheckout = (state: RootState) => state.tvod.isOpenCheckout;
export const selectTvodPrice = (state: RootState) => state.tvod.tvodPrice;
export const selectpaymentId = (state: RootState) => state.tvod.paymentId;
export { tvodReducer };
