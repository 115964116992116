import { useAppDispatch, useAppSelector } from '@/core/hook/appHook';
import { BaseDialog } from '@/shared/components/diglog/BaseDialog';
import React, { useEffect, useMemo, useState } from 'react';
import { useVerifyTermOfUse } from './useVerifyTermOfUse';
import { Paper, Tabs, Tab, Box, FormControl, FormControlLabel } from '@material-ui/core';
import tw, { styled, theme } from 'twin.macro';
import { parseInt } from 'lodash';
import { setPrivacyAndPolicy, setTermsAndConditions } from '@/components/global/globalServices';
import {
  privacyAndPolicySelector,
  termsAndConditionsSelector,
} from '@/components/global/globalSlice';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form } from '@/shared/components/form/Form';
import { Controller } from 'react-hook-form';
import { getConfigByKey } from '@/features/tenantConfig/services';
import Checkbox from '@/components/Input/CheckBox';

type Props = {};

const schema = yup.object({
  agree: yup
    .boolean()
    .required()
    .test({
      name: 'email',
      test: (agreeValue: boolean) => agreeValue === true,
      message: 'Vui lòng chọn đồng ý',
    }),
});
type FormFields = yup.InferType<typeof schema>;
const initValues: FormFields = {
  agree: false,
};
const VerifyTermOfUseStyled = styled(Box)`
  .MuiTabs-indicator {
    display: none;
  }
  .MuiTabs-flexContainer {
    column-gap: 10px;
    min-height: 0;
  }

  .MuiTab-root {
    text-transform: capitalize;
    ${tw`font-open-sans`};
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 100%;
    color: #333;
    min-height: 30px;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #e4e4e4;
    border: 1px solid #333333;
    min-height: 30px;
    min-width: 150px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    zoom: calc(1 / var(--scaling));
    box-sizing: border-box;
    max-width: initial;
    &.Mui-selected {
      color: #fff;
      background-color: #2e9af2;
      border-color: #2e9af2;
    }
    @media (min-width: ${theme('screens.md')}) {
      font-size: 18px;
      min-width: 240px;
      padding: 8px 23px;
    }
  }
  .tab-panels {
    max-height: calc(100vh - 280px);
    overflow-y: auto;
    margin-right: -5px;
    padding-right: 5px;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
      border-radius: 10px;
    }
    @media (min-width: ${theme('screens.md')}) {
      max-height: calc(100vh - 200px);
      margin-right: -10px;
      padding-right: 10px;
      &::-webkit-scrollbar {
        width: 10px;
      }
    }
  }
  .inputs {
    .MuiFormControlLabel-root {
      margin-left: 0;
      align-items: flex-start;
    }
    a {
      text-decoration: underline;
    }
    a,
    .MuiFormControlLabel-label {
      font-size: 12px;
      color: #555555;
    }
    .MuiCheckbox-root {
      padding-top: 0;
      padding-left: 0;
    }
    @media (min-width: ${theme('screens.md')}) {
      .MuiFormControlLabel-root {
        margin: 0;
      }
    }
  }
  .actions__wrap {
    margin-top: 10px;
    padding-block: 18px;
    border-top: 1px solid #dddddd;
    .submit-button {
      font-size: 16px;
      padding: 13px 90px;
      width: auto;
      min-width: 250px;
      &:disabled {
        color: #5e5e5e;
        background-color: #c9c9c9;
      }
    }
    @media (min-width: ${theme('screens.md')}) {
      padding-bottom: 0;
    }
  }
  .form {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
  }
  .actions {
    margin-bottom: 0;
    text-align: center;
    @media (min-width: ${theme('screens.md')}) {
      margin-top: 0;
    }
  }
`;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
export const VerifyTermOfUse = (props: Props) => {
  const privacyPolicy = useSelector(privacyAndPolicySelector);
  const termOfUse = useSelector(termsAndConditionsSelector);
  const dispatch = useAppDispatch();
  const [systemMessage, setSystemMessage] = useState('');

  const { updateTermOfUse, profile } = useVerifyTermOfUse();
  const verifiedTermOfUse = useMemo(
    () => profile?.accept_term_of_use,
    [profile?.accept_term_of_use],
  );

  const isOpen = useMemo(() => {
    return verifiedTermOfUse === false && !!privacyPolicy?.content && !!termOfUse?.content;
  }, [verifiedTermOfUse, privacyPolicy, termOfUse]);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(parseInt(newValue));
  };

  const handleOnSubmit = ({ agree }: FormFields) => {
    return updateTermOfUse(agree).catch(({ message }) => message && setSystemMessage(message));
  };
  useEffect(() => {
    setPrivacyAndPolicy()(dispatch);
    setTermsAndConditions()(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseDialog
      clickOutsize={false}
      open={isOpen}
      onClose={() => {}}
      hasCloseIcon={false}
      size="large"
      contentClassName="px-[10px] py-[20px] md:px-[50px] md:pt-[27px] md:!pb-[20px]"
      paperClassName="lg:!max-w-[1298px]"
    >
      <VerifyTermOfUseStyled className="verify-term-of-use">
        <Tabs value={value} onChange={handleChange} className="">
          <Tab label="Chính sách bảo mật" />
          <Tab label="Điều khoản sử dụng dịch vụ" />
        </Tabs>
        <div className="tab-panels">
          <TabPanel value={value} index={0}>
            <div dangerouslySetInnerHTML={{ __html: privacyPolicy?.content ?? null }} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div dangerouslySetInnerHTML={{ __html: termOfUse?.content ?? null }} />
          </TabPanel>
        </div>
        <Box className="actions__wrap">
          <Form<FormFields>
            onSubmit={handleOnSubmit}
            submitLabel="Xác nhận"
            initialForm={{
              defaultValues: initValues,
              resolver: yupResolver(schema) as any,
            }}
            titleForm=""
            titleFormProps={{ variant: 'short', className: 'mb-[43px] md:mb-[28px]' }}
          >
            {({ control, formState: { errors } }) => (
              <>
                <Controller
                  control={control}
                  name="agree"
                  render={({ field }) => (
                    <FormControl className="">
                      <FormControlLabel
                        className="agree-start"
                        label={
                          <span className="link">
                            Tôi đã đọc và đồng ý với{' '}
                            <a
                              target="_blank"
                              href={getConfigByKey('privacyPolicyLink')}
                              rel="noreferrer"
                            >
                              Chính sách bảo mật
                            </a>{' '}
                            và{' '}
                            <a
                              target="_blank"
                              href={getConfigByKey('termsOfUseLink')}
                              rel="noreferrer"
                            >
                              Điều khoản sử dụng dịch vụ
                            </a>
                          </span>
                        }
                        control={<Checkbox {...field}></Checkbox>}
                      />
                    </FormControl>
                  )}
                />
              </>
            )}
          </Form>
        </Box>
      </VerifyTermOfUseStyled>
    </BaseDialog>
  );
};
