import { forwardRef } from 'react';
import { useButton, UseButtonProps } from './useButton';
import { twMerge } from 'tailwind-merge';

export type ButtonProps = UseButtonProps & {
  className?: string;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { className = '' } = props;
  const { styles, children, getButtonProps } = useButton({ ...props, ref });
  return (
    <button className={twMerge(styles, className)} {...getButtonProps()}>
      {children}
    </button>
  );
});
