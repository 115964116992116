import React from 'react';
import { Oval, OvalProps } from 'react-loader-spinner';

type Props = OvalProps & {
  /**
   * @deprecated This prop is deprecated.
   */
  type?: string;
};

export const Loader = (props: Props) => {
  return (
    <Oval
      visible={true}
      color="#FFFFFF"
      height="30"
      width="30"
      ariaLabel="oval-loading"
      wrapperStyle={{}}
      wrapperClass=""
      {...props}
    />
  );
};
