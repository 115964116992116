import { isNode } from '@/shared/utils/ssr';

function supportFormatWebp(feature, callback) {
  if (isNode()) {
    return callback(feature, false);
  }
  var kTestImages = {
    lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
    lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
    alpha:
      'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
    animation:
      'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
  };
  var img = new Image();
  img.onload = function () {
    var result = img.width > 0 && img.height > 0;
    callback(feature, result);
  };
  img.onerror = function () {
    callback(feature, false);
  };
  img.src = 'data:image/webp;base64,' + kTestImages[feature];
}

export const RESOLUTION_SCALE_HD = /* @mangle */ 'web@hd' /* @/mangle */;
export const RESOLUTION_SCALE_SD = /* @mangle */ 'web@sd' /* @/mangle */;
export const RESOLUTION_SCALE_FHD = /* @mangle */ 'web@fhd' /* @/mangle */;
export const RESOLUTION_SCALE_QHD = /* @mangle */ 'web@qhd' /* @/mangle */;

export const IMAGE_THUMBNAIL_TYPE = /* @mangle */ 'thumbnail' /* @/mangle */;
export const IMAGE_POSTER_TYPE = /* @mangle */ 'poster' /* @/mangle */;
export const IMAGE_BANNER_TYPE = /* @mangle */ 'banner' /* @/mangle */;
export const IMAGE_BACKDROP_TYPE = /* @mangle */ 'backdrop' /* @/mangle */;

export const IMAGE_TYPE_WIDTH_MATRIX = [
  [
    null, // 0
    RESOLUTION_SCALE_SD, // 1
    RESOLUTION_SCALE_HD, // 2
    RESOLUTION_SCALE_FHD, // 3
    RESOLUTION_SCALE_QHD, // 4
  ],
  [IMAGE_THUMBNAIL_TYPE, 384, 384, 1280, 1280],
  [IMAGE_POSTER_TYPE, 384, 384, 1280, 1280],
  [IMAGE_BANNER_TYPE, 384, 384, 1920, 1920],
  [IMAGE_BACKDROP_TYPE, 427, 427, 2560, 2560],
];

export let SUPPORT_FORMAT_WEBP = false;

supportFormatWebp('lossy', (feature, isSupported) => (SUPPORT_FORMAT_WEBP = isSupported));
