import thunkMiddleware from 'redux-thunk';
import { persistStore } from 'redux-persist';

import { DEBUG, VERSION } from '@/constants/envConfig';

import createRootReducer, { TRootReducer } from './rootReducer';
import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import createHistory from '@/shared/store/history';
import { createStateSyncMiddleware } from 'redux-state-sync';
import { Dispatch } from 'redux';

let store = null;
let persistor = null;

const history = createHistory();

type StoreParams = {
  initialState?: { [key: string]: any };
  middleware?: any[];
};

const loggerMiddleware = createLogger({
  predicate: (getState, action) => (__SERVER__ ? false : DEBUG),
});

const configStateSync = {
  whitelist: [],
};
export const storeFactory = ({ initialState, middlewares = [] }: StoreParams) => {
  if (store) {
    return {
      store,
      persistor,
    };
  }
  const mergedMiddlewares = [thunkMiddleware, loggerMiddleware];
  if (__BROWSER__) {
    mergedMiddlewares.push(createStateSyncMiddleware(configStateSync));
  }
  mergedMiddlewares.concat(middlewares);
  store = configureStore({
    reducer: createRootReducer(),
    devTools: __SERVER__ ? false : DEBUG,
    middleware: () => mergedMiddlewares,
    preloadedState: __SERVER__ ? initialState : undefined,
  });

  persistor = __BROWSER__ ? persistStore(store, initialState) : null;
  // persistor = persistStore(store, initialState);
  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./rootReducer', () =>
        store.replaceReducer(require('./rootReducer').default),
      );
    }
  }

  return { store, persistor };
};
export { history, store, persistor };
export type RootState = TRootReducer;
export type AppDispatch = Dispatch<RootState>;
