import { tenantEnvConfig } from './tenantEnvConfig';
import { REACT_APP_ENV } from '@/constants/apiString';

const featureFlagsByTenantSlug = {
  // Default for other tenants
  default: {
    subscriptionFeature: false,
    bottom_bar_information: false,
    deep_link: false,
  },

  odv: {
    subscriptionFeature: true,
    firebaseFeature: true,
    gtmFeature: true,
    onesignal: true,
    cookielaw: true,
    invite_referrals: true,
    bottom_bar_information: true,
    deep_link: true,
    notify_player: true,
    payment_card_feature: true,
    coupon_feature: true,
    vertical_ribbon: true,
    ppvPaymentPaypalFeature: true,
    ppvPaymentStripFeature: true,
    ppvPaymentBootpayFeature: false,
    deviceOptionsFeature: true,
    tvodFeature: true,
    signInFacebookFeature: true,
  },

  sctv: {
    rewatchChannelFeature: true,
    subscriptionFeature: true,
    firebaseFeature: true,
    bottom_bar_information: true,
    deep_link: true,
    notify_player: false,
    payment_sms_feature: false,
    payment_method_feature: true,
    vertical_ribbon: false,
    loginPhoneNumber: true,
    svodAppFeature: false,
    deviceOptionsFeature: false,
    tvodFeature: false,
    pgMomoFeature: true,
    pgVNPayFeature: false,
    // pgVTCPayFeature: !['PROD', 'RC'].includes(REACT_APP_ENV),
    pgVTCPayFeature: true,
    signInFacebookFeature: false,
    pgBankTransferFeature: true,
    multiTierSubscriptionEnabled: true,
    requestSignInFeature: false,
    displayBadgesBannerFeature: true,
    displayDescriptionBannerFeature: true,
    displayFooterContentOfContinueWatchingFeature: false,
  },
  projectw: {
    subscriptionFeature: false,
    bottom_bar_information: true,
    deep_link: false,
    notify_player: false,
    vertical_ribbon: false,
    ppvPaymentBootpayFeature: true,
    tvodFeature: true,
    signInFacebookFeature: false,
  },
};

export const tenantFeatureFlags =
  tenantEnvConfig.tenant_slug in featureFlagsByTenantSlug
    ? featureFlagsByTenantSlug[tenantEnvConfig.tenant_slug]
    : featureFlagsByTenantSlug['default'];
