import React, { ReactNode, useState } from 'react';

import { SwitchFormButton } from './SwitchFormButton';
import clsx from 'clsx';

type SwitchTwoFormProps = {
  forms: {
    labelActionForm: string;
    formComponent: ReactNode;
    helperText?: string;
    key: string;
  }[];
  onSwitchForm?: (key: string) => void;
  defaultKey?: string;
};

export const SwitchTwoForm = ({ forms, onSwitchForm, defaultKey = '' }: SwitchTwoFormProps) => {
  const [defaultForm, otherForm] = forms;
  const [switchCurrentForm, setSwitchCurrentForm] = useState(defaultKey || defaultForm?.key);

  const handleSwitchForm = (key: string) => {
    setSwitchCurrentForm(key);

    onSwitchForm && onSwitchForm(key);
  };

  const renderForm = (currentForm: string) => {
    switch (currentForm) {
      case defaultForm?.key:
        return (
          <>
            {defaultForm?.formComponent}
            <SwitchFormButton
              handleOnClick={() => handleSwitchForm(otherForm.key)}
              actionLabel={defaultForm?.labelActionForm}
              label={defaultForm?.helperText}
            />
          </>
        );

      default:
        return (
          <>
            {otherForm?.formComponent}
            <SwitchFormButton
              handleOnClick={() => handleSwitchForm(defaultForm.key)}
              actionLabel={otherForm?.labelActionForm}
              label={otherForm?.helperText}
            />
          </>
        );
    }
  };

  return (
    <>
      {renderForm(switchCurrentForm)}
      <div
        className={clsx({
          'md:pb-[32px]': switchCurrentForm === 'sign-in',
        })}
      ></div>
    </>
  );
};
