import FETCH from '../utils/security/fetch';
import * as APIString from '../constants/apiString';
import queryString from 'query-string';
import { appConfig } from '@/shared/common/config';

export function getDocumentByType(params) {
  const select = JSON.stringify({
    Document: ['id', 'content'],
  });
  const queryParams = queryString.stringify({ select });
  const { type } = params;

  const path = `/tenants/${APIString.TENANT_SLUG}/tenant_platforms/${APIString.ROOT_PLATFORM_MENU_ID}/documents/by_type/${type}?${queryParams}`;

  return FETCH(path, 'GET', {
    'Content-Type': 'application/json',
  });
}

export function getDocumentInformationById(documentID) {
  const { tenantSlug, platformSlug } = appConfig;

  const path = `/tenants/${tenantSlug}/tenant_platforms/${platformSlug}/documents/${documentID}/`;

  return FETCH(path, 'GET');
}
