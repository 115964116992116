import { Dialog, DialogContent, IconButton, DialogProps } from '@material-ui/core';
import { PropsWithChildren, useEffect } from 'react';
// import CloseIcon from '@material-ui/icons/Close';
import { tv, VariantProps } from 'tailwind-variants';
import { twMerge } from 'tailwind-merge';
import CloseIcon from '@/assets/icons/close-icon.svg';

import { styled } from 'twin.macro';

export type TBaseDialogProps = PropsWithChildren<{
  open: boolean;
  onClose: (event?: any, reason?: string) => void;
  contentClassName?: string;
  clickOutsize?: boolean;
  hasCloseIcon?: boolean;
  paperClassName?: string;
}> &
  VariantProps<typeof baseDialogTv> &
  Pick<DialogProps, 'fullScreen'>;

const notFullscreen = (fullString: TemplateStringsArray) => {
  return `[&:not(.MuiDialog-paperFullScreen)]:${fullString.join()}]`;
};

const baseDialogTv = tv({
  base: `my-0 box-content w-full max-w-full rounded-[10px] border-[2px] border-solid border-[#636363] [&.MuiDialog-paperFullScreen]:rounded-none [&.MuiDialog-paperFullScreen]:border-none [&.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody]:max-w-[calc(100%-26px)] [&:not(.MuiDialog-paperFullScreen)]:mx-[0px] [&:not(.MuiDialog-paperFullScreen)_.MuiDialogContent-root]:md:pb-[47px]`,
  variants: {
    size: {
      small: '[&:not(.MuiDialog-paperFullScreen)]:md:w-[480px]',
      normal: '[&:not(.MuiDialog-paperFullScreen)]:md:w-[min(100%,650px)]',
      large: '[&:not(.MuiDialog-paperFullScreen)]:md:w-[min(100%,1370px)]',
      fullScreen: '',
    },
  },

  defaultVariants: {
    size: 'normal',
  },
});

const dialogContentTv = tv({
  base: `px-[15px] pb-[62px] pt-0`,
  variants: {
    size: {
      small: `md:px-[28px]`,
      normal: `md:px-[40px]`,
      large: `md:px-[60px]`,
      fullScreen: 'p-0 [&:first-child]:pt-0',
    },
  },
  defaultVariants: {
    size: 'normal',
  },
});

const IconButtonStyled = styled(IconButton)`
  position: relative;
  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    width: min(200%, 40px);
    height: min(200%, 40px);
    inset: auto;
    border-radius: 50%;
    transition: all 0.25s;
  }
  &:hover:before {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const BaseDialog = (props: TBaseDialogProps) => {
  const {
    open,
    onClose,
    children,
    size,
    fullScreen,
    contentClassName = '',
    clickOutsize = true,
    hasCloseIcon = true,
    paperClassName = '',
    ...restProps
  } = props;

  const handleOnClose = (event: any, reason?: string) => {
    if (!clickOutsize && reason) {
      return;
    }
    onClose(event, reason);
  };

  useEffect(() => {
    const handleEvent = () => {
      onClose();
    };
    window.addEventListener('popstate', handleEvent);
    return () => window.removeEventListener('popstate', handleEvent);
  }, []);
  return (
    <Dialog
      onClose={handleOnClose}
      open={open}
      fullScreen={fullScreen}
      scroll={'body'}
      classes={{
        scrollPaper: 'bg-gradient-to-b from-[rgba(0,0,0,0.1)] to-[rgba(0,0,0,0.8)]',
        paper: twMerge(baseDialogTv({ size: fullScreen ? 'fullScreen' : size }), paperClassName),
      }}
      {...restProps}
    >
      {!fullScreen && hasCloseIcon && (
        <div className="text-right">
          <IconButtonStyled
            className="ml-[auto] mr-[22px] mt-[21px] inline-flex h-[18px] w-[18px] items-center p-0 hover:bg-[transparent] "
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon className="text-[31px]  text-[#CFCFCF]" />
          </IconButtonStyled>
        </div>
      )}

      <DialogContent
        classes={{
          root: twMerge(
            dialogContentTv({ size: fullScreen ? 'fullScreen' : size }),
            contentClassName,
          ),
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};
