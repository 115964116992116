import i18n from 'i18next';

import { getConfigByKey } from '@/features/tenantConfig/services';
import { getLocales } from '@/utils/locales';

export const AVAILABEL_LOCALES = getLocales();
export const DEFAULT_LOCALE =
  getConfigByKey('default_locale') || (getConfigByKey('tenant_slug') === 'projectw' ? 'ko' : 'en');

export default i18n;
