const env = process.env;

export const VERSION = env.REACT_APP_VERSION || '2.16.51';
export const AVOD_REGISTRATION_FEATURE_ENABLED =
  process.env.REACT_APP_AVOD_REGISTRATION_FEATURE_ENABLED === 'true';
export const DEBUG = process.env.REACT_APP_DEBUG === 'true' || false;

export const VERSION_PRELEASE = process.env.REACT_APP_VERSION_PRERELEASE || '';
export const REACT_APP_SSR = process.env.REACT_APP_SSR === 'true';
export const REACT_APP_BOOTPAY_APPLICATION_ID = process.env.REACT_APP_BOOTPAY_APPLICATION_ID || '';
export const BASE_DOMAIN = process.env.REACT_APP_BASE_DOMAIN || '';
export const VIMAI_API_PROTECTION_V1_CONFIG_ID =
  process.env.VIMAI_API_PROTECTION_V1_CONFIG_ID || '';
