import { requestCodeOtp } from '@/shared/features/auth/services';
import { useDispatch } from 'react-redux';

export type TUseForgotPasswordFormProps = {};

export const useForgotPasswordFormProps = () => {
  const dispatch = useDispatch();
  const handleRequestResetPassword = ({
    email,
    resetPasswordUrl,
    phoneNumber,
  }: {
    email?: string;
    resetPasswordUrl: string;
    phoneNumber?: string;
  }) => {
    return requestCodeOtp(email, resetPasswordUrl, phoneNumber)(dispatch);
  };
  return {
    handleRequestResetPassword,
  };
};
