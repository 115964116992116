import FETCH from '../utils/security/fetch';
import * as APIString from '../constants/apiString';
import { store } from '@/store/store';
import queryString from 'query-string';
import * as Version from './../constants/envConfig';
import { getDeviceResolution } from '@/shared/utils/utils';
import { getConfigByKey } from '@/shared/features/tenantConfig/services';
import * as plan from './plan';
import { appConfig } from '@/shared/common/config';

export {
  get_menu,
  get_page,
  get_ribbon,
  get_page_menu,
  get_detail,
  get_season,
  get_relate,
  get_epg,
  get_clip_hay,
  get_all_channel,
  get_search,
  login_email,
  loginPhoneNumber,
  requestCode,
  resetPassword,
  register_email,
  changePassword,
  getProfile,
  fbLogin,
  updateProfile,
  get_payment_plan,
  getClientSecret,
  refresh_stb_auth_code,
  paymentPlan,
  cancel_subscription_plan,
  get_actived_subscription_plan,
  register_or_login,
  subscription_history,
  paymentPlatTotol,
  post_progress,
  get_progress,
  change_email,
  resend_email,
  supports,
  confirmEmail,
  getAdsBanner,
  updateAccountPaymentMethod,
  getAdsVideo,
  getAccountPaymentMethod,
  postFavorite,
  getFavorite,
  registerEmail,
  allowedCountries,
  reportTemplates,
  postReport,
  uploadImage,
  getAccountInfo,
  subscriptionHistoryV2,
  getConfigFireBase,
  register_emaill_SCTV,
};

const SEARCH_PAGE_SELECT_FIELDS = ['items', 'keyword', 'suggested_items'];

const SEARCH_PAGE_CONTENT_ITEMS_SELECT_FIELDS = [
  'can_preview',
  'content_categories',
  'has_free_content',
  'id',
  'images',
  'is_new_release',
  'is_premium',
  'is_watchable',
  'num_first_episode_preview',
  'slug',
  'title',
  'type',
  'video_source',
  'min_sub_tier',
  'metadata',
  'start_on',
  'duration',
  'unpublished_time',
  'badges',
  'payment_type',
];

const CONTENT_FAVORITE_PAGE_SELECT_FIELDS = [
  'id',
  'slug',
  'title',
  'images',
  'is_new_release',
  'is_premium',
  'is_watchable',
  'youtube_video_id',
  'category_slug',
  'min_sub_tier',
  'metadata',
];

function get_menu() {
  const path = `/backend/cm/menu/${APIString.ROOT_PLATFORM_MENU_ID}/`;

  return FETCH(path, 'GET', {});
}

function get_page(randomString, slug) {
  const { account } = store.getState().auth;
  let Authorization = '';
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  const path = `/tenants/${APIString.TENANT_SLUG}/tenant_platforms/${APIString.ROOT_PLATFORM_MENU_ID}/home_page`;
  return FETCH(path, 'GET', {
    Authorization,
  });
}

function get_page_menu(id, randomString) {
  const { account } = store.getState().auth;
  let Authorization = '';
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  const path = `/backend/cm/page/${id}/?random=${randomString}`;
  return FETCH(path, 'GET', {
    Authorization,
  });
}

function get_ribbon(id, page = 0) {
  const { account } = store.getState().auth;
  let Authorization = '';
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  let params = {
    page,
  };
  const search = window.location.search;
  if (search !== '') {
    params = {
      ...params,
      ...queryString.parse(search),
    };
  }
  const queryParams = queryString.stringify(params);
  const path = `/backend/cm/ribbon/${id}/?${queryParams}`;
  return FETCH(path, 'GET', {
    Authorization,
  });
}

function get_detail(slug) {
  const { account } = store.getState().auth;
  let Authorization = '';
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  const path = `/backend/cm/detail/${slug}/`;
  return FETCH(path, 'GET', {
    Authorization,
  });
}

function get_season(season_id) {
  const { account } = store.getState().auth;
  let Authorization = '';
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  const path = `/backend/cm/season_by_id/${season_id}/`;
  return FETCH(path, 'GET', {
    Authorization,
  });
}

function get_relate(id) {
  const { account } = store.getState().auth;
  let Authorization = '';
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  const path = `/backend/cm/related/${id}/`;
  return FETCH(path, 'GET', {
    Authorization,
  });
}

function get_epg(params) {
  const path = `/backend/cm/epg/?channel_id=${params.channel_id}&schedule_date=${params.schedule_date}`;
  return FETCH(path, 'GET', {});
}

function get_clip_hay(id) {
  const path = `/backend/cm/clip_hay/${id}/`;
  return FETCH(path, 'GET', {});
}

function get_all_channel(id) {
  const path = '/backend/cm/channel/';
  return FETCH(path, 'GET', {});
}

function get_search(params) {
  const select = JSON.stringify({
    Search: SEARCH_PAGE_SELECT_FIELDS,
    Movie: SEARCH_PAGE_CONTENT_ITEMS_SELECT_FIELDS,
  });

  let data = { ...params, ...getDeviceResolution() };
  const queryParams = queryString.stringify({ ...data, select });
  const path = `/tenants/${APIString.TENANT_SLUG}/search/?q=${params.keyword}&${queryParams}`;
  const { authorization } = appConfig;
  return FETCH(path, 'GET', {
    Authorization: authorization,
  });
}

function login_email(email, password) {
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/logins/email`;

  const formData = new FormData();
  formData.append('email', email);
  formData.append('password', password);

  return FETCH(path, 'POST', {}, formData);
}

function loginPhoneNumber(username, password) {
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/user_account_login`;

  const data = {
    username,
    password,
  };

  return FETCH(
    path,
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(data),
  );
}

function requestCode(email, url, phoneNumber = null) {
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/password/forget`;
  let data = {
    email,
    reset_password_page_url: url,
  };
  if (phoneNumber) {
    data = {
      reset_password_page_url: url,
      phone_number: phoneNumber,
    };
  }

  return FETCH(
    path,
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(data),
  );
}

function resetPassword(email, otp, new_password) {
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/password/reset`;
  const formdata = new FormData();
  formdata.append('email', email);
  formdata.append('otp', otp);
  formdata.append('new_password', new_password);
  return FETCH(path, 'POST', {}, formdata);
}

function register_email(
  username,
  firstName,
  lastName,
  phoneNumber,
  password,
  gender,
  birthday,
  accept,
) {
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/register`;
  const formdata = new FormData();
  formdata.append('email', username);
  formdata.append('password', password);
  formdata.append('first_name', firstName);
  formdata.append('last_name', lastName);
  formdata.append('gender', gender);
  formdata.append('dob', birthday);
  formdata.append('phone_number', phoneNumber);
  formdata.append('g-recaptcha-response', '');
  if (accept) {
    formdata.append('accept_term_of_use', accept);
  }

  return FETCH(path, 'POST', {}, formdata);
}

function register_emaill_SCTV(params) {
  const { username, password, firstName, lastName, phoneNumber, type, promo_code, accept } = params;
  const queryParams = queryString.stringify({ register_type: 'mobi' });
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/register?${queryParams}`;
  const formdata = new FormData();
  formdata.append('password', password);
  formdata.append('first_name', firstName);
  formdata.append('last_name', lastName);
  formdata.append('phone_number', phoneNumber);
  formdata.append('g-recaptcha-response', '');
  if (accept) {
    formdata.append('accept_term_of_use', accept);
  }
  return FETCH(path, 'POST', {}, formdata);
}

function changePassword(old_password, password) {
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/password/change`;

  const { account } = store.getState().auth;
  const Authorization = account.access_token ? account.access_token : '';

  const formdata = new FormData();
  formdata.append('old_password', old_password);
  formdata.append('password', password);

  return FETCH(
    path,
    'POST',
    {
      Authorization: Authorization,
    },
    formdata,
  );
}
export function changePhoneNumber(newPhoneNumber, password) {
  const { account } = store.getState().auth;
  const Authorization = account.access_token ? account.access_token : '';
  const payload = { new_phone_number: newPhoneNumber, current_password: password };
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${account?.profile?.id}/change_phone_number`;

  return FETCH(
    path,
    'POST',
    {
      Authorization: Authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify(payload),
  );
}

function getProfile() {
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/profile`;
  const { account } = store.getState().auth;
  const Authorization = account.access_token ? account.access_token : '';
  return FETCH(path, 'GET', {
    Authorization,
  });
}

function fbLogin(token, platform, model, device_id, mac_address, push_token = '') {
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/logins/facebook`;

  const formdata = new FormData();
  formdata.append('token', token);
  formdata.append('platform', platform);
  formdata.append('model', model);
  formdata.append('device_id', device_id);
  formdata.append('mac_address', mac_address);
  formdata.append('push_token', push_token);

  return FETCH(path, 'POST', {}, formdata);
}

function updateProfile(firstName, lastName, phoneNumber, gender, birthday, acceptTermOfUse) {
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/profile`;

  const { account } = store.getState().auth;
  const Authorization = account.access_token ? account.access_token : '';
  const formdata = new FormData();

  formdata.append('first_name', firstName);
  formdata.append('last_name', lastName);
  formdata.append('gender', gender);
  formdata.append('dob', birthday);
  if (acceptTermOfUse) {
    formdata.append('accept_term_of_use', acceptTermOfUse);
  }

  if (phoneNumber) {
    formdata.append('phone_number', phoneNumber);
  }
  formdata.append('avatar', '');

  return FETCH(
    path,
    'POST',
    {
      Authorization,
    },
    formdata,
  );
}

function get_payment_plan() {
  return plan.getPlans();
}

function getClientSecret(plan_id) {
  const path = `/tenants/${APIString.TENANT_SLUG}/subscriptions/request_payment/`;

  const { account } = store.getState().auth;
  let Authorization = '';
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  const formdata = new FormData();

  formdata.append('plan_id', plan_id);

  return FETCH(
    path,
    'POST',
    {
      Authorization,
    },
    formdata,
  );
}

function refresh_stb_auth_code() {
  const path = '/backend/cas/refresh_stb_auth_code/';
  const { account } = store.getState().auth;
  const Authorization = account.access_token ? account.access_token : '';

  return FETCH(path, 'POST', {
    Authorization,
  });
}

function paymentPlan(stripe_plan_id, stripe_payment_method_id, product) {
  const path = `/tenants/${APIString.TENANT_SLUG}/stripe/subscriptions/`;

  const { account } = store.getState().auth;
  const Authorization = account.access_token ? account.access_token : '';
  const formdata = new FormData();

  formdata.append('stripe_plan_id', stripe_plan_id);
  formdata.append('stripe_payment_method_id', stripe_payment_method_id);
  const data = { stripe_plan_id, stripe_payment_method_id, product };
  if (product !== null) {
    return FETCH(
      path,
      'POST',
      {
        Authorization,
        'Content-Type': 'application/json',
      },
      JSON.stringify(data),
    );
  } else {
    return FETCH(
      path,
      'POST',
      {
        Authorization,
      },
      formdata,
    );
  }
}

function paymentPlatTotol(stripe_plan_id, product) {
  const path = `/tenants/${APIString.TENANT_SLUG}/stripe/subscriptions/?draft=true`;

  const { account } = store.getState().auth;
  const Authorization = account.access_token ? account.access_token : '';
  const data = { stripe_plan_id, product };
  return FETCH(
    path,
    'POST',
    {
      Authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify(data),
  );
}

function get_actived_subscription_plan() {
  const path = `/tenants/${APIString.TENANT_SLUG}/account/get_actived_subscription_plan/`;

  const { account } = store.getState().auth;
  const Authorization = account.access_token ? account.access_token : '';

  return FETCH(path, 'GET', {
    Authorization,
  });
}
function cancel_subscription_plan() {
  const path = `/tenants/${APIString.TENANT_SLUG}/account/cancel_current_subscription_plan/`;
  const { account } = store.getState().auth;
  const Authorization = account.access_token ? account.access_token : '';

  return FETCH(path, 'PUT', {
    Authorization,
  });
}
function register_or_login(email, password) {
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/register_or_login/`;
  const formdata = new FormData();
  formdata.append('email', email);
  formdata.append('password', password);
  return FETCH(path, 'POST', {}, formdata);
}
function subscription_history() {
  const { account } = store.getState().auth;
  const Authorization = account.access_token ? account.access_token : '';
  const path = `/tenants/${APIString.TENANT_SLUG}/account/subscription_history/`;

  return FETCH(path, 'GET', {
    Authorization,
  });
}

function post_progress(content_id, progress) {
  const { account } = store.getState().auth;
  let Authorization = null;
  if (account) {
    Authorization = account.access_token ? account.access_token : null;
  }
  if (!Authorization) {
    return;
  }
  const path = `/backend/cm/content/${content_id}/progress`;
  const data = { progress };
  return FETCH(
    path,
    'POST',
    {
      Authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify(data),
  );
}

function get_progress(content_id) {
  const { account } = store.getState().auth;
  let Authorization = '';
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  const path = `/backend/cm/content/${content_id}/progress`;
  return FETCH(path, 'GET', {
    Authorization,
  });
}

function change_email(old_email, new_email) {
  const { account } = store.getState().auth;
  const Authorization = account.access_token ? account.access_token : '';
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/email`;
  const data = { old_email, new_email };
  return FETCH(
    path,
    'POST',
    {
      Authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify(data),
  );
}

function resend_email(type) {
  const { account } = store.getState().auth;
  const Authorization = account.access_token ? account.access_token : '';
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/resend_email`;
  const data = { type };
  return FETCH(
    path,
    'POST',
    {
      Authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify(data),
  );
}

function supports(data) {
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/contacts_support`;
  return FETCH(
    path,
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(data),
  );
}

function confirmEmail(data) {
  const paramsQuery = queryString.stringify(data);
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/emails/validate?${paramsQuery}`;
  return FETCH(path, 'GET', {
    'Content-Type': 'application/json',
  });
}

function getAdsBanner(tenantPlatform, data = {}) {
  const paramsQuery = queryString.stringify(data);
  const path = `/tenants/${APIString.TENANT_SLUG}/platforms/${tenantPlatform}/ads_banners?${paramsQuery}`;
  return FETCH(path, 'GET', {
    'Content-Type': 'application/json',
  });
}

function updateAccountPaymentMethod(data) {
  const { account } = store.getState().auth;
  const Authorization = account.access_token ? account.access_token : '';
  const path = `/tenants/${APIString.TENANT_SLUG}/account/payment_method/`;
  return FETCH(
    path,
    'PUT',
    {
      Authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify(data),
  );
}

function getAdsVideo(params = {}) {
  const queryParams = queryString.stringify(params);
  const { account } = store.getState().auth;
  const Authorization = account?.access_token || '';
  const path = `/tenants/${APIString.TENANT_SLUG}/platforms/${APIString.ROOT_PLATFORM_MENU_ID}/video_ads/?${queryParams}`;
  return FETCH(path, 'GET', {
    Authorization,
    'Content-Type': 'application/json',
  });
}

function getAccountPaymentMethod() {
  const { account } = store.getState().auth;
  const Authorization = account.access_token ? account.access_token : '';
  const path = `/tenants/${APIString.TENANT_SLUG}/account/payment_method/`;
  return FETCH(path, 'GET', {
    Authorization,
    'Content-Type': 'application/json',
  });
}

function postFavorite(idContent) {
  const { account } = store.getState().auth;
  const Authorization = account.access_token ? account.access_token : '';
  const path = `/backend/cm/favorite/`;
  const formdata = new FormData();
  formdata.append('content_id', idContent);
  return FETCH(
    path,
    'POST',
    {
      Authorization,
    },
    formdata,
  );
}

function getFavorite() {
  const { account } = store.getState().auth;
  const select = JSON.stringify({
    MovieDetail: CONTENT_FAVORITE_PAGE_SELECT_FIELDS,
  });
  let data = { select, ...getDeviceResolution() };
  const queryParams = queryString.stringify(data);

  const Authorization = account.access_token ? account.access_token : '';
  const path = `/backend/cm/favorite/?${queryParams}`;
  return FETCH(path, 'GET', {
    Authorization,
  });
}
function registerEmail(email, password) {
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/register`;

  const formdata = new FormData();
  formdata.append('email', email);
  formdata.append('password', password);
  return FETCH(path, 'POST', {}, formdata);
}

function allowedCountries() {
  const path = `/tenants/${APIString.TENANT_SLUG}/allowed_countries/?check_permission=true`;
  return new Promise((resolve, reject) => {
    FETCH(path, 'GET', {})
      .then(response => {
        resolve({ isBlock: false, result: response });
      })
      .catch(error => {
        if (error.error_code === 'country_blocking_exception') {
          resolve({ isBlock: true, result: error });
          return;
        }
        reject(error);
      });
  });
}

function reportTemplates() {
  const path = `/tenants/${APIString.TENANT_SLUG}/report_templates/`;
  return FETCH(path, 'GET', {});
}

function postReport(id, data) {
  const { account } = store.getState().auth;
  const Authorization = account.access_token ? account.access_token : '';
  const params = { version: Version.VERSION, platform: 'web' };
  const queryParams = queryString.stringify(params);
  const path = `/tenants/${APIString.TENANT_SLUG}/contents/${id}/report/?${queryParams}`;
  return FETCH(
    path,
    'POST',
    {
      Authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify(data),
  );
}

function uploadImage(data) {
  const { account } = store.getState().auth;
  const Authorization = account.access_token ? account.access_token : '';
  const path = `/tenants/${APIString.TENANT_SLUG}/files/images/upload`;
  const formData = new FormData();
  formData.append('filename', data.name.split('.')[0]);
  formData.append('file', data);
  return FETCH(
    path,
    'POST',
    {
      Authorization,
    },
    formData,
  );
}

function getAccountInfo() {
  const { account } = store.getState().auth;
  if (!account) {
    return Promise.reject();
  }
  const accountId = account.profile.id;
  const Authorization = account.access_token;
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/info`;
  return FETCH(path, 'GET', {
    Authorization,
  });
}

function subscriptionHistoryV2() {
  const { account } = store.getState().auth;
  if (!account) {
    return;
  }
  const accountId = account.profile.id;
  const Authorization = account.access_token;
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/invoiced_payments/`;
  return FETCH(path, 'GET', {
    Authorization,
  });
}

function getConfigFireBase() {
  let path = `/tenants/${APIString.TENANT_SLUG}/tenant_platforms/${APIString.ROOT_PLATFORM_MENU_ID}/configs/firebase/`;

  return FETCH(path, 'GET', {});
}
