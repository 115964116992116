import { getConfigByKey } from '@/shared/features/tenantConfig/services';

const defaultLocales = ['vi', 'en'];
const SCTV_LOCALES = ['vi'];
const PROJECT_W_LOCALES = ['en', 'ko'];

export const getLocales = () => {
  let tenantLocales = getConfigByKey('locales');
  if (
    (!tenantLocales || tenantLocales.length == 0) &&
    getConfigByKey('tenant_slug') === 'projectw'
  ) {
    tenantLocales = PROJECT_W_LOCALES;
  }

  if ((!tenantLocales || tenantLocales.length == 0) && getConfigByKey('tenant_slug') === 'sctv') {
    tenantLocales = SCTV_LOCALES;
  }

  if (
    (!tenantLocales || tenantLocales.length == 0) &&
    getConfigByKey('tenant_slug') !== 'projectw' &&
    getConfigByKey('tenant_slug') !== 'sctv'
  ) {
    tenantLocales = defaultLocales;
  }

  if (typeof tenantLocales === 'string') {
    tenantLocales = tenantLocales.split(',');
  }

  return tenantLocales;
};
