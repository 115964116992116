import { ErrorBoundary, ErrorBoundaryContext, useErrorBoundary } from 'react-error-boundary';

import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Button } from '@/shared/components/button/Button';

import { useAppDispatch } from '@/core/hook/appHook';
import { refreshAppSession } from '../global/globalSlice';
import { BaseDialog } from '@/shared/components/diglog/BaseDialog';
import { styled } from 'twin.macro';
import clsx from 'clsx';
import { BASE_URL } from '@/constants/apiString';

type Props = {
  children: ReactNode;
  hiddenChildren?: boolean;
  className?: string;
  themeMode?: 'light' | 'dark';
  isDialog?: boolean;
};

const FallbackStyled = styled.div`
  && button {
    font-size: 16px;
    background-color: #e72d30;
  }
  &.player-errors {
    button {
      max-width: 300px;
    }
  }
`;

const logError = (error: Error, info: { componentStack: string }) => {
  // Do something with the error, e.g. log to an external API
};

const isInternalBaseUrl = (url: string) => {
  if (!url) {
    return false;
  }
  const urlInstance = new URL(url);
  return urlInstance.origin === BASE_URL;
};

const ObserverPromiseError = () => {
  const { showBoundary } = useErrorBoundary();
  const { didCatch, error, resetErrorBoundary } = useContext(ErrorBoundaryContext) ?? {};

  useEffect(() => {
    const handleComponentError: any = (error: any) => {
      if (!isInternalBaseUrl(error?.reason?.request?.url) || didCatch || !error?.reason) {
        return;
      }
      showBoundary(error?.reason);
    };

    window.addEventListener('unhandledrejection', handleComponentError);

    return () => {
      window.removeEventListener('unhandledrejection', handleComponentError);
    };
  }, [didCatch, showBoundary]);

  useEffect(() => {
    if (!isInternalBaseUrl(error?.reason?.request?.url) || !error.isInternal) {
      resetErrorBoundary?.();
    }
  }, [error, resetErrorBoundary]);

  return null;
};

const eventType = 'unhandledrejection';

function Fallback({
  error,
  resetErrorBoundary = () => {},
  children,
  isDialog = false,
  themeMode,
  className,
  ...rest
}: any) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { message, detail, type, code, error_code, request, isInternal = false } = error;
  const getMessage = () => {
    let messageError = message ?? detail;

    if (type === eventType && !messageError) {
      messageError = `Có lỗi xảy ra trong quá trình tải dữ liệu. Chúng tôi xin lỗi vì sự bất tiện này. Vui lòng thử lại.`;
    }
    return messageError;
  };
  const handleReset = () => {
    resetErrorBoundary();

    dispatch(refreshAppSession());
  };

  const handleOnClose = () => {
    resetErrorBoundary();
  };

  const render = () => (
    <FallbackStyled className={clsx('h-full w-full', className)} data-mode={themeMode}>
      <div className="fallback-error text-cente mx-auto w-[min(800px,100%)] text-[#1E232A] dark:text-white">
        <h5 className="text-center text-[35px] font-bold leading-[48px]">Có Lỗi Xảy Ra</h5>
        {/* <p>{`Lay tu BE`}</p> */}
        <p className="break-word text-center text-[30px] leading-[41px] text-[#1E232A] dark:text-white">
          {getMessage()}
        </p>
        {/* <p>{`Có lỗi xảy ra trong quá trình phát nội dung ({Error code từ player}). Chúng tôi xin lỗi vì sự bất tiện này. Vui lòng thử lại.`}</p> */}
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={handleReset}
          className="mt-[23px] w-full py-[10px] capitalize md:text-[24px]"
        >
          Thử lại
        </Button>
      </div>
      {children}
    </FallbackStyled>
  );

  if ((!code && !error_code) || !isInternal || error.name === 'AbortError') {
    return null;
  }
  if (!isDialog) {
    return render();
  }
  return (
    <BaseDialog open={true} onClose={handleOnClose} size="small">
      {render()}
    </BaseDialog>
  );
}

export const ErrorBoundaryApp = ({
  children,
  hiddenChildren = true,
  themeMode = 'light',
  isDialog = false,
  ...props
}: Props) => {
  const fallbackRender = useCallback(
    (params: any) => (
      <>
        <Fallback {...props} {...params} isDialog={isDialog} themeMode={themeMode} />
        {!hiddenChildren && children}
      </>
    ),
    [],
  );
  return (
    <>
      <ErrorBoundary
        fallbackRender={fallbackRender}
        // FallbackComponent={Fallback}
        onError={logError}
      >
        <ObserverPromiseError />
        {children}
      </ErrorBoundary>
    </>
  );
};
