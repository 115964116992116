import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsOpenLoginDialog,
  setOpenLoginDialog,
} from '@/shared/features/authentication/authenticationSlice';

export const useSignInSignUpPopup = () => {
  const isOpenLoginDialog = useSelector(selectIsOpenLoginDialog);
  const dispatch = useDispatch();
  const openLoginDialog = () => dispatch(setOpenLoginDialog(true));
  const closeLoginDialog = () => dispatch(setOpenLoginDialog(false));
  return {
    isOpenLoginDialog,
    openLoginDialog,
    closeLoginDialog,
  };
};
