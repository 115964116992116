import { createSlice } from '@reduxjs/toolkit';

const blockCountrySlice = createSlice({
  name: 'blockCountry',
  initialState: {
    loaded: __SERVER__ === true,
    loading: __BROWSER__ === true,
    isBlock: false,
    duration: 60 * 5,
    expire: 0,
  },
  reducers: {
    setLoaidng(state, action) {
      const value = action.payload;
      state.loading = value;
    },
    setLoaded(state, action) {
      state.loaded = action.payload;
    },
    setIsBlock(state, action) {
      state.isBlock = action.payload;
    },
    setNewState(state, action) {
      state = { ...state, ...action.payload };
      return state;
    },
  },
});

export const { setLoaidng, setLoaded, setIsBlock, setNewState } = blockCountrySlice.actions;

export default blockCountrySlice.reducer;
