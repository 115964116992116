import { useState } from 'react';

export type TUseTimerProps = {
  durationInSeconds: number;
};

const createNewTimer = (durationInSeconds: TUseTimerProps['durationInSeconds']) =>
  Date.now() + durationInSeconds * 1000;

export const useTimer = (props: TUseTimerProps) => {
  const { durationInSeconds = 10 } = props;
  const [date, setDate] = useState(createNewTimer(durationInSeconds));

  const reset = () => setDate(createNewTimer(durationInSeconds));

  return { reset, date };
};
