import React, { useState } from 'react';
import {
  TUseVerifyPhoneNumberFormProps,
  useVerifyPhoneNumberForm,
} from './useVerifyPhoneNumberForm';
import { Form } from '../Form';
import { Controller } from 'react-hook-form';
import { BaseInput } from '../BaseInput';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { Timer } from '@/shared/components/Timer/Timer';

export type TVerifyPhoneNumberFormProps = TUseVerifyPhoneNumberFormProps;

const schema = yup
  .object()
  .shape({
    otp: yup.string().required('Vui long nhập mã OTP'),
  })
  .required();

type FormFields = yup.InferType<typeof schema>;

const initValues: FormFields = {
  otp: '',
};
export const VerifyPhoneNumberForm = (props: TVerifyPhoneNumberFormProps) => {
  const {
    userRegisterInfo,
    onSuccess = () => {},
    onError = () => {},
    onIsLoading = () => {},
  } = props;
  const { password, phoneNumber } = userRegisterInfo;
  const { handleValidateOtp, handleLogin, handleResendOTP, systemMessageError } =
    useVerifyPhoneNumberForm(props);

  const [timerCounter, setTimerCount] = useState(1);

  const handleSubmit = async ({ otp }: FormFields) => {
    onIsLoading(true);
    const params = { phoneNumber, password, otp };
    try {
      await handleValidateOtp(params);
      const res = await handleLogin({ phoneNumber, password });
      onSuccess(res);
      return res;
    } catch (error) {
      onError(error);
      // throw error;
    } finally {
      onIsLoading(false);
    }
  };

  const handleOnResend = async () => {
    onIsLoading(true);
    try {
      await handleResendOTP(userRegisterInfo);
      setTimerCount(timerCounter + 1);
    } catch (error) {
      onError(error);
    } finally {
      onIsLoading(false);
    }
  };

  const handleOnTimeout = () => {};

  return (
    <div className={clsx('VerifyPhoneNumberForm')}>
      <Form
        initialForm={{
          defaultValues: initValues,
          resolver: yupResolver(schema),
        }}
        serverErrors={{ otp: systemMessageError }}
        onSubmit={handleSubmit}
        actionClassName="mb-[19px] mt-0"
        titleForm="Xác nhận số điện thoại"
        titleFormProps={{
          className: 'mb-[7px] md:mb-[7px]',
          variant: 'long',
        }}
        detailDescriptionJsx={
          <p className="mb-[24px] mt-[7px] text-center text-[16px]">
            {`Nhập mã code được gửi về số điện thoại`}
            <br />
            {`${phoneNumber} của bạn`}
          </p>
        }
        timer={
          <Timer
            durationInSeconds={60 * 5}
            key={timerCounter}
            onResendClick={handleOnResend}
            onTimeout={handleOnTimeout}
          />
        }
      >
        {({ control, formState: { errors } }) => (
          <>
            <Controller
              control={control}
              name="otp"
              render={({ field }) => (
                <BaseInput
                  {...field}
                  label="Mã xác nhận"
                  fullWidth
                  error={errors?.otp?.message}
                  required
                />
              )}
            />
          </>
        )}
      </Form>
    </div>
  );
};
