import { tv, VariantProps } from 'tailwind-variants';

export const buttonVariant = tv(
  {
    base: 'whitespace-nowrap border-none bg-[transparent] font-[inherit] font-normal shadow-none outline-none',
    variants: {
      color: {
        primary: 'text-[theme(colors.primary)]',
      },
      size: {
        small: 'text-[17px] md:text-[20px]',
        normal: 'text-[17px] md:text-[25px]',
        standard: 'text-[16px]',
      },
      minWH: {
        small: 'min-h-[40px] min-w-[125px] md:min-h-[45px] md:min-w-[145px]',
        normal: 'min-h-[40px] min-w-[145px] md:min-h-[50px] md:min-w-[250px]',
      },
      variant: {
        text: 'text-[#1E232A]',
        contained: 'bg-[theme(colors.primary)] text-[#fff]',
        upgrade: 'bg-[theme(colors.upgrade)] text-[#fff]',
      },
      fullWidth: {
        true: 'w-full',
      },
      disabled: {
        true: 'pointer-events-none ',
      },
      padding: {
        none: 'p-0',
        sm: 'px-[19px] py-[8px]',
        standard: 'px-[19px] py-[13px]',
      },
      radius: {
        none: 'rounded-none',
        small: 'rounded-[5px]',
        standard: 'rounded-[10px]',
        full: 'rounded-full',
      },
      fontWeight: {
        bold: 'font-bold',
      },
    },

    defaultVariants: {
      variant: 'text',
      size: 'small',
      fullWidth: false,
      radius: 'standard',
      padding: 'sm',
    },
    compoundVariants: [
      {
        variant: ['contained', 'upgrade'],
        disabled: true,
        className: 'bg-[#C9C9C9] text-[#5E5E5E]',
      },
      {
        variant: 'text',
        color: 'primary',
        className: 'text-[theme(colors.primary)]',
      },
    ],
  },
  {
    responsiveVariants: ['sm', 'md'],
  },
);
export type ButtonVariantProps = VariantProps<typeof buttonVariant>;
