import { parse, stringifyUrl } from 'query-string';
import { useHistory } from 'react-router-dom';

export const useNavigate = () => {
  const {
    push,
    replace,
    location: { search, pathname },
  } = useHistory();

  /**
   *
   * @param {Object} options
   * @param {string} [options.path]
   * @param {boolean} [options.isReplace]
   * @param {boolean} [options.keepSearch]
   * @param {Object} [options.appendSearch]
   */
  const navigate = ({
    path = pathname,
    keepSearch = true,
    appendSearch = {},
    isReplace = false,
  }) => {
    const methodNavigate = isReplace ? replace : push;
    methodNavigate(
      stringifyUrl({ url: path, query: { ...appendSearch, ...(keepSearch ? parse(search) : {}) } }),
    );
  };

  const navigateNext = ({ nextFallback, ...restOptions } = {}) => {
    const { next = nextFallback } = parse(search);
    if (!next) {
      return;
    }
    const options = { path: next, keepSearch: false, ...restOptions };

    navigate(options);
  };

  return {
    navigate,
    navigateNext,
  };
};
