import React, { useEffect, useState } from 'react';
import { useSignInSignUpPopup } from './useSignInSignUpPopup';
import { BaseDialog } from '@/shared/components/diglog/BaseDialog';
import { SignInSignUpFormSwitcher } from '@/shared/components/form/SignInSignUpFormSwitcher/SignInSignUpFormSwitcher';
import { VerifyOTPDialog } from '@/shared/components/diglog/VerifyOTPDialog/VerifyOTPDialog';

type Props = {};

export const SignInSignUpPopup = (props: Props) => {
  const { isOpenLoginDialog, openLoginDialog, closeLoginDialog } = useSignInSignUpPopup();
  const [accountRegister, setAccountRegister]: any = useState();

  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const [openConfirmOTP, setOpenConfirmOTP] = useState(false);

  const isOpenConfirmOTP = accountRegister && openConfirmOTP;
  const handleOnClose = (event: any, reason?: string) => {
    if (reason) {
      return;
    }
    closeLoginDialog();
    setTimeout(() => setOpenForgotPassword(false), 200);
  };

  const handleCloseModalConfirmOTP = () => {
    setOpenConfirmOTP(false);
  };

  if (isOpenConfirmOTP) {
    return (
      <VerifyOTPDialog
        open={openConfirmOTP}
        onClose={handleCloseModalConfirmOTP}
        userRegisterInfo={accountRegister ?? {}}
        onSuccess={handleCloseModalConfirmOTP}
      />
    );
  }

  return (
    <BaseDialog
      open={isOpenLoginDialog}
      onClose={handleOnClose}
      size={openForgotPassword ? 'small' : 'normal'}
      contentClassName="px-0"
      clickOutsize={false}
    >
      <SignInSignUpFormSwitcher
        openForgotPassword={openForgotPassword}
        setOpenForgotPassword={setOpenForgotPassword}
        openConfirmOTP={openConfirmOTP}
        setOpenConfirmOTP={setOpenConfirmOTP}
        setAccountRegister={setAccountRegister}
      />
    </BaseDialog>
  );
};
