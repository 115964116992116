import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { forwardRef } from 'react';
import { css, styled, theme } from 'twin.macro';
import { useThemeFormContext } from './useThemeFormContext';
// import TextField, { TextFieldProps } from '@mui/material/TextField';
export type BaseInputProps = TextFieldProps & {
  labelColor?: string;
  textColor?: string;
  helperTextSize?: string;
  isRequired?: boolean;
  errorEachField?: boolean;
};
const TextFieldStyled = styled(TextField)`
  ${({
    labelColor = '#ababab',
    textColor = '#1e232a',
    helperTextSize = '12px',
    borderColor,
    iconColor = '#94a3ad',
    helperTextColor = '#fff',
  }: any) => css`
    --labelColor: ${labelColor};
    --textPrimary: ${textColor};
    --borderColor: ${borderColor ?? textColor};
    --iconColor: ${iconColor};
    --labelSizeFloating: 12px;
    --labelSizePlaceholder: 14px;
    --helperTextSize: ${helperTextSize};
    --helperTextColor: ${helperTextColor};
    --textSize: 16px;

    font-weight: 400;
    font-family: 'Open Sans';
    font-size: var(--textSize);

    margin-bottom: 16px;
    .MuiSvgIcon-root {
      color: var(--iconColor);
    }
    /* .star, */
    .MuiInputBase-root {
      color: var(--textPrimary);
      /* padding-top: 5px; */
    }

    .MuiFormLabel-root {
      color: var(--labelColor);
      font-size: var(--labelSizePlaceholder);
      font-weight: 400;
    }
    .MuiFormLabel-asterisk.Mui-error {
      color: var(--labelColor);
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: var(--borderColor);
      border-radius: 10px;
      top: 0;
    }
    .MuiOutlinedInput-root {
      &.Mui-focused,
      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: var(--borderColor);
          border-width: 1px;
        }
      }
    }
    .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: var(--borderColor);
    }
    .MuiOutlinedInput-input {
      padding: 14px 17px;
      padding-bottom: 7px;
      padding-top: 22px;
    }
    .MuiInputLabel-outlined {
      transform: translate(18px, 16px) scale(1);
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(18px, 7px) scale(1);
      font-size: var(--labelSizeFloating);
    }
    legend {
      display: none;
    }
    .MuiFormHelperText-root {
      font-size: var(--helperTextSize);
      margin-inline: 0;
    }
    .MuiFormHelperText-root:not(.Mui-error) {
      color: var(--helperTextColor);
    }
    @media (min-width: ${theme`screens.md`}) {
      /* --labelSizeFloating: 14px; */
      --labelSizePlaceholder: 16px;
    }
  `}
`;

const isOverrideLabel = false;

export const BaseInput = forwardRef((props: BaseInputProps, ref) => {
  const { error, isRequired, label, helperText, errorEachField = true, ...rest } = props;
  const { themeMode } = useThemeFormContext();

  const isDark = themeMode === 'dark';

  const labelTitle = () => {
    if (!isRequired) {
      return label;
    }
    return (
      <span>
        {label} <span className="star">*</span>
      </span>
    );
  };
  const colors = {
    labelColor: isDark ? '#B1B1B1' : '#ababab',
    textColor: isDark ? '#E30000' : '#1e232a',
    borderColor: isDark ? '#fff' : null,
    iconColor: '#94a3ad',
    helperTextColor: isDark ? '#fff' : '#1e232a',
  };

  return (
    <TextFieldStyled
      size="small"
      variant="outlined"
      error={errorEachField ? !!error : false}
      helperText={helperText || (errorEachField ? error : '')}
      inputRef={ref}
      // className="[&_:where(.MuiFormLabel-root)]:dark:!text-[#E30000] "
      // required={isOverrideLabel ? false : isRequired}
      {...colors}
      {...rest}
      label={labelTitle()}
    />
  );
});
