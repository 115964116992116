import { ReactRef } from '@/shared/utils/react-utils/refs';
import { ComponentProps, MouseEventHandler, useMemo } from 'react';
import { buttonVariant, ButtonVariantProps } from './buttonVariant';

type Props = ComponentProps<'button'> & {
  ref?: ReactRef<HTMLButtonElement | null>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export type UseButtonProps = Props & ButtonVariantProps;
export const useButton = (props: UseButtonProps) => {
  const {
    radius,
    size,
    color,
    variant,
    className,
    children,
    onClick,
    fullWidth,
    padding,
    disabled,
    fontWeight,
    minWH,
    type,
    ref,
  } = props;

  const styles = useMemo(
    () =>
      buttonVariant({
        size,
        color,
        variant,
        className,
        fullWidth,
        padding,
        disabled,
        radius,
        fontWeight,
        minWH,
      }),
    [size, color, variant, className, fullWidth, padding, disabled, radius, fontWeight, minWH],
  );

  const getButtonProps = () => ({
    onClick,
    type,
    ref,
  });

  return {
    styles,
    getButtonProps,
    children,
  };
};

export type UseButtonReturn = ReturnType<typeof useButton>;
