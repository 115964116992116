import { Dispatch, MouseEventHandler, useState } from 'react';
import { ForgotPasswordForm } from '../ForgotPasswordForm/ForgotPasswordForm';
import { LoginForm } from '../LoginForm/LoginForm';
import { SignUpForm } from '../SignUpForm/SignUpForm';
import { SwitchTwoForm } from '../SwitchTwoForm';

type Props = {
  openForgotPassword?: boolean;
  setOpenForgotPassword?: Dispatch<React.SetStateAction<boolean>>;
  openConfirmOTP?: boolean;
  setOpenConfirmOTP?: Dispatch<React.SetStateAction<boolean>>;
  accountRegister?: any;
  setAccountRegister?: Dispatch<React.SetStateAction<any>>;
};

export const formKey = {
  signIn: 'sign-in',
  signUp: 'sign-up',
};

export const SignInSignUpFormSwitcher = (props: Props) => {
  const {
    openForgotPassword,
    setOpenForgotPassword,
    openConfirmOTP,
    accountRegister,
    setAccountRegister,
    setOpenConfirmOTP,
  } = props;

  const handleForgotPasswordClick = () => {
    // event.preventDefault();
    setOpenForgotPassword?.(true);
  };

  if (openForgotPassword) {
    return <ForgotPasswordForm />;
  }

  return (
    <div className="SignInSignUpFormSwitcher px-[10px] md:px-[16px]">
      <SwitchTwoForm
        forms={[
          {
            labelActionForm: 'Đăng ký',
            formComponent: <LoginForm onForgotPasswordClick={handleForgotPasswordClick} />,
            helperText: 'Bạn chưa có tài khoản?',
            key: formKey.signIn,
          },
          {
            labelActionForm: 'Đăng nhập',
            formComponent: (
              <SignUpForm
                accountRegister={accountRegister}
                setAccountRegister={setAccountRegister}
                setOpenConfirmOTP={setOpenConfirmOTP}
              />
            ),
            helperText: 'Bạn đã có tài khoản?',
            key: formKey.signUp,
          },
        ]}
        defaultKey={formKey.signIn}
      />
    </div>
  );
};
