import * as types from './actionTypes';

export { confirmEmail };

function confirmEmail(data) {
  return {
    type: types.CONFIRM_EMAIL,
    payload: data,
  };
}
