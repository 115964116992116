import clsx from 'clsx';
import { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';
import { tv, VariantProps } from 'tailwind-variants';

const titleFormTv = tv({
  base: 'text-center font-open-sans font-bold text-[#1E232A] dark:text-[#fff]',
  variants: {
    size: {
      short: 'text-[42px] leading-[57px]',
      long: 'text-[30px] leading-[40px]',
    },
    margin: {
      none: 'm-0',
      dense: 'mb-[18px] md:mb-[30px]',
    },
  },
  defaultVariants: {
    size: 'short',
    margin: 'dense',
  },
});

export type TitleFormProps = ComponentProps<'h4'> & {
  titleDialog?: string;
  variant?: 'short' | 'long';
  className?: string;
} & VariantProps<typeof titleFormTv>;

export const TitleForm = ({
  children,
  variant = 'short',
  margin,
  className = '',
}: TitleFormProps) => {
  return (
    <h4
      className={clsx('title-dialog', twMerge(titleFormTv({ size: variant, margin }), className))}
    >
      {children}
    </h4>
  );
};
